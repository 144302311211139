// @flow
import type { Customer, CustomerSimple } from "../types/customers";
import { parseSegmentsServerResponse } from "./segments";
import { toArray, toSelectOption } from "./utils";

export function parseCustomerSimpleServerResponse(customer: Object): CustomerSimple {
    return toSelectOption({
        slug: customer.slug,
        name: customer.name,
    });
}

export function parseCustomersSimpleServerResponse(customers: $ReadOnlyArray<Object>): Array<CustomerSimple> {
    return toArray(customers, parseCustomerSimpleServerResponse);
}

export function parseCustomerServerResponse(data: Object): Customer {
    return toSelectOption( {
        slug: data.slug,
        name: data.name,
        externalId: data.external_id,
        lastInteraction: data.last_interaction,
        numPendingItems: data.pending_items,
        segments: parseSegmentsServerResponse(data.segments),
    });
}
