import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('ContactMessageViewReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    contactMessage: {},
};

// ACTION TYPES
export const types = {
    CONTACT_MESSAGE_CLEAR_DATA: 'CONTACT_MESSAGE/CLEAR/DATA',

    CONTACT_MESSAGE_REQUEST: 'CONTACT_MESSAGE/REQUEST',
    CONTACT_MESSAGE_REQUEST_SUCCESS: 'CONTACT_MESSAGE/REQUEST/SUCCESS',
    CONTACT_MESSAGE_REQUEST_ERROR: 'CONTACT_MESSAGE/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    clearData: () => ({
        type: types.CONTACT_MESSAGE_CLEAR_DATA,
    }),

    getContactMessage: (message_slug) => ({
        type: types.CONTACT_MESSAGE_REQUEST,
        message_slug: message_slug,
    }),
    getContactMessageSuccess: (payload) => ({
        type: types.CONTACT_MESSAGE_REQUEST_SUCCESS,
        payload,
    }),
    getContactMessageError: (errors) => ({
        type: types.CONTACT_MESSAGE_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function contactMessageViewReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CONTACT_MESSAGE_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.CONTACT_MESSAGE_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CONTACT_MESSAGE_REQUEST_SUCCESS: {
            log('contact message is', action.payload);
            let msg = {...action.payload};
            msg.news_items.map((ni, idx) => {
                ni.idx = idx;
            });
            return {
                ...state,
                loading: false,
                errors: null,
                contactMessage: msg,
            }
        }

        case types.CONTACT_MESSAGE_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.contactMessageView.loading;
export const getErrors = (state) => state.contactMessageView.errors;
export const getSentContactMessage = (state) => state.contactMessageView.contactMessage;
