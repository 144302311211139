import debug from "debug";
import {takeLatest} from "redux-saga/effects";
import {makeRequest} from "../../../sagas/utils";

import {getInteractionDetails, saveInteractionClassification} from "./api";
import {actions} from "./reducer";

const log = debug("InteractionDetailsSaga");

function* fetchInteractionDetails(action) {
    log("got request to fetch interaction details", action);
    yield makeRequest(actions.requestInteractionSuccess, actions.requestInteractionError, getInteractionDetails, action.payload);
}

function* submitInteractionClassification(action) {
    log("got request to save interaction classification", action);
    yield makeRequest(actions.classifyInteractionSuccess, actions.classifyInteractionError, saveInteractionClassification, action.payload, action.interactionSlug);
}


const sagas = [
    takeLatest(actions.requestInteraction.type, fetchInteractionDetails),
    takeLatest(actions.classifyInteraction.type, submitInteractionClassification),
];
export default sagas;
