// @flow
import {endpoints} from "../../constants/api";
import type {ServerResponse} from "../../types/common";
import {get} from "../../utils/requester";

export function fetchTags(accountSlug: string, tagName: string): Promise<Array<Object>> {
    return get(endpoints.newsItems.listNewsItemTag.replace(":account", accountSlug), {search: tagName});
}

export function fetchCustomers(customerName: string): Promise<ServerResponse> {
    return get(endpoints.accountData.customersSimple, {search: customerName, limit: 10});
}
