import React from "react";
import PropTypes from "prop-types";

import './RouteLoader.css';


class RouteLoader extends React.Component {
	static propTypes = {
		error: PropTypes.object,
		timedOut: PropTypes.bool.isRequired,
		pastDelay: PropTypes.bool.isRequired,
	};

	static defaultProps = {
		error: null,
	};


	render() {
		const { error, timedOut, pastDelay } = this.props;

		if (error || timedOut) {
			return (
				<div className="route-loader-container">
                    <div className="route-loader-error">
                        <p className="error">Something went wrong. Please try again</p>
				    </div>
                </div>
			);
		} else if (pastDelay) {
			return (
				<div className="route-loader-container">
                    <div className="route-loader">
                        <div className="route-loading">
                            <i className="fas fa-circle-notch fa-spin"/>
                        </div>
				    </div>
                </div>
			);
		}
		return null;
	}
}

export default RouteLoader;