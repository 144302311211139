import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import isEmpty from "lodash-es/isEmpty";
import debug from "debug";

import "./LoginForm.css";
import ErrorsMessage from "../../ErrorMessage/ErrorMessage";
import { routes } from "../../../constants/routes";
import { AVAILABLE_FEATURES } from "../../../constants/accountFeatures";

const log = debug("LoginForm");

class LoginForm extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            username: "",
            password: "",
            email: "",
            from: null,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleShowAResetPassword = this.handleShowAResetPassword.bind(this);
        this.handleCloseResetPassword = this.handleCloseResetPassword.bind(this);
        this.handleSubmitResetPassword = this.handleSubmitResetPassword.bind(this);
    }

    static propTypes = {
        onFormSubmit: PropTypes.func.isRequired,
        onResetPasswordSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        errors: PropTypes.array,
        success: PropTypes.bool,
        passwordResetSuccess: PropTypes.bool,
        location: PropTypes.object,
        authenticated: PropTypes.bool,
        getProfileData: PropTypes.func,
        user: PropTypes.object,
    };

    static defaultProps = {
        onFormSubmit: () => {},
        onResetPasswordSubmit: () => {},
        isLoading: null,
        errors: [],
        success: null,
        passwordResetSuccess: null,
        authenticated: null,
        getProfileData: () => {},
        user: {},
    };

    componentDidMount() {
        log("login props are", this.props);
        this._isMounted = true;
        let from = this.props.location.pathname + this.props.location.search;
        if (this.props.location.search != null && this.props.location.search !== "") {
            log("checking if location has search params", this.props.location.search);

            const params = new URLSearchParams(this.props.location.search);
            const jwt = params.get("token");

            if (jwt != null) {
                log("got jwt from external");
                this.props.loginFromToken(jwt);
                this.props.getProfileData();
                params.delete("token");
                from = this.props.location.pathname + "?" + params.toString();
                log("from is now", from);
            }
        }

        this.setState({
            from: from,
        });
    }

    componentDidUpdate(prevProps) {
        const { passwordResetSuccess, success } = this.props;

        if (prevProps.passwordResetSuccess !== passwordResetSuccess && passwordResetSuccess) {
            window.scrollTo(0, 0);
            this.setState({ alertVisible: true }, () => {
                window.setTimeout(() => {
                    if (this._isMounted) {
                        this.setState({ alertVisible: false });
                    }
                }, 5000);
            });
        }

        if (prevProps.success !== success && success) {
            this.props.getProfileData();
        }
    }

    componentWillUnmount() {
        log("component will unmount");
        this._isMounted = false;
    }

    handleInputChange(event) {
        if (event && event.target) {
            this.setState({
                [event.target.id]: event.target.value,
            });
        }
    }

    onFormSubmit = (event) => {
        event.preventDefault();

        const payload = {
            username: this.state.username,
            password: this.state.password,
        };
        this.props.onFormSubmit(payload);
    };

    handleShowAResetPassword() {
        this.setState({
            showPasswordReset: true,
        });
    }

    handleCloseResetPassword() {
        this.setState({
            showPasswordReset: false,
        });
    }

    handleSubmitResetPassword(event) {
        event.preventDefault();

        const payload = {
            email: this.state.email,
        };
        this.props.onResetPasswordSubmit(payload);
        this.setState({
            showPasswordReset: false,
        });
    }

    render() {
        const { isLoading, errors, passwordResetSuccess, authenticated, user } = this.props;
        const { from } = this.state;
        log("login props are", this.props);
        log("login from", from);

        if (authenticated && !isEmpty(user)) {
            let routeTo;

            if (from && from !== routes.root) {
                routeTo = from;
            } else {
                const enabledFeatures =
                    this.props.user && this.props.user.account ? this.props.user.account.enabled_features : {};
                let enabledFeaturesList = [];
                if (!isEmpty(enabledFeatures)) {
                    enabledFeaturesList = AVAILABLE_FEATURES.filter((f) => enabledFeatures[f.key]);
                }
                routeTo = enabledFeaturesList.length !== 0 ? enabledFeaturesList[0].link : routes.profile.main;
            }
            log("redirecting to", routeTo);
            return <Redirect to={routeTo} />;
        }

        return [
            <div className="row" key="login-form">
                {passwordResetSuccess && (
                    <section className="col-md-12">
                        <Alert variant="success" show={this.state.alertVisible}>
                            A password reset link has been sent to your email.
                        </Alert>
                    </section>
                )}
                <form className="form-component clearfix" onSubmit={this.onFormSubmit}>
                    {isLoading && (
                        <div className="form-loading">
                            <i className="fas fa-circle-notch fa-spin" />
                        </div>
                    )}
                    <section className="login-logo col-md-12">
                        <a href="/">
                            <img
                                alt="questomer"
                                className="logo-main"
                                src={process.env.PUBLIC_URL + "/images/logo.png"}
                            />
                        </a>
                        <h3>Sign In</h3>
                    </section>
                    <section className="login-form-fields col-md-12">
                        <input
                            id="username"
                            name="username"
                            type="text"
                            disabled={isLoading}
                            required
                            placeholder="Email"
                            value={this.state.username}
                            onChange={this.handleInputChange}
                        />
                        <input
                            id="password"
                            name="password"
                            type="password"
                            disabled={isLoading}
                            required
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                        />
                        <button type="submit" disabled={isLoading} className="btn btn-primary">
                            Sign in
                        </button>
                        {errors && <ErrorsMessage errors={errors} />}
                        <button
                            type="button"
                            className="btn btn-link forgot-password-link"
                            onClick={this.handleShowAResetPassword}
                        >
                            Forgot my password
                        </button>
                    </section>
                </form>
            </div>,
            <Modal
                key="modal-password-reset"
                size="sm"
                dialogClassName="modal-additional-news-items"
                centered
                show={this.state.showPasswordReset}
                onHide={this.handleCloseResetPassword}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-component clearfix" onSubmit={this.handleSubmitResetPassword}>
                        {isLoading && (
                            <div className="form-loading">
                                <i className="fas fa-circle-notch fa-spin" />
                            </div>
                        )}
                        <div className="row">
                            <section className="password-reset-form-fields col-md-12">
                                <p>Please enter your email and a password reset link will sent.</p>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    disabled={isLoading}
                                    required
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                />
                                <button type="submit" disabled={isLoading} className="btn btn-primary">
                                    Reset
                                </button>
                                {errors && <ErrorsMessage errors={errors} />}
                            </section>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>,
        ];
    }
}

export default LoginForm;
