// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ObjectSlugString, ServerResponse } from "../../../types/common";
import { deleteMethod, get, post } from "../../../utils/requester";
import type { CustomerAnalyticsParams, TiersStagesParams, SavedFilterParams } from "./types";

const log = debug("CustomerAnalyticsAPI");

const CUSTOMER_ANALYTICS_PARAMS = [
    { paramsKey: "csms", payloadKey: "csms" },
    { paramsKey: "financialKpi", payloadKey: "financial_kpi" },
    { paramsKey: "tier", payloadKey: "tier" },
    { paramsKey: "stage", payloadKey: "stage" },
    { paramsKey: "renewalTiming", payloadKey: "renewal_timing" },
    { paramsKey: "period", payloadKey: "period_weeks" },
];

const TIERS_STAGES_PARAMS = [
    { paramsKey: "groupKey", payloadKey: "group_key" },
];

const SAVED_FILTER_PARAMS = [
    { paramsKey: "csms", payloadKey: "csms" },
    { paramsKey: "financialKpi", payloadKey: "financial_kpi" },
    { paramsKey: "tier", payloadKey: "tier" },
    { paramsKey: "stage", payloadKey: "stage" },
    { paramsKey: "renewalTiming", payloadKey: "renewal_timing" },
    { paramsKey: "period", payloadKey: "period_weeks" },
    { paramsKey: "slug", payloadKey: "slug" },
    { paramsKey: "title", payloadKey: "title" },
];

export function getCustomerAnalytics(params: CustomerAnalyticsParams): Promise<ServerResponse> {
    log("requesting customer analytics", params);
    let getParams = {};
    CUSTOMER_ANALYTICS_PARAMS.forEach((p) => {
        const val = params[p.paramsKey];
        if (val != null) {
            getParams[p.payloadKey] = val;
        }
    });
    return get(endpoints.reports.customerAnalytics, getParams);
}

export function getTiersStages(params: TiersStagesParams): Promise<ServerResponse> {
    log("requesting tiers/stages", params);
    const account = params.account ? params.account : "";
    let getParams = {};
    TIERS_STAGES_PARAMS.forEach((p) => {
        const val = params[p.paramsKey];
        if (val != null) {
            getParams[p.payloadKey] = val;
        }
    });
    return get(endpoints.accountData.segments.replace(":account", account), getParams);
}

export function getFinancialKpis(): Promise<ServerResponse> {
    log("requesting financial kpis");
    return get(endpoints.accountData.financialKpis);
}

export function saveReportFilter(params: SavedFilterParams): Promise<ServerResponse> {
    log("requesting save filter");
    let postParams = {};
    SAVED_FILTER_PARAMS.forEach((p) => {
        const val = params[p.paramsKey];
        if (val != null) {
            postParams[p.payloadKey] = val;
        }
    });
    return post(endpoints.reports.saveFilter, postParams);
}

export function getSavedFilter(filterSlug: ObjectSlugString): Promise<ServerResponse> {
    log("requesting saved filter");
    return get(endpoints.reports.savedFilter.replace(':filterSlug', filterSlug));
}

export function deleteSavedFilter(filterSlug: ObjectSlugString): Promise<ServerResponse> {
    log("requesting to delete saved filter", filterSlug);
    return deleteMethod(endpoints.reports.savedFilter.replace(":filterSlug", filterSlug));
}
