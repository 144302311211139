import React, {Component} from 'react';
import PropTypes from 'prop-types';

import "./Message.css";


class Message extends Component {
    static propTypes = {
        children: PropTypes.node,
        type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
        style: PropTypes.object,
        styleContainer: PropTypes.object,
    };

    static defaultProps = {
        children: null,
        style: {},
        styleContainer: {},
    };

    render() {
        const {children, type, style, styleContainer} = this.props;
        return (
            <div style={styleContainer}>
                <div className={type} style={style}>
                    {children}
                </div>
            </div>
        );
    }
}

export default Message;
