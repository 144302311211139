import {func} from "prop-types";
import {put, takeEvery} from "redux-saga/effects";
import debug from "debug";

import {get, post} from "../utils/requester";
import {endpoints} from "../constants/api";
import {actions} from "../reducers/campaignAddRecipients";
import {makeAuthenticatedCall, makeRequest} from "./utils";

const log = debug("campaignExecuteContactsSaga");


function getContacts({customer, offset, limit, search}) {
    log('requesting contacts');
    let getParams = {
        offset: offset,
    };
    if (limit != null) {
        getParams.limit = limit;
    }
    if (search != null && search !== '') {
        getParams.search = search;
    }
    if (customer != null) {
        getParams.customer = customer;
    }
    return get(endpoints.contacts.list, getParams);
}

function* fetchContactsData(action) {
    log('got a request to get contacts', action);
    const {success, response, error, statusCode} = yield makeAuthenticatedCall(getContacts, action.payload);
    log('got response', success, response, error, statusCode);

    if (success) {
        log('api call ok', response);
        yield put(actions.requestContactsSuccess({...response, getMore: action.payload.getMore}));
    } else if (statusCode ===  401) {
        log("token expired. exiting", response);
    } else {
        log('api call bad', error);
        yield put(actions.requestContactsError(error));
    }
}

function addContacts({contactSlugs, campaignSlug}) {
    log('requesting to add contacts', contactSlugs, campaignSlug);
    return post(endpoints.campaigns.addContacts.replace(":campaign", campaignSlug), {contacts: contactSlugs});
}

function* requestAddContacts(action) {
    log('got a request to add contacts to campaign', action);
    yield makeRequest(actions.requestAddContactsSuccess, actions.requestAddContactsError, addContacts, action.payload);
}

// WATCHERS
export function* watchRequestContactsData() {
    yield takeEvery(actions.requestContacts.type, fetchContactsData);
}

export function* watchRequestAddContacts() {
    yield takeEvery(actions.requestAddContacts.type, requestAddContacts);
}
