// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import { parseContactsSimpleServerResponse } from "../../../parsers/contacts";
import { parseCustomersSimpleServerResponse } from "../../../parsers/customers";
import type { ServerResponse } from "../../../types/common";
import type { ContactSimple } from "../../../types/contacts";
import type { CustomerSimple } from "../../../types/customers";
import { get } from "../../../utils/requester";

const log = debug("SearchParamsAPI");

export function getAccountManagerData(searchValue: ?string): Promise<ServerResponse> {
    log("requesting manager data", searchValue);
    if (searchValue != null && searchValue.length > 0) {
        return get(endpoints.accountData.managers, { search: searchValue });
    }
    return get(endpoints.accountData.managers);
}

export function getContactsData(searchValue: string): Promise<ServerResponse> {
    log("requesting contacts", searchValue);
    if (searchValue.length > 0) {
        return get(endpoints.contacts.list, { search: searchValue, simple: true, limit: 10 });
    }
    return get(endpoints.contacts.list, { simple: true, limit: 10 });
}

export function getCustomersSimpleBySlugs(slugs: string, callback: (customers: Array<CustomerSimple>) => void): void {
    if (slugs === "") return;
    get(endpoints.accountData.customersSimple, { slugs: slugs }).then(
        (resp) => {
            log("got response for simple customers", resp);
            if (resp.success) {
                callback(parseCustomersSimpleServerResponse(resp.response.results));
            } else {
                log("error getting simple customers by slugs");
                callback([]);
            }
        },
        (err) => {
            log("there was an error getting simple customers", err);
            callback([]);
        }
    );
}

export function searchContacts(
    inputValue: string,
    reactSelectCallback: (searchResults: Array<ContactSimple>) => void
): void {
    if (inputValue.length < 3) {
        // we don't search with less than 3 characters
        reactSelectCallback([]);
        return;
    }
    getContactsData(inputValue).then(
        (resp) => {
            log("got search resp", resp);
            if (typeof resp.response === "object" && Array.isArray(resp.response.results)) {
                reactSelectCallback(parseContactsSimpleServerResponse(resp.response.results));
            } else {
                log("there was some error searching contacts", resp);
                reactSelectCallback([]);
            }
        },
        (err) => {
            log("error", err);
            reactSelectCallback([]);
        }
    );
}

export function getContactsSimpleBySlugs(slugs: string, callback: (contacts: Array<ContactSimple>) => void): void {
    if (slugs === "") return;
    get(endpoints.contacts.list, { simple: true, slugs: slugs }).then(
        (resp) => {
            log("got response for simple contacts", resp);
            if (resp.success) {
                callback(parseContactsSimpleServerResponse(resp.response.results));
            } else {
                log("error getting simple contacts by slugs");
                callback([]);
            }
        },
        (err) => {
            log("error getting simple contacts", err);
            callback([]);
        }
    );
}

export function fetchSentiments(): Promise<ServerResponse> {
    log("requesting sentiments");
    return get(endpoints.interactions.sentimentsList);
}
