import debug from "debug";
import { createAction } from "@reduxjs/toolkit";

import { getErrorData } from "../../../utils/errorData";
import { parseInteractionDetailsServerResponse } from "../../../parsers/interactions";

const log = debug("interactionDetailsReducer");

const DEFAULT_STATE = {
    interaction: null,
    errors: null,
    success: null,
    loading: false,
};

export const actions = {
    clearState: createAction("INTERACTION_DETAILS/CLEAR_STATE"),

    requestInteraction: createAction("INTERACTION_DETAILS/REQUEST"),
    requestInteractionSuccess: createAction("INTERACTION_DETAILS/REQUEST/SUCCESS"),
    requestInteractionError: createAction("INTERACTION_DETAILS/REQUEST/ERROR"),

    classifyInteraction: createAction("INTERACTION_CLASSIFY/REQUEST"),
    classifyInteractionSuccess: createAction("INTERACTION_CLASSIFY/REQUEST/SUCCESS"),
    classifyInteractionError: createAction("INTERACTION_CLASSIFY/REQUEST/ERROR"),
};

export default function interactionDetailsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE;
        }

        case actions.requestInteraction.type: {
            return {
                ...state,
                loading: true,
                errors: null,
            };
        }

        case actions.requestInteractionSuccess.type: {
            return {
                ...state,
                loading: false,
                errors: null,
                interaction: parseInteractionDetailsServerResponse(action.payload),
            };
        }

        case actions.requestInteractionError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
            };
        }

        case actions.classifyInteraction.type: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            };
        }

        case actions.classifyInteractionSuccess.type: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            };
        }

        case actions.classifyInteractionError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
                success: false,
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => state.interactionDetails.loading;
export const getErrors = (state) => state.interactionDetails.errors;
export const getInteraction = (state) => state.interactionDetails.interaction;
export const getSuccess = (state) => state.interactionDetails.success;
