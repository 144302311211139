import {routes} from "./routes";

export const AVAILABLE_FEATURES = [
    { link: routes.newsitems.main, key: "news_items", icon: "fas fa-envelope", label: "News items", },
    { link: routes.customers.main, key: "customers", icon: "fas fa-id-card-alt", label: "Customers", },
    { link: routes.campaigns.main, key: "campaigns", icon: "fas fa-mail-bulk", label: "Campaigns", },
    { link: routes.messages.main, key: "messages", icon: "fas fa-envelope-open-text", label: "Messages", },
    { link: routes.feedback.main, key: "feedbacks", icon: "fas fa-star", label: "Feedback", },
    { link: routes.interactions.main, key: "interactions", icon: "fas fa-comments", label: "Interactions", },
    { link: routes.reports.main, key: "reports", icon: "fas fa-chart-area", label: "Reports", },
];

export const INTEGRATIONS_CONTROL = [
    { key: "gmail", description: "googleLoginBtn", },
    { key: "salesforce", description: "sfLoginBtn", },
    { key: "zendesk", description: "zdLoginBtn", },
];
