// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ServerResponse } from "../../../types/common";
import { get } from "../../../utils/requester";
import type { CustomersListParams } from "./types";

const log = debug("CustomersListAPI");

const CUSTOMERS_LIST_PARAMS = [
    { paramsKey: "sortField", payloadKey: "sort_by" },
    { paramsKey: "sort", payloadKey: "sort" },
    { paramsKey: "offset", payloadKey: "offset" },
    { paramsKey: "limit", payloadKey: "limit" },
    { paramsKey: "customers", payloadKey: "customers" },
    { paramsKey: "csms", payloadKey: "csms" },
    { paramsKey: "financialKpi", payloadKey: "financial_kpi" },
    { paramsKey: "tier", payloadKey: "tier" },
    { paramsKey: "stage", payloadKey: "stage" },
    { paramsKey: "renewalTiming", payloadKey: "renewal_timing" },
    { paramsKey: "risk", payloadKey: "risk" },
    { paramsKey: "showCustomers", payloadKey: "show_customers" },
];

export function getCustomersList(params: CustomersListParams): Promise<ServerResponse> {
    log("requesting customers list", params);
    let getParams = {};
    CUSTOMERS_LIST_PARAMS.forEach((p) => {
        const val = params[p.paramsKey];
        if (val != null) {
            getParams[p.payloadKey] = val;
        }
    });
    return get(endpoints.customers.list, getParams);
}
