import debug from "debug";
import { push } from "connected-react-router";
import { call, put, takeEvery } from "redux-saga/effects";

import { types, actions as loginActions } from "../reducers/auth";
import { endpoints } from "../constants/api";
import { routes } from "../constants/routes";
import { get, post } from "../utils/requester";
import { GOOGLE, SALESFORCE, ZENDESK } from "../constants/socialChannels";
import { makeRequest } from "./utils";

const log = debug("loginSaga");

function doSocialRequest(payload) {
    log("contacting server", payload);
    switch (payload.channel) {
        case GOOGLE:
            return post(endpoints.auth.googleCallback, { code: payload.data.code });
        case SALESFORCE:
            const sfPayload = {
                code: payload.data.code,
                is_sandbox: payload.data.sandbox,
            };
            return post(endpoints.auth.salesForceCallback, sfPayload);
        case ZENDESK:
            const zdPayload = {
                code: payload.data.code,
            };
            return post(endpoints.auth.zendeskCallback, zdPayload);
        default:
            throw new Error(`${payload.channel} is not valid for social login`);
    }
}

function* startGoogleRequest(action) {
    log("start google login", action);
    yield makeRequest(loginActions.googleSuccess, loginActions.googleError, doSocialRequest, action.payload);
}

function* startSalesForceRequest(action) {
    log("start salesforce login", action);
    yield makeRequest(loginActions.salesForceSuccess, loginActions.salesForceError, doSocialRequest, action.payload);
}

function* startZendeskRequest(action) {
    log("start zendesk login", action);
    yield makeRequest(loginActions.zendeskSuccess, loginActions.zendeskError, doSocialRequest, action.payload);
}

function doLogin(payload = {}) {
    log("contacting server", payload);
    return post(endpoints.auth.login, payload);
}

function* requestLogin(action) {
    log("user asked to login, querying server", action);
    let response = yield call(doLogin, action.payload);

    log("server response", response);

    if (response.success) {
        const accessToken = response.response.auth_token;
        delete response.response.auth_token;

        yield put(loginActions.loginSuccess({ token: accessToken }));
    } else {
        yield put(loginActions.loginError(response.error));
    }
}

function doLogout() {
    log("contacting server");
    return post(endpoints.auth.logout);
}

function* requestLogout(action) {
    log("user asked to logout, querying server", action);
    const response = yield call(doLogout);

    log("server response", response);

    yield put(loginActions.logoutSuccess());
    yield put(push(routes.root));

    window.location.reload();
}

function sendResetPassword(actionPayload) {
    log("email is", actionPayload);
    log("transplanting the action to server payload", actionPayload);
    return post(endpoints.auth.resetPassword, actionPayload);
}

function* handleSendResetPassword(action) {
    log("got request to send password reset link", action);
    const { success, response, error } = yield call(sendResetPassword, action.payload);

    if (success) {
        log("save api OK");
        yield put(loginActions.submitPasswordResetSuccess(response));
    } else {
        log("api bad request", error);
        yield put(loginActions.submitPasswordResetError(error));
    }
}

// WATCHERS
export function* watchStartGoogleRequest() {
    yield takeEvery(types.GOOGLE_REQUEST, startGoogleRequest);
}

export function* watchStartSalesForceRequest() {
    yield takeEvery(types.SALESFORCE_REQUEST, startSalesForceRequest);
}

export function* watchStartZendeskRequest() {
    yield takeEvery(types.ZENDESK_REQUEST, startZendeskRequest);
}

export function* watchRequestLoginRequest() {
    yield takeEvery(types.LOGIN_REQUEST, requestLogin);
}

export function* watchRequestLogoutRequest() {
    yield takeEvery(types.LOGOUT_REQUEST, requestLogout);
}

export function* watchRequestResetPasswordRequest() {
    yield takeEvery(types.PASSWORD_RESET_REQUEST, handleSendResetPassword);
}
