import debug from "debug";
import { createAction } from "@reduxjs/toolkit";
import some from "lodash-es/some";

import { getErrorData } from "../../../utils/errorData";
import { parseCustomerAnalyticsServerResponse, parseReportFilterServerResponse, parseKpisServerResponse } from "../../../parsers/reports";
import { parseSegmentsServerResponse } from "../../../parsers/segments";

const log = debug("customerAnalyticsReducer");

const DEFAULT_STATE = {
    errors: null,
    loading: false,
    loadingKpis: false,
    loadingTiers: false,
    loadingStages: false,
    analyticsData: {},
    financialKpis: [],
    tiers: [],
    stages: [],
    success: null,
    savedFilter: {},
    savedNewFilter: {},
};

export const actions = {
    clearState: createAction("CUSTOMER_ANALYTICS/CLEAR_DATA"),

    requestCustomerAnalytics: createAction("CUSTOMER_ANALYTICS/REQUEST"),
    requestCustomerAnalyticsSuccess: createAction("CUSTOMER_ANALYTICS/REQUEST/SUCCESS"),
    requestCustomerAnalyticsError: createAction("CUSTOMER_ANALYTICS/REQUEST/ERROR"),

    requestFinancialKpis: createAction("CUSTOMER_ANALYTICS/KPIS/REQUEST"),
    requestFinancialKpisSuccess: createAction("CUSTOMER_ANALYTICS/KPIS/REQUEST/SUCCESS"),
    requestFinancialKpisError: createAction("CUSTOMER_ANALYTICS/KPIS/REQUEST/ERROR"),

    requestTiers: createAction("CUSTOMER_ANALYTICS/TIERS/REQUEST"),
    requestTiersSuccess: createAction("CUSTOMER_ANALYTICS/TIERS/REQUEST/SUCCESS"),
    requestTiersError: createAction("CUSTOMER_ANALYTICS/TIERS/REQUEST/ERROR"),

    requestStages: createAction("CUSTOMER_ANALYTICS/STAGES/REQUEST"),
    requestStagesSuccess: createAction("CUSTOMER_ANALYTICS/STAGES/REQUEST/SUCCESS"),
    requestStagesError: createAction("CUSTOMER_ANALYTICS/STAGES/REQUEST/ERROR"),

    saveReportFilter: createAction("CUSTOMER_ANALYTICS/SAVE_FILTER/REQUEST"),
    saveReportFilterSuccess: createAction("CUSTOMER_ANALYTICS/SAVE_FILTER/REQUEST/SUCCESS"),
    saveReportFilterError: createAction("CUSTOMER_ANALYTICS/SAVE_FILTER/REQUEST/ERROR"),

    requestSavedReportFilter: createAction("CUSTOMER_ANALYTICS/SAVED_FILTER/REQUEST"),
    requestSavedReportFilterSuccess: createAction("CUSTOMER_ANALYTICS/SAVED_FILTER/REQUEST/SUCCESS"),
    requestSavedReportFilterError: createAction("CUSTOMER_ANALYTICS/SAVED_FILTER/REQUEST/ERROR"),

    requestDeleteSavedFilter: createAction("CUSTOMER_ANALYTICS/SAVED_FILTER/DELETE/REQUEST"),
    requestDeleteSavedFilterSuccess: createAction("CUSTOMER_ANALYTICS/SAVED_FILTER/DELETE/REQUEST/SUCCESS"),
    requestDeleteSavedFilterError: createAction("CUSTOMER_ANALYTICS/SAVED_FILTER/DELETE/REQUEST/ERROR"),
};

export default function customerAnalyticsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE;
        }

        case actions.requestCustomerAnalytics.type:
        case actions.requestSavedReportFilter.type: {
            return {
                ...state,
                errors: null,
                loading: true,
            };
        }

        case actions.requestFinancialKpis.type: {
            return {
                ...state,
                errors: null,
                loadingKpis: true,
            };
        }

        case actions.requestTiers.type: {
            return {
                ...state,
                errors: null,
                loadingTiers: true,
            };
        }

        case actions.requestStages.type: {
            return {
                ...state,
                errors: null,
                loadingStages: true,
            };
        }

        case actions.saveReportFilter.type: {
            return {
                ...state,
                errors: null,
                loading: true,
                success: null,
            };
        }

        case actions.requestDeleteSavedFilter.type: {
            return {
                ...state,
                errors: null,
                loading: true,
                success: null,
            };
        }

        case actions.requestCustomerAnalyticsSuccess.type: {
            log("analytics data is", action.payload);
            const analyticsData = parseCustomerAnalyticsServerResponse(action.payload);
            return {
                ...state,
                errors: null,
                loading: false,
                analyticsData: analyticsData,
            };
        }

        case actions.requestFinancialKpisSuccess.type: {
            log("kpis", action.payload);
            return {
                ...state,
                errors: null,
                loadingKpis: false,
                financialKpis: parseKpisServerResponse(action.payload),
            };
        }

        case actions.requestTiersSuccess.type: {
            log("tiers", action.payload);
            return {
                ...state,
                errors: null,
                loadingTiers: false,
                tiers: parseSegmentsServerResponse(action.payload),
            };
        }

        case actions.requestStagesSuccess.type: {
            log("stages", action.payload);
            return {
                ...state,
                errors: null,
                loadingStages: false,
                stages: parseSegmentsServerResponse(action.payload),
            };
        }

        case actions.saveReportFilterSuccess.type: {
            return {
                ...state,
                errors: null,
                loading: false,
                success: true,
                savedNewFilter: parseReportFilterServerResponse(action.payload),
            };
        }

        case actions.requestSavedReportFilterSuccess.type: {
            return {
                ...state,
                errors: null,
                loading: false,
                savedFilter: parseReportFilterServerResponse(action.payload),
            };
        }

        case actions.requestDeleteSavedFilterSuccess.type: {
            return {
                ...state,
                errors: null,
                loading: false,
                success: true,
                savedNewFilter: {},
            };
        }

        case actions.requestCustomerAnalyticsError.type:
        case actions.requestSavedReportFilterError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
            };
        }

        case actions.requestFinancialKpisError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loadingKpis: false,
                errors: getErrorData(action.payload),
            };
        }

        case actions.requestTiersError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loadingTiers: false,
                errors: getErrorData(action.payload),
            };
        }

        case actions.requestStagesError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loadingStages: false,
                errors: getErrorData(action.payload),
            };
        }

        case actions.saveReportFilterError.type:
        case actions.requestDeleteSavedFilterError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
                success: false,
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => some([
    state.customerAnalytics.loading,
    state.customerAnalytics.loadingKpis,
    state.customerAnalytics.loadingTiers,
    state.customerAnalytics.loadingStages,
], (val) => val === true);
export const getCustomerAnalytics = (state) => state.customerAnalytics.analyticsData;
export const getFinancialKpis = (state) => state.customerAnalytics.financialKpis;
export const getTiers = (state) => state.customerAnalytics.tiers;
export const getStages = (state) => state.customerAnalytics.stages;
export const getErrors = (state) => state.customerAnalytics.errors;
export const getSuccess = (state) => state.customerAnalytics.success;
export const getSavedFilter = (state) => state.customerAnalytics.savedFilter;
export const getSavedNewFilter = (state) => state.customerAnalytics.savedNewFilter;
