import React from "react";
import debug from "debug";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

import { routes } from "../../constants/routes";
import { actions as loginActions } from "../../reducers/auth";

const log = debug("PrivateRoute");

class PrivateRoute extends React.Component {
    static propTypes = {
        authenticated: PropTypes.bool,
        location: PropTypes.object,
    };

    static defaultProps = {
        authenticated: false,
    };

    getRoute = () => {
        const { component: Component, location, authenticated } = this.props;
        const redirectTo = location.pathname + location.search;

        log("checking if user is logged in", authenticated, redirectTo);

        if (authenticated) {
            return <Component {...this.props} />;
        }

        return <Redirect to={routes.root} />;
    };

    render() {
        const { component, ...rest } = this.props;
        return <Route {...rest} render={this.getRoute} />;
    }
}

export default PrivateRoute;
