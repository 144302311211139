export const endpoints = {
    auth: {
        register: "/auth/signup/",
        confirmEmail: "/auth/signup/verify-email/",
        login: "/auth/token/login/",
        logout: "/auth/token/logout/",
        profile: "/auth/users/me/",
        resetPassword: "/auth/users/reset_password/",
        newPassword: "/auth/users/reset_password_confirm/",
        setPassword: "/auth/users/set_password/",
        googleCallback: "/auth/google/callback/",
        salesForceCallback: "/auth/salesforce/callback/",
        zendeskCallback: "/auth/zendesk/callback/",
    },
    accountData: {
        financialKpis: "/account/financial-kpis/",
        segments: "/account/:account/segments/",
        customers: "/account/:account/customers/",
        customersSimple: "/account/customers/",
        contactTypes: "/account/:account/contact-types/",
        classifications: "/account/news-item-classifications/",
        goals: "/account/news-item-goals/",
        managers: "/account/managers/",
    },
    newsItems: {
        publicCreate: "/news-items/public-create/",
        create: "/news-items/create/",
        listNewsItems: "/news-items/",
        update: "/news-items/:item/update/",
        listNewsItemsCustomer: "/customer/:customer/news-items/",
        listNewsItemsContact: "/contact/:contact/news-items/",
        listNewsItemTag: "/account/:account/tags/",
    },
    contacts: {
        list: "/contacts/",
        customer: "/customers/:customer/contacts/",
        create: "/contacts/create/",
        updateContact: "/contacts/:contact/",
        updateRelevance: "/news-items/:item/contact-relevance/:contact/update/",
        availableNewsItems: "/contact/:contact/available-news-items/",
        updateContactNewsItems: "/contact/:contact/add-contact-news-items/",
    },
    contactMessages: {
        list: "/contact-messages/",
        send: "/contact-messages/:cm/send/",
        updateView: "/contact-messages/:cm/",
        create: "/contact/:contact/create-message/",
        feedback: "/contact-messages/:cm/feedback/",
        listFeedbacks: "/feedbacks/",
    },
    campaigns: {
        addContacts: "/campaigns/:campaign/add-contacts/",
        details: "/campaigns/:campaign/",
        execute: "/campaigns/:campaign/execute/",
        listContacts: "/campaigns/:campaign/customer/:customer/contacts/",
        listCreateUpdate: "/campaigns/",
        listCustomers: "/campaigns/:campaign/customers/",
        massCreate: "/campaigns/templated/:template/create-and-execute/",
        report: "/campaigns/:campaign/report/",
        removeContacts: "/campaigns/:campaign/remove-contacts/",
        templates: "/:account/campaign-templates/",
        updateNewsItems: "/campaigns/manage-campaign-news-item/:cni/",
        validateReport: "/mass-campaign/validate-salesforce-report/",
    },
    interactions: {
        classify: "/sentiments/:interaction/classify/",
        details: "/interactions/:interaction/",
        search: "/interactions/search/",
        sentimentsList: "/sentiments/",
        savedSearches: "/interactions/saved-searches/",
        savedSearch: "/interactions/saved-searches/:searchSlug/",
        saveSearch: "/interactions/save-search/",
        shareSavedSearch: "/interactions/saved-searches/:searchSlug/share/",
    },
    reports: {
        customerAnalytics: "/analytics/report-data/",
        saveFilter: "/analytics/save-report-filters/",
        savedFilter: "/analytics/saved-report-filters/:filterSlug",
        savedFilters: "/analytics/saved-report-filters/",
    },
    customers: {
        list: "/customers/",
        details: "/customers/:customer/",
        insights: "/customers/:customer/insights/",
    },
    insights: {
        updateVerdict: "/gaddon/insights/:insight/verdict/",
    },
};
