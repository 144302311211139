// @flow
import type { NewsItemSlim, NewsItemContact } from "../types/newsItems";
import { parseContactTypeServerResponse } from "./contacts";
import { toArray } from "./utils";

export function parseNewsItemSlimServerResponse(data: Object): NewsItemSlim {
    return {
        slug: data.slug,
        title: data.title,
        description: data.description,
        link: data.link,
        deadline: data.deadline,
        email: data.email,
    };
}

export function parseNewsItemsSlimServerResponse(data: $ReadOnlyArray<Object>): Array<NewsItemSlim> {
    return toArray(data, parseNewsItemSlimServerResponse);
}

export function parseContactNewsItemServerResponse(data: Object): NewsItemContact {
    return {
        newsItem: parseNewsItemSlimServerResponse(data.news_item),
        contact: {
            slug: data.contact.slug,
            name: data.contact.name,
            contactType: data.contact.contact_type ? parseContactTypeServerResponse(data.contact.contact_type) : null,
            email: data.contact.email,
            customerName: data.contact.customer_name,
        },
        relevance: {
            slug: data.relevance.slug,
            state: data.relevance.state,
        },
    };
}
