import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('campaignReportReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    campaignReport: [],
};

// ACTION TYPES
export const types = {
    CAMPAIGN_REPORT_REQUEST: 'CAMPAIGN_REPORT/REQUEST',
    CAMPAIGN_REPORT_REQUEST_SUCCESS: 'CAMPAIGN_REPORT/REQUEST/SUCCESS',
    CAMPAIGN_REPORT_REQUEST_ERROR: 'CAMPAIGN_REPORT/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    requestCampaignReport: (campaign) => ({
        type: types.CAMPAIGN_REPORT_REQUEST,
        campaign: campaign,
    }),
    requestCampaignReportSuccess: (payload) => ({
        type: types.CAMPAIGN_REPORT_REQUEST_SUCCESS,
        payload,
    }),
    requestCampaignReportError: (errors) => ({
        type: types.CAMPAIGN_REPORT_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function campaignReportReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CAMPAIGN_REPORT_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGN_REPORT_REQUEST_SUCCESS: {
            log('campaign report data', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                campaignReport: action.payload,
            }
        }

        case types.CAMPAIGN_REPORT_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.campaignReport.loading;
export const getErrors = (state) => state.campaignReport.errors;
export const getSuccess = (state) => state.campaignReport.success;
export const getCampaignReport = (state) => state.campaignReport.campaignReport;
