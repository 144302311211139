// @flow
import debug from "debug";
import { parseCustomersSimpleServerResponse } from "../../../parsers/customers";
import type { CustomerSimple } from "../../../types/customers";
import { fetchCustomers } from "../../NewsItemForm/api";

const log = debug("CustomersAsyncSelectAPI");

export function searchCustomers(
    inputValue: string,
    reactSelectCallback: (searchResults: Array<CustomerSimple>) => void
): void {
    if (inputValue.length < 3) {
        // we don't search with less than 3 characters
        reactSelectCallback([]);
        return;
    }
    fetchCustomers(inputValue).then(
        (resp) => {
            log("got search resp", resp);
            if (typeof resp.response === "object" && Array.isArray(resp.response.results)) {
                reactSelectCallback(parseCustomersSimpleServerResponse(resp.response.results));
            } else {
                log("there was some error searching customers");
                reactSelectCallback([]);
            }
        },
        (err) => {
            log("error", err);
            reactSelectCallback([]);
        }
    );
}
