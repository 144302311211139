import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/contactMessages";
import {makeRequest} from "./utils";

const log = debug("contactMessagesSaga");


function getAccountContactMessages(offset, limit, search) {
    log('requesting contact messages');
    const getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    return get(endpoints.contactMessages.list, getParams);
}

function* getContactMessagesData(action) {
    log('got a request to get contact messages', action);
    yield makeRequest(
        actions.requestContactMessagesSuccess,
        actions.requestContactMessagesError,
        getAccountContactMessages,
        action.offset, action.limit, action.search
    );
}

function getAccountContactMessagesRefresh(offset, limit, search) {
    log('requesting contact messages, refreshed');
    const getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    return get(endpoints.contactMessages.list, getParams);
}

function* getContactMessagesDataRefresh(action) {
    log('got a request to get contact messages, refreshed', action);
    yield makeRequest(
        actions.requestRefreshContactMessagesSuccess,
        actions.requestRefreshContactMessagesError,
        getAccountContactMessagesRefresh,
        action.offset, action.limit, action.search
    );
}


// WATCHERS
export function* watchRequestForContactMessages() {
    yield takeEvery(types.CONTACT_MESSAGES_DATA_REQUEST, getContactMessagesData);
}

export function* watchRequestForContactMessagesRefresh() {
    yield takeEvery(types.CONTACT_MESSAGES_DATA_REFRESH_REQUEST, getContactMessagesDataRefresh);
}
