// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ObjectSlugString, ServerResponse } from "../../../types/common";
import { deleteMethod, get } from "../../../utils/requester";
import type { SavedSearchesParams } from "./types";

const log = debug("InteractionsSearchAPI");

export function getSavedSearches(params: ?SavedSearchesParams): Promise<ServerResponse> {
    log("requesting saved searches", params);
    return get(endpoints.interactions.savedSearches, params);
}

export function getSavedSearchesRefreshed(params: ?SavedSearchesParams): Promise<ServerResponse> {
    log("requesting saved searches, refreshed", params);
    return get(endpoints.interactions.savedSearches, params);
}

export function deleteSavedSearch(searchSlug: ObjectSlugString): Promise<ServerResponse> {
    log("requesting to delete saved search", searchSlug);
    return deleteMethod(endpoints.interactions.savedSearch.replace(":searchSlug", searchSlug));
}
