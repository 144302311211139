import debug from "debug";

import {getErrorData} from "../utils/errorData";
import {createAction} from "@reduxjs/toolkit";

const log = debug('campaignAddRecipientsReducer');

const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    contacts: [],
    totalCount: 0,
};

export const actions = {
    requestContacts: createAction("CAMPAIGN_ADD_RECIPIENTS/GET_CONTACTS/REQUEST"),
    requestContactsSuccess: createAction("CAMPAIGN_ADD_RECIPIENTS/GET_CONTACTS/REQUEST/SUCCESS"),
    requestContactsError: createAction("CAMPAIGN_ADD_RECIPIENTS/GET_CONTACTS/REQUEST/ERROR"),

    requestAddContacts: createAction("CAMPAIGN_ADD_RECIPIENTS/ADD_CONTACTS/REQUEST"),
    requestAddContactsSuccess: createAction("CAMPAIGN_ADD_RECIPIENTS/ADD_CONTACTS/REQUEST/SUCCESS"),
    requestAddContactsError: createAction("CAMPAIGN_ADD_RECIPIENTS/ADD_CONTACTS/REQUEST/ERROR"),
}


export default function campaignAddRecipientsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.requestContacts.type: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case actions.requestContactsSuccess.type: {
            let contacts;
            if (action.payload.getMore && action.payload.previous !== null) {
                contacts = Array.from(state.contacts);
                contacts.concat(action.payload.results);
            } else {
                contacts = Array.from(action.payload.results);
            }
            return {
                ...state,
                loading: false,
                errors: null,
                contacts: contacts,
                totalCount: action.payload.count,
            }

        }

        case actions.requestContactsError.type: {
            log('error is', action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
            }
        }

        case actions.requestAddContacts.type: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case actions.requestAddContactsSuccess.type: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case actions.requestAddContactsError.type: {
            log('error is', action.payload);
            return {
                ...state,
                loading: false,
                success: false,
                errors: getErrorData(action.payload),
            }
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => state.campaignAddRecipients.loading;
export const getErrors = (state) => state.campaignAddRecipients.errors;
export const getSuccess = (state) => state.campaignAddRecipients.success;
export const getContacts = (state) => state.campaignAddRecipients.contacts;
export const getTotal = (state) => state.campaignAddRecipients.totalCount;
