import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('contactAddNewsItemsReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    newsItems: [],
    totalCount: 0,
    newsItemsToAdd: [],
};

// ACTION TYPES
export const types = {
    CONTACT_NEWS_ITEMS_CLEAR_DATA: 'CONTACT_NEWS_ITEMS/CLEAR/DATA',

    CONTACT_NEWS_ITEMS_DATA_REQUEST: 'CONTACT_NEWS_ITEMS/REQUEST',
    CONTACT_NEWS_ITEMS_DATA_REQUEST_SUCCESS: 'CONTACT_NEWS_ITEMS/REQUEST/SUCCESS',
    CONTACT_NEWS_ITEMS_DATA_REQUEST_ERROR: 'CONTACT_NEWS_ITEMS/REQUEST/ERROR',

    CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST: 'CONTACT_NEWS_ITEMS_REFRESH/REQUEST',
    CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST_SUCCESS: 'CONTACT_NEWS_ITEMS_REFRESH/REQUEST/SUCCESS',
    CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST_ERROR: 'CONTACT_NEWS_ITEMS_REFRESH/REQUEST/ERROR',

    ADDING_NEWS_ITEMS: 'ADDING_NEWS_ITEMS/REQUEST',
    ADDING_NEWS_ITEMS_SUCCESS: 'ADDING_NEWS_ITEMS/REQUEST/SUCCESS',
    ADDING_NEWS_ITEMS_ERROR: 'ADDING_NEWS_ITEMS/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    clearData: () => ({
        type: types.CONTACT_NEWS_ITEMS_CLEAR_DATA,
    }),

    requestContactNewsItems: (contact, offset, limit, search) => ({
        type: types.CONTACT_NEWS_ITEMS_DATA_REQUEST,
        contact: contact,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestContactNewsItemsSuccess: (payload) => ({
        type: types.CONTACT_NEWS_ITEMS_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestContactNewsItemsError: (errors) => ({
        type: types.CONTACT_NEWS_ITEMS_DATA_REQUEST_ERROR,
        errors,
    }),

    requestRefreshContactNewsItems: (contact, offset, limit, search) => ({
        type: types.CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST,
        contact: contact,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestRefreshContactNewsItemsSuccess: (payload) => ({
        type: types.CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshContactNewsItemsError: (errors) => ({
        type: types.CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST_ERROR,
        errors,
    }),

    addNewsItems: (payload, contact) => ({
        type: types.ADDING_NEWS_ITEMS,
        payload,
        contact,
    }),
    addNewsItemsSuccess: (payload) => ({
        type: types.ADDING_NEWS_ITEMS_SUCCESS,
        payload,
    }),
    addNewsItemsError: (errors) => ({
        type: types.ADDING_NEWS_ITEMS_ERROR,
        errors,
    }),
};

// REDUCERS
export default function contactAddNewsItemsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CONTACT_NEWS_ITEMS_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.CONTACT_NEWS_ITEMS_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CONTACT_NEWS_ITEMS_DATA_REQUEST_SUCCESS: {
            log('news items are', action.payload);
            let newsItems = Array.from(state.newsItems);
            newsItems = newsItems.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                newsItems: newsItems,
                totalCount: action.payload.count,
            }
        }

        case types.CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST_SUCCESS: {
            log('news items are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                newsItems: action.payload.results,
                totalCount: action.payload.count,
            }
        }

        case types.CONTACT_NEWS_ITEMS_DATA_REQUEST_ERROR:
        case types.CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.ADDING_NEWS_ITEMS: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
                newsItemsToAdd: action.payload.newsItemsToAdd,
            }
        }

        case types.ADDING_NEWS_ITEMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.ADDING_NEWS_ITEMS_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.contactAddNewsItems.loading;
export const getContactNewsItems = (state) => state.contactAddNewsItems.newsItems;
export const getErrors = (state) => state.contactAddNewsItems.errors;
export const getSuccess = (state) => state.contactAddNewsItems.success;
export const getTotal = (state) => state.contactAddNewsItems.totalCount;
