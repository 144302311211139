import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { fetchSavedFilters, fetchSavedFiltersRefreshed } from "./api";
import { actions } from "./reducer";

const log = debug("SavedFiltersSaga");

function* savedFiltersFetchRequest(action) {
    log('got a request to fetch saved filters', action);
    yield makeRequest(
        actions.savedFiltersSuccess,
        actions.savedFiltersError,
        fetchSavedFilters,
        action.payload
    );
}

function* savedFiltersFetchRefreshedRequest(action) {
    log('got a request to fetch saved filters, refreshed', action);
    yield makeRequest(
        actions.savedFiltersRefreshedSuccess,
        actions.savedFiltersRefreshedError,
        fetchSavedFiltersRefreshed,
        action.payload
    );
}

const sagas = [
    takeLatest(actions.savedFiltersRequest.type, savedFiltersFetchRequest),
    takeLatest(actions.savedFiltersRefreshedRequest.type, savedFiltersFetchRefreshedRequest),
];
export default sagas;
