import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get, post, patch} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/campaignForm";
import {makeRequest} from "./utils";

const log = debug("campaignForm");


function getCampaignNewsItems(show, offset, limit, search) {
    log('requesting campaign news items');
    let getParams = {
        status: "APPROVED",
        offset: offset,
        limit: limit,
        search: search,
    };
    if (show === "recent") {
        getParams = {
            ...getParams,
            age_days: "30",
        }
    }
    return get(endpoints.newsItems.listNewsItems, getParams);
}

function* getCampaignNewsItemsData(action) {
    log('got a request to get campaign news items', action);
    yield makeRequest(actions.requestCampaignNewsItemsSuccess, actions.requestCampaignNewsItemsError, getCampaignNewsItems, action.show, action.offset, action.limit, action.search)
}

function getCampaignNewsItemsRefresh(show, offset, limit, search) {
    log('requesting campaign news items, refreshed');
    let getParams = {
        status: "APPROVED",
        offset: offset,
        limit: limit,
        search: search,
    };
    if (show === "recent") {
        getParams = {
            ...getParams,
            age_days: "30",
        }
    }
    return get(endpoints.newsItems.listNewsItems, getParams);
}

function* getCampaignNewsItemsDataRefresh(action) {
    log('got a request to get campaign news items, refreshed', action);
    yield makeRequest(
        actions.requestRefreshCampaignNewsItemsSuccess,
        actions.requestRefreshCampaignNewsItemsError,
        getCampaignNewsItemsRefresh,
        action.show, action.offset, action.limit, action.search
    );
}

function getCampaignContacts(contactType, managed, offset, limit, search) {
    log('requesting campaign contacts');
    let getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    if (managed) {
        getParams = {
            ...getParams,
            managed: managed,
        }
    }
    if (contactType !== "") {
        getParams = {
            ...getParams,
            contact_type: contactType,
        }
    }
    return get(endpoints.contacts.list, getParams);
}

function* getCampaignContactsData(action) {
    log('got a request to get campaign contacts', action);
    yield makeRequest(
        actions.requestCampaignContactsSuccess,
        actions.requestCampaignContactsError,
        getCampaignContacts,
        action.contactType, action.managed, action.offset, action.limit, action.search
    );
}

function getCampaignContactsRefresh(contactType, managed, offset, limit, search) {
    log('requesting campaign contacts, refreshed');
    let getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    if (managed) {
        getParams = {
            ...getParams,
            managed: managed,
        }
    }
    if (contactType !== "") {
        getParams = {
            ...getParams,
            contact_type: contactType,
        }
    }
    return get(endpoints.contacts.list, getParams);
}

function* getCampaignContactsDataRefresh(action) {
    log('got a request to get campaign contacts, refreshed', action);
    yield makeRequest(
        actions.requestRefreshCampaignContactsSuccess,
        actions.requestRefreshCampaignContactsError,
        getCampaignContactsRefresh,
        action.contactType, action.managed, action.offset, action.limit, action.search
    );
}

function saveCampaign(actionPayload) {
    let payload = {
        is_draft: actionPayload.draft,
    };

    if ('campaignDetails' in actionPayload) {
        payload = {
            ...payload,
            name: actionPayload.campaignDetails.campaignName,
            cs_managers: actionPayload.campaignDetails.csManagers,
            all_cs_managers: actionPayload.campaignDetails.allCSMs,
            kind: actionPayload.campaignDetails.campaignType.value,
            contact_types: actionPayload.campaignDetails.contactTypes,
        };

        if (actionPayload.campaignDetails.customers != null) {
            payload.customers = actionPayload.campaignDetails.customers;
        }

        if (actionPayload.campaignDetails.segments != null) {
            payload.segments = actionPayload.campaignDetails.segments;
        }
    }

    if ('campaignNewsItems' in actionPayload) {
        payload = {
            ...payload,
            news_items: actionPayload.campaignNewsItems.newsItems,
        };
    }

    if ('campaignMessage' in actionPayload) {
        let selectedNewsItems = [];
        if (actionPayload.campaignMessage.newsItems) {
            if (actionPayload.campaignMessage.newsItems.length !== 0) {
                actionPayload.campaignMessage.newsItems.map((cni, i) => {
                    selectedNewsItems.push(cni.news_item);
                });
            }
        }

        payload = {
            ...payload,
            subject: actionPayload.campaignMessage.subject,
            body_greeting: actionPayload.campaignMessage.greeting,
            news_items: selectedNewsItems,
            body_footer: actionPayload.campaignMessage.footer,
        };
    }

    if ('campaignContacts' in actionPayload) {
        payload = {
            ...payload,
            contacts: actionPayload.campaignContacts.contacts,
        };
    }

    if (actionPayload.campaign !== "") {
        payload = {
            ...payload,
            slug: actionPayload.campaign,
        };
        log('transplanting the action to server payload', payload);
        return patch(endpoints.campaigns.listCreateUpdate, payload);
    } else {
        log("brand new campaign");
        log('transplanting the action to server payload', payload);
        return post(endpoints.campaigns.listCreateUpdate, payload);
    }
}

function* handleSaveCampaign(action) {
    log('got request to save campaign', action);
    yield makeRequest(actions.submitCampaignSuccess, actions.submitCampaignError, saveCampaign, action.payload);
}

function saveCampaignNewsItem(actionPayload) {
    log('transplanting the action to server payload', actionPayload);
    return patch(endpoints.campaigns.updateNewsItems.replace(':cni', actionPayload.slug), actionPayload);
}

function* handleSaveCampaignNewsItem(action) {
    log('got a request to save campaign message', action);
    yield makeRequest(actions.submitCampaignNewsItemSuccess, actions.submitCampaignNewsItemError, saveCampaignNewsItem, action.payload);
}

function getCampaignContent(campaign) {
    log('requesting campaign content');
    return get(endpoints.campaigns.details.replace(':campaign', campaign));
}

function* getCampaignContentData(action) {
    log('got a request to get campaign content', action);
    yield makeRequest(actions.requestCampaignContentSuccess, actions.requestCampaignContentError, getCampaignContent, action.campaign);
}


// WATCHERS
export function* watchRequestForCampaignNewsItems() {
    yield takeEvery(types.CAMPAIGN_NEWS_ITEMS_REQUEST, getCampaignNewsItemsData);
}

export function* watchRequestForCampaignNewsItemsRefresh() {
    yield takeEvery(types.CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST, getCampaignNewsItemsDataRefresh);
}

export function* watchRequestForCampaignContacts() {
    yield takeEvery(types.CAMPAIGN_CONTACTS_REQUEST, getCampaignContactsData);
}

export function* watchRequestForCampaignContactsRefresh() {
    yield takeEvery(types.CAMPAIGN_CONTACTS_REFRESH_REQUEST, getCampaignContactsDataRefresh);
}

export function* watchRequestSaveCampaign() {
    yield takeEvery(types.SUBMITTING_CAMPAIGN, handleSaveCampaign);
}

export function* watchRequestSaveCampaignNewsItem() {
    yield takeEvery(types.SUBMITTING_CAMPAIGN_NEWS_ITEM, handleSaveCampaignNewsItem);
}

export function* watchRequestGetCampaignContent() {
    yield takeEvery(types.CAMPAIGN_CONTENT_REQUEST, getCampaignContentData);
}
