// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ObjectSlugString, ServerResponse } from "../../../types/common";
import type { NewsItemContact } from "../../../types/newsItems";
import { get, patch, post } from "../../../utils/requester";

const log = debug("CustomerContactsApi");

export function getCustomerInfo(customer: ObjectSlugString, account: ObjectSlugString): Promise<ServerResponse> {
    log("requesting customer info", customer);
    return get(endpoints.accountData.customers.replace(":account", account), { customer: customer });
}

export function getNewsItemsCustomer(customer: ObjectSlugString): Promise<ServerResponse> {
    log("requesting customer news items", customer);
    return get(endpoints.newsItems.listNewsItemsCustomer.replace(":customer", customer));
}

export function getContactsCustomer(customer: ObjectSlugString): Promise<ServerResponse> {
    log("requesting customer contacts", customer);
    return get(endpoints.contacts.customer.replace(":customer", customer));
}

export function getContactTypes(customer: ObjectSlugString, account: ObjectSlugString): Promise<ServerResponse> {
    log("requesting contact types", account);
    return get(endpoints.accountData.contactTypes.replace(":account", account));
}

export function updateNewsItemContactRelevance(actionPayload: NewsItemContact): Promise<ServerResponse> {
    log("news item is", actionPayload);
    let payload = {
        news_item: actionPayload.newsItem,
        contact: actionPayload.contact,
        relevance: actionPayload.relevance,
    };
    log("transplanting the action to server payload", payload);
    return patch(
        endpoints.contacts.updateRelevance
            .replace(":item", actionPayload.newsItem)
            .replace(":contact", actionPayload.contact),
        payload
    );
}

export function createInitialContactMessage(contact: ObjectSlugString): Promise<ServerResponse> {
    log("contact is", contact);
    log("transplanting the action to server");
    return post(endpoints.contactMessages.create.replace(":contact", contact));
}
