import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('NewsItemFormCustomersReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    account: '',
    status: {},
    title: '',
    description: '',
    link: '',
    segments: [],
    customers: [],
    contactTypes: [],
    deadline: null,
};

// ACTION TYPES
export const types = {
    NEWS_ITEM_CUSTOMERS_CLEAR_DATA: 'NEW_NEWS_ITEM_CUSTOMERS/CLEAR/DATA',

    SUBMITTING_NEWS_ITEM_CUSTOMERS: 'NEW_NEWS_ITEM_CUSTOMERS/SUBMIT',
    SUBMITTING_NEWS_ITEM_CUSTOMERS_SUCCESS: 'NEW_NEWS_ITEM_CUSTOMERS/SUBMIT/SUCCESS',
    SUBMITTING_NEWS_ITEM_CUSTOMERS_ERROR: 'NEW_NEWS_ITEM_CUSTOMERS/SUBMIT/ERROR',
};

// ACTIONS
export const actions = {
    clearForm: () => ({
        type: types.NEWS_ITEM_CUSTOMERS_CLEAR_DATA,
    }),

    saveNewNewsItemCustomer: (payload) => ({
        type: types.SUBMITTING_NEWS_ITEM_CUSTOMERS,
        payload,
    }),
    saveNewNewsItemCustomerSuccess: (payload) => ({
        type: types.SUBMITTING_NEWS_ITEM_CUSTOMERS_SUCCESS,
        payload,
    }),
    saveNewNewsItemCustomerError: (errors) => ({
        type: types.SUBMITTING_NEWS_ITEM_CUSTOMERS_ERROR,
        errors,
    }),
};

// REDUCERS
export default function newNewsItemCustomersReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.NEWS_ITEM_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.SUBMITTING_NEWS_ITEM_CUSTOMERS: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
                account: action.payload.account,
                status: action.payload.status,
                title: action.payload.title,
                description: action.payload.description,
                link: action.payload.link,
                segments: action.payload.segments,
                customers: action.payload.customers,
                deadline: action.payload.deadline,
            }
        }

        case types.SUBMITTING_NEWS_ITEM_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.SUBMITTING_NEWS_ITEM_CUSTOMERS_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.newsItemFormCustomers.loading;
export const getErrors = (state) => state.newsItemFormCustomers.errors;
export const getSuccess = (state) => state.newsItemFormCustomers.success;
