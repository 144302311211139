import debug from "debug";
import { createAction } from "@reduxjs/toolkit";

import { getErrorData } from "../../../utils/errorData";
import { parseCustomersListServerResponse } from "../../../parsers/customersList";

const log = debug("customersListReducer");

const DEFAULT_STATE = {
    errors: null,
    loading: false,
    customersList: [],
    totalCount: 0,
};

export const actions = {
    clearState: createAction("CUSTOMER_ANALYTICS/CLEAR_DATA"),

    requestCustomersList: createAction("CUSTOMERS_LIST/REQUEST"),
    requestCustomersListSuccess: createAction("CUSTOMERS_LIST/REQUEST/SUCCESS"),
    requestCustomersListError: createAction("CUSTOMERS_LIST/REQUEST/ERROR"),

    requestCustomersListRefreshed: createAction("CUSTOMERS_LIST_REFRESHED/REQUEST"),
    requestCustomersListRefreshedSuccess: createAction("CUSTOMERS_LIST_REFRESHED/REQUEST/SUCCESS"),
    requestCustomersListRefreshedError: createAction("CUSTOMERS_LIST_REFRESHED/REQUEST/ERROR"),
};

export default function customersListReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE;
        }

        case actions.requestCustomersList.type:
        case actions.requestCustomersListRefreshed.type: {
            return {
                ...state,
                errors: null,
                loading: true,
            };
        }

        case actions.requestCustomersListSuccess.type: {
            log("customers list is", action.payload);
            let customersList = Array.from(state.customersList)
            customersList = customersList.concat(parseCustomersListServerResponse(action.payload.results));
            return {
                ...state,
                errors: null,
                loading: false,
                customersList: customersList,
                totalCount: action.payload.count,
            };
        }

        case actions.requestCustomersListRefreshedSuccess.type: {
            log("customers list is", action.payload);
            return {
                ...state,
                errors: null,
                loading: false,
                customersList: parseCustomersListServerResponse(action.payload.results),
                totalCount: action.payload.count,
            };
        }

        case actions.requestCustomersListError.type:
        case actions.requestCustomersListRefreshedError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => state.customersList.loading;
export const getCustomersList = (state) => state.customersList.customersList;
export const getErrors = (state) => state.customersList.errors;
export const getTotal = (state) => state.customersList.totalCount;
