import debug from "debug";
import { parseContactsServerResponse, parseContactTypesServerResponse } from "../../../parsers/contacts";
import { parseCustomerServerResponse } from "../../../parsers/customers";
import { parseContactNewsItemServerResponse, parseNewsItemSlimServerResponse } from "../../../parsers/newsItems";
import { getErrorData } from "../../../utils/errorData";
import { parseCustomerNewsItemContactServerResponse } from "./parsers";

const log = debug("customerContactsReducer");

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    updateRelevanceSuccess: null,
    customerInfo: {},
    customerNewsItems: {},
    customerContacts: [],
    contactTypeData: [],
};

// ACTION TYPES
export const types = {
    CUSTOMER_CONTENT_REQUEST: "CUSTOMER_CONTENT/REQUEST",
    CUSTOMER_CONTENT_REQUEST_SUCCESS: "CUSTOMER_CONTENT/REQUEST/SUCCESS",
    CUSTOMER_CONTENT_REQUEST_ERROR: "CUSTOMER_CONTENT/REQUEST/ERROR",

    CUSTOMER_INFO_REQUEST: "CUSTOMER_INFO/REQUEST",
    CUSTOMER_INFO_REQUEST_SUCCESS: "CUSTOMER_INFO/REQUEST/SUCCESS",
    CUSTOMER_INFO_REQUEST_ERROR: "CUSTOMER_INFO/REQUEST/ERROR",

    CUSTOMER_NEWS_ITEMS_REQUEST: "CUSTOMER_NEWS_ITEMS/REQUEST",
    CUSTOMER_NEWS_ITEMS_REQUEST_SUCCESS: "CUSTOMER_NEWS_ITEMS/REQUEST/SUCCESS",
    CUSTOMER_NEWS_ITEMS_REQUEST_ERROR: "CUSTOMER_NEWS_ITEMS/REQUEST/ERROR",

    CUSTOMER_CONTACTS_REQUEST: "CUSTOMER_CONTACTS/REQUEST",
    CUSTOMER_CONTACTS_REQUEST_SUCCESS: "CUSTOMER_CONTACTS/REQUEST/SUCCESS",
    CUSTOMER_CONTACTS_REQUEST_ERROR: "CUSTOMER_CONTACTS/REQUEST/ERROR",

    UPDATING_NEWS_ITEM_CONTACT_RELEVANCE: "NEWS_ITEM_CONTACT_RELEVANCE_UPDATE/REQUEST",
    UPDATING_NEWS_ITEM_CONTACT_RELEVANCE_SUCCESS: "NEWS_ITEM_CONTACT_RELEVANCE_UPDATE/REQUEST/SUCCESS",
    UPDATING_NEWS_ITEM_CONTACT_RELEVANCE_ERROR: "NEWS_ITEM_CONTACT_RELEVANCE_UPDATE/REQUEST/ERROR",

    CREATING_CONTACT_MESSAGE: "CONTACT_MESSAGE/CREATE",
    CREATING_CONTACT_MESSAGE_SUCCESS: "CONTACT_MESSAGE/CREATE/SUCCESS",
    CREATING_CONTACT_MESSAGE_ERROR: "CONTACT_MESSAGE/CREATE/ERROR",
};

// ACTIONS
export const actions = {
    requestCustomerContent: (customer, account) => ({
        type: types.CUSTOMER_CONTENT_REQUEST,
        customer: customer,
        account: account,
    }),
    requestCustomerContentSuccess: (payload) => ({
        type: types.CUSTOMER_CONTENT_REQUEST_SUCCESS,
        payload,
    }),
    requestCustomerContentError: (errors) => ({
        type: types.CUSTOMER_CONTENT_REQUEST_ERROR,
        errors,
    }),

    requestCustomerInfo: (customer, account) => ({
        type: types.CUSTOMER_INFO_REQUEST,
        customer: customer,
        account: account,
    }),
    requestCustomerInfoSuccess: (payload) => ({
        type: types.CUSTOMER_INFO_REQUEST_SUCCESS,
        payload,
    }),
    requestCustomerInfoError: (errors) => ({
        type: types.CUSTOMER_INFO_REQUEST_ERROR,
        errors,
    }),

    requestCustomerNewsItems: (customer) => ({
        type: types.CUSTOMER_NEWS_ITEMS_REQUEST,
        customer: customer,
    }),
    requestCustomerNewsItemsSuccess: (payload) => ({
        type: types.CUSTOMER_NEWS_ITEMS_REQUEST_SUCCESS,
        payload,
    }),
    requestCustomerNewsItemsError: (errors) => ({
        type: types.CUSTOMER_NEWS_ITEMS_REQUEST_ERROR,
        errors,
    }),

    requestCustomerContacts: (customer) => ({
        type: types.CUSTOMER_CONTACTS_REQUEST,
        customer: customer,
    }),
    requestCustomerContactsSuccess: (payload) => ({
        type: types.CUSTOMER_CONTACTS_REQUEST_SUCCESS,
        payload,
    }),
    requestCustomerContactsError: (errors) => ({
        type: types.CUSTOMER_CONTACTS_REQUEST_ERROR,
        errors,
    }),

    updateNewsItemContactRelevance: (payload) => ({
        type: types.UPDATING_NEWS_ITEM_CONTACT_RELEVANCE,
        payload,
    }),
    updateNewsItemContactRelevanceSuccess: (payload) => ({
        type: types.UPDATING_NEWS_ITEM_CONTACT_RELEVANCE_SUCCESS,
        payload,
    }),
    updateNewsItemContactRelevanceError: (errors) => ({
        type: types.UPDATING_NEWS_ITEM_CONTACT_RELEVANCE_ERROR,
        errors,
    }),

    createContactMessage: (contact) => ({
        type: types.CREATING_CONTACT_MESSAGE,
        contact,
    }),
    createContactMessageSuccess: (payload) => ({
        type: types.CREATING_CONTACT_MESSAGE_SUCCESS,
        payload,
    }),
    createContactMessageError: (errors) => ({
        type: types.CREATING_CONTACT_MESSAGE_ERROR,
        errors,
    }),
};

// REDUCERS
export default function customerContactsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CUSTOMER_CONTENT_REQUEST:
        case types.CUSTOMER_INFO_REQUEST:
        case types.CUSTOMER_CONTACTS_REQUEST:
        case types.CUSTOMER_NEWS_ITEMS_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            };
        }

        case types.CUSTOMER_CONTENT_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                customerInfo: parseCustomerServerResponse(action.payload.customer.results[0]),
                customerNewsItems: parseCustomerNewsItemContactServerResponse(action.payload.newsItems),
                customerContacts: parseContactsServerResponse(action.payload.contacts),
                contactTypeData: parseContactTypesServerResponse(action.payload.contactTypes),
            };
        }

        case types.CUSTOMER_INFO_REQUEST_SUCCESS: {
            log("customer info is", action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                customerInfo: action.payload.results[0],
            };
        }

        case types.CUSTOMER_NEWS_ITEMS_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                customerNewsItems: parseCustomerNewsItemContactServerResponse(action.payload),
            };
        }

        case types.CUSTOMER_CONTACTS_REQUEST_SUCCESS: {
            log("customer contacts are", action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                customerContacts: action.payload,
            };
        }

        case types.CUSTOMER_CONTENT_REQUEST_ERROR:
        case types.CUSTOMER_INFO_REQUEST_ERROR:
        case types.CUSTOMER_NEWS_ITEMS_REQUEST_ERROR:
        case types.CUSTOMER_CONTACTS_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            };
        }

        case types.UPDATING_NEWS_ITEM_CONTACT_RELEVANCE: {
            return {
                ...state,
                loading: true,
                errors: null,
                updateRelevanceSuccess: null,
                newsItem: action.payload.newsItem,
                contact: action.payload.contact,
                relevance: action.payload.relevance,
            };
        }

        case types.UPDATING_NEWS_ITEM_CONTACT_RELEVANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                updateRelevanceSuccess: true,
            };
        }

        case types.UPDATING_NEWS_ITEM_CONTACT_RELEVANCE_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                updateRelevanceSuccess: false,
            };
        }

        case types.CREATING_CONTACT_MESSAGE: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            };
        }

        case types.CREATING_CONTACT_MESSAGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            };
        }

        case types.CREATING_CONTACT_MESSAGE_ERROR: {
            log("error is", action);
            const errorData = getErrorData(action.errors);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            };
        }

        default: {
            return state;
        }
    }
}

// SELECTORS
export const getIsLoading = (state) => state.customerContacts.loading;
export const getCustomerInfo = (state) => state.customerContacts.customerInfo;
export const getCustomerNewsItems = (state) => state.customerContacts.customerNewsItems;
export const getCustomerContacts = (state) => state.customerContacts.customerContacts;
export const getContactTypes = (state) => state.customerContacts.contactTypeData;
export const getErrors = (state) => state.customerContacts.errors;
export const getSuccess = (state) => state.customerContacts.success;
export const getUpdateRelevanceSuccess = (state) => state.customerContacts.updateRelevanceSuccess;
