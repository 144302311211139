// @flow
import debug from "debug";
import {endpoints} from "../../../constants/api";
import type {ServerResponse} from "../../../types/common";
import {get, post} from "../../../utils/requester";
import type {ClassificationPayload} from "./types";

const log = debug("InteractionDetailsAPI");

export function getInteractionDetails(interactionSlug: string): Promise<ServerResponse> {
    log("fetching interaction details", interactionSlug);
    return get(endpoints.interactions.details.replace(":interaction", interactionSlug));
}

export function saveInteractionClassification(payload: ClassificationPayload): Promise<ServerResponse> {
    log("saving interaction classification", payload, payload.interactionSlug);
    let actionPayload = {
        sentiment: payload.sentiment,
        style: payload.style,
        importance: payload.importance,
        urgency: payload.urgency,
        thread: payload.threadSlug,
    };
    return post(endpoints.interactions.classify.replace(":interaction", payload.interactionSlug), actionPayload);
}
