import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { getCustomerAnalytics, getFinancialKpis, getTiersStages, saveReportFilter, getSavedFilter, deleteSavedFilter } from "./api";
import { actions } from "./reducer";

const log = debug("CustomerAnalyticsSaga");

function* fetchCustomerAnalytics(action) {
    log("got request to fetch customer analytics", action);
    yield makeRequest(
        actions.requestCustomerAnalyticsSuccess,
        actions.requestCustomerAnalyticsError,
        getCustomerAnalytics,
        action.payload
    );
}

function* fetchFinancialKpis(action) {
    log("got request to fetch financial kpis", action);
    yield makeRequest(
        actions.requestFinancialKpisSuccess,
        actions.requestFinancialKpisError,
        getFinancialKpis,
        action.payload
    );
}

function* fetchTiers(action) {
    log("got request to fetch tiers", action);
    yield makeRequest(
        actions.requestTiersSuccess,
        actions.requestTiersError,
        getTiersStages,
        action.payload
    );
}

function* fetchStages(action) {
    log("got request to fetch stages", action);
    yield makeRequest(
        actions.requestStagesSuccess,
        actions.requestStagesError,
        getTiersStages,
        action.payload
    );
}


function* saveFilter(action) {
    log("got request to save filter", action);
    yield makeRequest(
        actions.saveReportFilterSuccess,
        actions.saveReportFilterError,
        saveReportFilter,
        action.payload
    );
}

function* fetchSavedReportFilter(action) {
    log("got request to fetch saved filter", action);
    yield makeRequest(
        actions.requestSavedReportFilterSuccess,
        actions.requestSavedReportFilterError,
        getSavedFilter,
        action.payload
    );
}

function* requestToDeleteSavedFilter(action) {
    log("got request to delete saved filter", action);
    yield makeRequest(
        actions.requestDeleteSavedFilterSuccess,
        actions.requestDeleteSavedFilterError,
        deleteSavedFilter,
        action.payload
    );
}

const sagas = [
    takeLatest(actions.requestCustomerAnalytics.type, fetchCustomerAnalytics),
    takeLatest(actions.requestFinancialKpis.type, fetchFinancialKpis),
    takeLatest(actions.requestTiers.type, fetchTiers),
    takeLatest(actions.requestStages.type, fetchStages),
    takeLatest(actions.saveReportFilter.type, saveFilter),
    takeLatest(actions.requestSavedReportFilter.type, fetchSavedReportFilter),
    takeLatest(actions.requestDeleteSavedFilter, requestToDeleteSavedFilter),
];
export default sagas;
