import {call, put, takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get, post, patch} from "../utils/requester";
import {endpoints} from "../constants/api";
import {actions} from "../reducers/campaignExecuteContacts";
import {makeRequest} from "./utils";

const log = debug("campaignExecuteContacts");


function getCampaignCustomerContacts({campaign, customer, offset, limit}) {
    log('requesting campaign customer contacts');
    const getParams = {
        offset: offset,
        limit: limit,
    };
    return get(endpoints.campaigns.listContacts.replace(':campaign', campaign).replace(':customer', customer), getParams);
}

function* getCampaignCustomersData(action) {
    log('got a request to get campaign customer contacts', action);
    yield makeRequest(actions.requestCampaignContactsSuccess, actions.requestCampaignContactsError, getCampaignCustomerContacts, action.payload);
}

function getCampaignCustomerContactsRefresh({campaign, customer, offset, limit}) {
    log('requesting campaign customer contacts, refreshed');
    const getParams = {
        offset: offset,
        limit: limit,
    };
    return get(endpoints.campaigns.listContacts.replace(':campaign', campaign).replace(':customer', customer), getParams);
}

function* getCampaignCustomersDataRefresh(action) {
    log('got a request to get campaign customer contacts, refreshed', action);
    yield makeRequest(actions.requestRefreshCampaignContactsSuccess, actions.requestRefreshCampaignContactsError, getCampaignCustomerContactsRefresh, action.payload);
}

function doRemoveContacts({campaignSlug, contactSlugs}) {
    log('requesting to remove contacts from campaign', campaignSlug, contactSlugs);
    return post(endpoints.campaigns.removeContacts.replace(":campaign", campaignSlug), {contacts: contactSlugs});
}

function* requestRemoveContacts(action) {
    log('got request to remove contacts', action);
    yield makeRequest(actions.requestRemoveContactsSuccess, actions.requestRemoveContactsError, doRemoveContacts, action.payload);
}

function doSendCampaignMessage(messageSlug) {
    log('requesting to send campaign message', messageSlug);
    return post(endpoints.contactMessages.send.replace(":cm", messageSlug));
}

function* requestSendCampaignMessage(action) {
    log('got request to send campaign message', action);
    yield makeRequest(actions.requestSendCampaignMessageSuccess, actions.requestSendCampaignMessageError, doSendCampaignMessage, action.payload);
}

// WATCHERS
export function* watchRequestGetCampaignCustomerContacts() {
    yield takeEvery(actions.requestCampaignContacts.type, getCampaignCustomersData);
}

export function* watchRequestGetCampaignCustomerContactsRefresh() {
    yield takeEvery(actions.requestRefreshCampaignContacts.type, getCampaignCustomersDataRefresh);
}

export function* watchRequestRemoveContacts() {
    yield takeEvery(actions.requestRemoveContacts.type, requestRemoveContacts);
}
export function* watchRequestSendCampaignMessage() {
    yield takeEvery(actions.requestSendCampaignMessage.type, requestSendCampaignMessage);
}
