// @flow
import debug from "debug";
import { parseContactNewsItemServerResponse } from "../../../parsers/newsItems";
import type { NewsItemContact } from "../../../types/newsItems";

const log = debug("CustomerContactsParsers");

export function parseCustomerNewsItemContactServerResponse(data: Array<Object>): { [string]: Array<NewsItemContact> } {
    log("customer news items are", data);
    let customerNewsItemsContacts = {};
    if (data.length !== 0) {
        customerNewsItemsContacts = data.reduce((acc, val) => {
            if (!acc[val.contact.slug]) {
                acc[val.contact.slug] = [];
            }
            acc[val.contact.slug].push(parseContactNewsItemServerResponse(val));
            return acc;
        }, {});
    }
    log("customer news items sorted by contact are", customerNewsItemsContacts);
    return customerNewsItemsContacts;
}
