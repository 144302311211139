import axios from "axios/index";
import debug from "debug";


const log = debug('requester');
const serverError = {non_field_error: "Server error. Please try again later."};


export function apiSuccessResponseParsing(response) {
    // log("status code", response);
    return {success: true, response: response.data, statusCode: response.status};
}

export function apiErrorResponseParsing(error) {
    log('got error', error.response, error.request, error.message, error.config);
    if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
        success: false,
        error: typeof error.response.data === 'string' ? serverError : error.response.data,
        statusCode: error.response.status,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
        success: false,
        error: serverError,
        statusCode: error.request.status,
    };
  }
  // Something happened in setting up the request that triggered an Error
  return {
        success: false,
        error: { message: error.message },
        statusCode: error.response ? error.response.status : 503,
    };
}

function mergeOptions(options = {}) {
    const accessToken = window.localStorage.getItem('token');

    const opts = Object.assign({}, {
        timeout: 300000,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    }, options);
    if (accessToken) {
        opts.headers['Authorization'] = `Token ${accessToken}`
    }
    return opts;
}

export function post(endpoint, data, options = {}, isFullURL = false) {
    const url = isFullURL ? endpoint : process.env.REACT_APP_API + endpoint;
    return axios.post(url, data, mergeOptions(options))
        .then(apiSuccessResponseParsing)
        .catch(apiErrorResponseParsing);
}

export function patch(endpoint, data, options = {}, isFullURL = false) {
    const url = isFullURL ? endpoint : process.env.REACT_APP_API + endpoint;
    return axios.patch(url, data, mergeOptions(options))
        .then(apiSuccessResponseParsing)
        .catch(apiErrorResponseParsing);
}

export function putMethod(endpoint, data, options = {}, isFullURL = false) {
    const url = isFullURL ? endpoint : process.env.REACT_APP_API + endpoint;
    return axios.put(url, data, mergeOptions(options))
        .then(apiSuccessResponseParsing)
        .catch(apiErrorResponseParsing);
}

export function deleteMethod(endpoint, options = {}, isFullURL = false) {
    const url = isFullURL ? endpoint : process.env.REACT_APP_API + endpoint;
    return axios.delete(url, mergeOptions(options))
        .then(apiSuccessResponseParsing)
        .catch(apiErrorResponseParsing);
}


export function get(endpoint, params, options = {}) {
    const url = process.env.REACT_APP_API + endpoint;
    const config = Object.assign({}, {params: params}, options);
    return axios.get(url, mergeOptions(config))
        .then(apiSuccessResponseParsing)
        .catch(apiErrorResponseParsing);
}
