import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('campaignsReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    campaigns: [],
    totalCount: 0,
    active: null,
};

// ACTION TYPES
export const types = {
    CAMPAIGNS_CLEAR_DATA: 'CAMPAIGNS/CLEAR/DATA',

    CAMPAIGNS_DATA_REQUEST: 'CAMPAIGNS/REQUEST',
    CAMPAIGNS_DATA_REQUEST_SUCCESS: 'CAMPAIGNS/REQUEST/SUCCESS',
    CAMPAIGNS_DATA_REQUEST_ERROR: 'CAMPAIGNS/REQUEST/ERROR',

    CAMPAIGNS_DATA_REFRESH_REQUEST: 'CAMPAIGNS_REFRESH/REQUEST',
    CAMPAIGNS_DATA_REFRESH_REQUEST_SUCCESS: 'CAMPAIGNS_REFRESH/REQUEST/SUCCESS',
    CAMPAIGNS_DATA_REFRESH_REQUEST_ERROR: 'CAMPAIGNS_REFRESH/REQUEST/ERROR',
    
    UPDATING_CAMPAIGN_ACTIVE: 'CAMPAIGN_ACTIVE_UPDATE/REQUEST',
    UPDATING_CAMPAIGN_ACTIVE_SUCCESS: 'CAMPAIGN_ACTIVE_UPDATE/REQUEST/SUCCESS',
    UPDATING_CAMPAIGN_ACTIVE_ERROR: 'CAMPAIGN_ACTIVE_UPDATE/REQUEST/ERROR',

    UPDATING_CAMPAIGN_SYNC: 'CAMPAIGN_SYNC_UPDATE/REQUEST',
    UPDATING_CAMPAIGN_SYNC_SUCCESS: 'CAMPAIGN_SYNC_UPDATE/REQUEST/SUCCESS',
    UPDATING_CAMPAIGN_SYNC_ERROR: 'CAMPAIGN_SYNC_UPDATE/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    clearData: () => ({
        type: types.CAMPAIGNS_CLEAR_DATA,
    }),

    requestCampaigns: (active, offset, limit, search) => ({
        type: types.CAMPAIGNS_DATA_REQUEST,
        active: active,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestCampaignsSuccess: (payload) => ({
        type: types.CAMPAIGNS_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestCampaignsError: (errors) => ({
        type: types.CAMPAIGNS_DATA_REQUEST_ERROR,
        errors,
    }),

    requestRefreshCampaigns: (active, offset, limit, search) => ({
        type: types.CAMPAIGNS_DATA_REFRESH_REQUEST,
        active: active,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestRefreshCampaignsSuccess: (payload) => ({
        type: types.CAMPAIGNS_DATA_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshCampaignsError: (errors) => ({
        type: types.CAMPAIGNS_DATA_REFRESH_REQUEST_ERROR,
        errors,
    }),

    updateCampaignActive: (payload) => ({
        type: types.UPDATING_CAMPAIGN_ACTIVE,
        payload,
    }),
    updateCampaignActiveSuccess: (payload) => ({
        type: types.UPDATING_CAMPAIGN_ACTIVE_SUCCESS,
        payload,
    }),
    updateCampaignActiveError: (errors) => ({
        type: types.UPDATING_CAMPAIGN_ACTIVE_ERROR,
        errors,
    }),

    updateCampaignSync: (payload) => ({
        type: types.UPDATING_CAMPAIGN_SYNC,
        payload,
    }),
    updateCampaignSyncSuccess: (payload) => ({
        type: types.UPDATING_CAMPAIGN_SYNC_SUCCESS,
        payload,
    }),
    updateCampaignSyncError: (errors) => ({
        type: types.UPDATING_CAMPAIGN_SYNC_ERROR,
        errors,
    }),
};

// REDUCERS
export default function campaignsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CAMPAIGNS_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.CAMPAIGNS_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGNS_DATA_REQUEST_SUCCESS: {
            log('campaigns', action.payload);
            let campaigns = Array.from(state.campaigns);
            campaigns = campaigns.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                campaigns: campaigns,
                totalCount: action.payload.count,
            }
        }

        case types.CAMPAIGNS_DATA_REQUEST_ERROR:
        case types.CAMPAIGNS_DATA_REFRESH_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.CAMPAIGNS_DATA_REFRESH_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGNS_DATA_REFRESH_REQUEST_SUCCESS: {
            log('campaigns are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                campaigns: action.payload.results,
                totalCount: action.payload.count,
            }
        }

        case types.UPDATING_CAMPAIGN_ACTIVE:
        case types.UPDATING_CAMPAIGN_SYNC: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
                payload: action.payload,
            }
        }

        case types.UPDATING_CAMPAIGN_ACTIVE_SUCCESS:
        case types.UPDATING_CAMPAIGN_SYNC_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.UPDATING_CAMPAIGN_ACTIVE_ERROR:
        case types.UPDATING_CAMPAIGN_SYNC_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.campaigns.loading;
export const getCampaigns = (state) => state.campaigns.campaigns;
export const getErrors = (state) => state.campaigns.errors;
export const getSuccess = (state) => state.campaigns.success;
export const getTotal = (state) => state.campaigns.totalCount;
