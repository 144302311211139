import debug from "debug";
import {takeEvery} from 'redux-saga/effects';

import {types, actions} from "../reducers/passwordReset";
import {endpoints} from "../constants/api";
import {post} from "../utils/requester";
import {makeRequest} from "./utils";

const log = debug("passwordResetSaga");


function submitSetNewPassword(actionPayload) {
    log('setting new password', actionPayload);
    return post(endpoints.auth.newPassword, actionPayload);
}

function* handleSubmitSetNewPassword(action) {
    log('got a request to set new password', action);
    yield makeRequest(actions.setNewPasswordSuccess, actions.setNewPasswordError, submitSetNewPassword, action.payload);
}

// WATCHERS
export function* watchRequestSubmitSetNewPassword() {
    yield takeEvery(types.SUBMIT_NEW_PASSWORD_REQUEST, handleSubmitSetNewPassword);
}
