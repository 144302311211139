// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ServerResponse } from "../../../types/common";
import { get, patch } from "../../../utils/requester";
import type { VerdictParams } from "./types";

const log = debug("CustomerDetailsAPI");

export function getCustomerData(customer: string): Promise<ServerResponse> {
    log("requesting customer data", customer);
    return get(endpoints.customers.details.replace(':customer', customer));
}


export function updateVerdict(params: VerdictParams): Promise<ServerResponse> {
    log("requesting to update insight verdict", params);
    let postParams = {};
    if (params.verdict != null) {
        postParams.verdict = params.verdict;
    }
    return patch(endpoints.insights.updateVerdict.replace(':insight', params.insight), postParams);
}
