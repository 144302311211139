// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ServerResponse } from "../../../types/common";
import { get } from "../../../utils/requester";
import type { SavedFiltersParams } from "./types";

const log = debug("SavedFiltersAPI");

export function fetchSavedFilters(params: ?SavedFiltersParams): Promise<ServerResponse> {
    log("requesting saved filters", params);
    return get(endpoints.reports.savedFilters, params);
}

export function fetchSavedFiltersRefreshed(params: ?SavedFiltersParams): Promise<ServerResponse> {
    log("requesting saved filters, refreshed", params);
    return get(endpoints.reports.savedFilters, params);
}
