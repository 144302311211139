import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('massCampaignReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    templates: [],
    reportContacts: [],
    massCampaign: {},
};

// ACTION TYPES
export const types = {
    CAMPAIGN_TEMPLATES_REQUEST: 'CAMPAIGN_TEMPLATES/REQUEST',
    CAMPAIGN_TEMPLATES_REQUEST_SUCCESS: 'CAMPAIGN_TEMPLATES/REQUEST/SUCCESS',
    CAMPAIGN_TEMPLATES_REQUEST_ERROR: 'CAMPAIGN_TEMPLATES/REQUEST/ERROR',

    VALIDATING_REPORT: 'VALIDATING_REPORT/SUBMIT',
    VALIDATING_REPORT_SUCCESS: 'VALIDATING_REPORT/SUBMIT/SUCCESS',
    VALIDATING_REPORT_ERROR: 'VALIDATING_REPORT/SUBMIT/ERROR',

    SUBMITTING_MASS_CAMPAIGN: 'MASS_CAMPAIGN/SUBMIT',
    SUBMITTING_MASS_CAMPAIGN_SUCCESS: 'MASS_CAMPAIGN/SUBMIT/SUCCESS',
    SUBMITTING_MASS_CAMPAIGN_ERROR: 'MASS_CAMPAIGN/SUBMIT/ERROR',
};

// ACTIONS
export const actions = {
    requestCampaignTemplates: (account) => ({
        type: types.CAMPAIGN_TEMPLATES_REQUEST,
        account,
    }),
    requestCampaignTemplatesSuccess: (payload) => ({
        type: types.CAMPAIGN_TEMPLATES_REQUEST_SUCCESS,
        payload,
    }),
    requestCampaignTemplatesError: (errors) => ({
        type: types.CAMPAIGN_TEMPLATES_REQUEST_ERROR,
        errors,
    }),

    validateReport: (payload) => ({
        type: types.VALIDATING_REPORT,
        payload,
    }),
    validateReportSuccess: (payload) => ({
        type: types.VALIDATING_REPORT_SUCCESS,
        payload,
    }),
    validateReportError: (errors) => ({
        type: types.VALIDATING_REPORT_ERROR,
        errors,
    }),

    submitMassCampaign: (payload) => ({
        type: types.SUBMITTING_MASS_CAMPAIGN,
        payload,
    }),
    submitMassCampaignSuccess: (payload) => ({
        type: types.SUBMITTING_MASS_CAMPAIGN_SUCCESS,
        payload,
    }),
    submitMassCampaignError: (errors) => ({
        type: types.SUBMITTING_MASS_CAMPAIGN_ERROR,
        errors,
    }),
};

// REDUCERS
export default function massCampaignReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CAMPAIGN_TEMPLATES_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGN_TEMPLATES_REQUEST_SUCCESS: {
            action.payload.forEach((val) => {
                val.value = val.slug;
                val.label = val.name;
            });
            return {
                ...state,
                loading: false,
                errors: null,
                templates: action.payload,
            }
        }

        case types.CAMPAIGN_TEMPLATES_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.VALIDATING_REPORT:
        case types.SUBMITTING_MASS_CAMPAIGN: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case types.VALIDATING_REPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                reportContacts: action.payload,
            }
        }

        case types.SUBMITTING_MASS_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.VALIDATING_REPORT_ERROR:
        case types.SUBMITTING_MASS_CAMPAIGN_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.massCampaign.loading;
export const getErrors = (state) => state.massCampaign.errors;
export const getSuccess = (state) => state.massCampaign.success;
export const getCampaignTemplates = (state) => state.massCampaign.templates;
export const getReportContacts = (state) => state.massCampaign.reportContacts;
export const getMassCampaign = (state) => state.massCampaign.massCampaign;
