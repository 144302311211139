// @flow
import isEmpty from "lodash-es/isEmpty";
import type {InteractionDetails, Participant} from "../pages/Interactions/InteractionDetails/types";
import type {Interaction} from "../pages/Interactions/InteractionsTimeline/types";
import {toArray} from "./utils";
import type {ObjectSlugString} from "../types/common";


export function parseInteractionServerResponse(interaction: Object): Interaction {
    let sentiment;
    if (!isEmpty(interaction.sentiment)) {
        sentiment = {
            slug: interaction.sentiment.slug,
            name: interaction.sentiment.name,
        };
    }
    let sender;
    if (!isEmpty(interaction.sender)) {
        sender = {
            email: interaction.sender.email,
            firstName: interaction.sender.first_name,
            lastName: interaction.sender.last_name,
        };
    }
    return {
        slug: interaction.slug,
        sentiment: sentiment,
        content: interaction.content,
        messageSlug: interaction.message_slug,
        messageType: interaction.message_type,
        originType: interaction.origin_type,
        sender: sender,
        source: interaction.source,
        timestamp: interaction.timestamp,
        thread: {
            slug: interaction.thread.slug,
            name: interaction.thread.name,
        },
        customer: {
            slug: interaction.customer.slug,
            name: interaction.customer.name,
        }
    }
}

type Sentiment = {slug: ObjectSlugString, name: string};

function parseSentimentServerResponse(sentimentServer: ?Sentiment): ?Sentiment {
    let sentiment = null;
    if (sentimentServer != null) {
        sentiment = {
            slug: sentimentServer.slug,
            name: sentimentServer.name,
        };
    }
    return sentiment;
}

export function parseParticipantServerResponse(participantServer: Object): Participant {
    let relation = null;
    if (participantServer.relation != null) {
        relation = {
            slug: participantServer.relation.slug,
            firstName: participantServer.relation.first_name,
            lastName: participantServer.relation.last_name,
            role: participantServer.relation.role,
            isCsLead: participantServer.relation.is_cs_lead,
            userTitle: participantServer.relation.user_title,
        };
    }

    return {
        slug: participantServer.slug,
        email: participantServer.email,
        participantType: participantServer.participant_type,
        relation: relation,
    }
}

export function parseParticipantsServerResponse(participantsServer: Array<Object>): Array<Participant> {
    return toArray(participantsServer, parseParticipantServerResponse);
}


export function parseInteractionDetailsServerResponse(interactionServer: Object): InteractionDetails {
    let currentSentiment = null;
    if (interactionServer.current_sentiment != null) {
        currentSentiment = {
            slug: interactionServer.current_sentiment.slug,
            sentiment: parseSentimentServerResponse(interactionServer.current_sentiment.sentiment),
            style: interactionServer.current_sentiment.style,
            importance: interactionServer.current_sentiment.importance,
            urgency: interactionServer.current_sentiment.urgency,
        };
    }
    return {
        slug: interactionServer.slug,
        account: interactionServer.account,
        customer: interactionServer.customer,
        timestamp: interactionServer.timestamp,
        content: interactionServer.content,
        contentObject: {
            thread: {
                slug: interactionServer.content_object.thread.slug,
                subject: interactionServer.content_object.thread.subject,
                firstInteractionAt: interactionServer.content_object.thread.first_interaction_at,
                lastInteractionAt: interactionServer.content_object.thread.last_interaction_at,
                countMessages: interactionServer.content_object.thread.count_messages,
            },
            sender: parseParticipantServerResponse(interactionServer.content_object.sender),
            receivers: parseParticipantsServerResponse(interactionServer.content_object.receivers),
            kind: interactionServer.content_object.kind,
            idxInThread: interactionServer.content_object.idx_in_thread,
            size: interactionServer.content_object.size,
            sizeMeasureUnit: interactionServer.content_object.size_measure_unit,
            slug: interactionServer.content_object.slug,
        },
        currentSentiment: currentSentiment,
        interactionType: interactionServer.interaction_type,
        originType: interactionServer.origin_type,
        source: interactionServer.source,
    }
}

export function parseInteractionsServerResponse(interactions: Array<Object>): Array<InteractionDetails> {
    return toArray(interactions, parseInteractionServerResponse);
}
