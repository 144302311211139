import React, {Component} from "react";
import DocumentTitle from 'react-document-title';
import debug from 'debug';

import "./Login.css";
import LoginForm from "../../containers/LoginForm";

const log = debug("Login");


class Login extends Component {
    render() {
        log("login props", this.props);
        return (
            <div className="login-form container">
                <DocumentTitle title="Questomer - Login"/>
                <LoginForm {...this.props} />
            </div>
        );
    }
}

export default Login;
