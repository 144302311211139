import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { fetchSentiments, getAccountManagerData } from "./api";
import { actions } from "./reducer";

const log = debug("SearchParamsSaga");

function* fetchAccountCsms(action) {
    log("got request to fetch csms", action);
    yield makeRequest(actions.requestCsmsSuccess, actions.requestCsmsError, getAccountManagerData);
}

function* fetchSentimentsData(action) {
    log("got request to fetch sentiments", action);
    yield makeRequest(actions.requestSentimentsSuccess, actions.requestSentimentsError, fetchSentiments);
}

const sagas = [
    takeLatest(actions.requestSentiments.type, fetchSentimentsData),
    takeLatest(actions.requestCsms.type, fetchAccountCsms),
];
export default sagas;
