import {createAction} from "@reduxjs/toolkit";
import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('newsItemsReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    deadline: null,
    description: '',
    email: '',
    errors: null,
    link: '',
    loading: false,
    newsItems: [],
    segments: [],
    status: {},
    success: null,
    tags: [],
    title: '',
    totalCount: 0,
};

// ACTION TYPES
export const types = {
    NEWS_ITEMS_CLEAR_DATA: 'NEWS_ITEMS/CLEAR/DATA',

    NEWS_ITEMS_DATA_REQUEST: 'NEWS_ITEMS/REQUEST',
    NEWS_ITEMS_DATA_REQUEST_SUCCESS: 'NEWS_ITEMS/REQUEST/SUCCESS',
    NEWS_ITEMS_DATA_REQUEST_ERROR: 'NEWS_ITEMS/REQUEST/ERROR',

    NEWS_ITEMS_DATA_REFRESH_REQUEST: 'NEWS_ITEMS_REFRESH/REQUEST',
    NEWS_ITEMS_DATA_REFRESH_REQUEST_SUCCESS: 'NEWS_ITEMS_REFRESH/REQUEST/SUCCESS',
    NEWS_ITEMS_DATA_REFRESH_REQUEST_ERROR: 'NEWS_ITEMS_REFRESH/REQUEST/ERROR',

    UPDATING_NEWS_ITEM: 'NEWS_ITEM_UPDATE/REQUEST',
    UPDATING_NEWS_ITEM_SUCCESS: 'NEWS_ITEM_UPDATE/REQUEST/SUCCESS',
    UPDATING_NEWS_ITEM_ERROR: 'NEWS_ITEM_UPDATE/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    clearData: () => ({
        type: types.NEWS_ITEMS_CLEAR_DATA,
    }),

    requestNewsItems: (customer, status, offset, limit, search) => ({
        type: types.NEWS_ITEMS_DATA_REQUEST,
        customer: customer,
        status: status,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestNewsItemsSuccess: (payload) => ({
        type: types.NEWS_ITEMS_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestNewsItemsError: (errors) => ({
        type: types.NEWS_ITEMS_DATA_REQUEST_ERROR,
        errors,
    }),

    requestRefreshNewsItems: (customer, status, offset, limit, search) => ({
        type: types.NEWS_ITEMS_DATA_REFRESH_REQUEST,
        customer: customer,
        status: status,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestRefreshNewsItemsSuccess: (payload) => ({
        type: types.NEWS_ITEMS_DATA_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshNewsItemsError: (errors) => ({
        type: types.NEWS_ITEMS_DATA_REFRESH_REQUEST_ERROR,
        errors,
    }),

    updateNewsItem: (payload, newsItem) => ({
        type: types.UPDATING_NEWS_ITEM,
        payload,
        newsItem,
    }),
    updateNewsItemSuccess: (payload) => ({
        type: types.UPDATING_NEWS_ITEM_SUCCESS,
        payload,
    }),
    updateNewsItemError: (errors) => ({
        type: types.UPDATING_NEWS_ITEM_ERROR,
        errors,
    }),

    requestTagsData: createAction('NEWS_ITEM_TAGS/REQUEST'),
    requestTagsDataSuccess: createAction('NEWS_ITEM_TAGS/REQUEST/SUCCESS'),
    requestTagsDataError: createAction('NEWS_ITEM_TAGS/REQUEST/ERROR'),

};

// REDUCERS
export default function newsItemsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.NEWS_ITEMS_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case actions.requestTagsData.type:
        case types.NEWS_ITEMS_DATA_REFRESH_REQUEST:
        case types.NEWS_ITEMS_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.UPDATING_NEWS_ITEM: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case types.NEWS_ITEMS_DATA_REQUEST_SUCCESS: {
            log('news items are', action.payload);
            let newsItems = Array.from(state.newsItems);
            newsItems = newsItems.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                newsItems: newsItems,
                totalCount: action.payload.count,
            }
        }

        case types.NEWS_ITEMS_DATA_REFRESH_REQUEST_SUCCESS: {
            log('news items are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                newsItems: action.payload.results,
                totalCount: action.payload.count,
            }
        }

        case types.UPDATING_NEWS_ITEM_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case actions.requestTagsDataSuccess.type: {
            action.payload.forEach((val, _idx) => {
                val.label = val.name;
                val.value = val.slug;
            });
            return {
                ...state,
                loading: false,
                errors: null,
                tags: action.payload,
            }
        }

        case types.NEWS_ITEMS_DATA_REQUEST_ERROR:
        case types.NEWS_ITEMS_DATA_REFRESH_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.UPDATING_NEWS_ITEM_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        case actions.requestTagsDataError.type: {
            log("error is", action);
            const errorData = getErrorData(action.payload);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.newsItems.loading;
export const getNewsItems = (state) => state.newsItems.newsItems;
export const getErrors = (state) => state.newsItems.errors;
export const getSuccess = (state) => state.newsItems.success;
export const getTotal = (state) => state.newsItems.totalCount;
export const getTags = (state) => state.newsItems.tags;
