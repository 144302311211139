import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('contactMessagesReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    contactMessages: [],
    totalCount: 0,
};

// ACTION TYPES
export const types = {
    CONTACT_MESSAGES_CLEAR_DATA: 'CONTACT_MESSAGES/CLEAR/DATA',

    CONTACT_MESSAGES_DATA_REQUEST: 'CONTACT_MESSAGES/REQUEST',
    CONTACT_MESSAGES_DATA_REQUEST_SUCCESS: 'CONTACT_MESSAGES/REQUEST/SUCCESS',
    CONTACT_MESSAGES_DATA_REQUEST_ERROR: 'CONTACT_MESSAGES/REQUEST/ERROR',

    CONTACT_MESSAGES_DATA_REFRESH_REQUEST: 'CONTACT_MESSAGES_REFRESH/REQUEST',
    CONTACT_MESSAGES_DATA_REFRESH_REQUEST_SUCCESS: 'CONTACT_MESSAGES_REFRESH/REQUEST/SUCCESS',
    CONTACT_MESSAGES_DATA_REFRESH_REQUEST_ERROR: 'CONTACT_MESSAGES_REFRESH/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    clearData: () => ({
        type: types.CONTACT_MESSAGES_CLEAR_DATA,
    }),

    requestContactMessages: (offset, limit, search) => ({
        type: types.CONTACT_MESSAGES_DATA_REQUEST,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestContactMessagesSuccess: (payload) => ({
        type: types.CONTACT_MESSAGES_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestContactMessagesError: (errors) => ({
        type: types.CONTACT_MESSAGES_DATA_REQUEST_ERROR,
        errors,
    }),

    requestRefreshContactMessages: (offset, limit, search) => ({
        type: types.CONTACT_MESSAGES_DATA_REFRESH_REQUEST,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestRefreshContactMessagesSuccess: (payload) => ({
        type: types.CONTACT_MESSAGES_DATA_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshContactMessagesError: (errors) => ({
        type: types.CONTACT_MESSAGES_DATA_REFRESH_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function contactMessagesReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CONTACT_MESSAGES_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.CONTACT_MESSAGES_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CONTACT_MESSAGES_DATA_REQUEST_SUCCESS: {
            log('contact messages are', action.payload);
            let contactMessages = Array.from(state.contactMessages);
            contactMessages = contactMessages.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                contactMessages: contactMessages,
                totalCount: action.payload.count,
            }
        }

        case types.CONTACT_MESSAGES_DATA_REQUEST_ERROR:
        case types.CONTACT_MESSAGES_DATA_REFRESH_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.CONTACT_MESSAGES_DATA_REFRESH_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CONTACT_MESSAGES_DATA_REFRESH_REQUEST_SUCCESS: {
            log('contact messages are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                contactMessages: action.payload.results,
                totalCount: action.payload.count,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.contactMessages.loading;
export const getContactMessages = (state) => state.contactMessages.contactMessages;
export const getErrors = (state) => state.contactMessages.errors;
export const getTotal = (state) => state.contactMessages.totalCount;
