import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { getCustomerInsights } from "./api";
import { actions } from "./reducer";

const log = debug("CustomerInsightsSaga");

function* fetchCustomerInsights(action) {
    log("got request to fetch customer insights", action);
    yield makeRequest(
        actions.requestCustomerInsightsSuccess,
        actions.requestCustomerInsightsError,
        getCustomerInsights,
        action.payload
    );
}

const sagas = [
    takeLatest(actions.requestCustomerInsights.type, fetchCustomerInsights),
];
export default sagas;
