import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { reducer as formReducer } from "redux-form";

import rootSaga from "../sagas";
import { getHistory } from "../utils/history";
import login from "./auth";
import passwordReset from "./passwordReset";
import newsItemForm from "./newsItemForm";
import newsItemFormCustomers from "./newsItemFormCustomers";
import profile from "./profile";
import accountData from "./accountData";
import newsItems from "./newsItems";
import customers from "./customers";
import customerContacts from "../pages/Customers/CustomerContacts/reducer";
import customerContactForm from "./customerContactForm";
import contactAddNewsItems from "./contactAddNewsItems";
import contactMessageEdit from "./contactMessageEdit";
import contactMessageView from "./contactMessageView";
import contactMessages from "./contactMessages";
import emailFeedback from "./emailFeedback";
import feedbacks from "./feedbacks";
import campaigns from "./campaigns";
import campaignForm from "./campaignForm";
import campaignExecute from "./campaignExecute";
import campaignExecuteContacts from "./campaignExecuteContacts";
import campaignReport from "./campaignReport";
import massCampaign from "./massCampaign";
import campaignAddRecipients from "./campaignAddRecipients";
import searchParams from "../pages/Interactions/SearchParams/reducer";
import interactionsSearch from "../pages/Interactions/InteractionsSearch/reducer";
import interactionsTimeline from "../pages/Interactions/InteractionsTimeline/reducer";
import interactionDetails from "../pages/Interactions/InteractionDetails/reducer";
import customerAnalytics from "../pages/Reports/CustomerAnalytics/reducer";
import savedFilters from "../pages/Reports/SavedFilters/reducer";
import customersList from "../pages/Customers/CustomersList/reducer";
import customerDetails from "../pages/Customers/CustomerDetails/reducer";
import customerInsights from "../pages/Customers/CustomerInsights/reducer";

const createRootReducer = (history) =>
    combineReducers({
        accountData,
        campaignAddRecipients,
        campaignExecute,
        campaignExecuteContacts,
        campaignForm,
        campaignReport,
        campaigns,
        contactAddNewsItems,
        contactMessageEdit,
        contactMessages,
        contactMessageView,
        customerContactForm,
        customerContacts,
        customers,
        emailFeedback,
        feedbacks,
        form: formReducer,
        login,
        massCampaign,
        newsItemForm,
        newsItemFormCustomers,
        newsItems,
        passwordReset,
        profile,
        router: connectRouter(history),
        interactionsSearch,
        searchParams,
        interactionsTimeline,
        interactionDetails,
        customerAnalytics,
        savedFilters,
        customersList,
        customerDetails,
        customerInsights,
    });

const rootReducer = createRootReducer(getHistory());

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["profile", "interactionsTimeline"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    persistedReducer,
    compose(
        applyMiddleware(routerMiddleware(getHistory()), sagaMiddleware),
        process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
            : (f) => f
    )
);

const persistor = persistStore(store);

// run the saga
sagaMiddleware.run(rootSaga);

export { store, persistor };
