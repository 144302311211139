import React, { Component } from 'react';
import { Provider } from 'react-redux';
import debug from 'debug';
import { PersistGate } from 'redux-persist/es/integration/react';
import Routes from '../../containers/Router';
import './App.css';
import { store, persistor } from '../../reducers';


if (process.env.NODE_ENV === 'development') {
    // ENABLE DEBUG ON DEVELOPMENT ONLY
    debug.enable('*');
}


class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <Routes/>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
