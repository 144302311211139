import debug from "debug";
import { createAction } from "@reduxjs/toolkit";

import { getErrorData } from "../../../utils/errorData";
import { parseReportFiltersServerResponse } from "../../../parsers/reports";

const log = debug("savedFiltersReducer");

const DEFAULT_STATE = {
    loading: false,
    success: false,
    errors: null,
    savedFilters: [],
    totalCount: 0,
};

export const actions = {
    clearState: createAction("CUSTOMER_ANALYTICS/SAVED_FILTERS/CLEAR_STATE"),

    savedFiltersRequest: createAction("CUSTOMER_ANALYTICS/SAVED_FILTERS/REQUEST"),
    savedFiltersSuccess: createAction("CUSTOMER_ANALYTICS/SAVED_FILTERS/SUCCESS"),
    savedFiltersError: createAction("CUSTOMER_ANALYTICS/SAVED_FILTERS/ERROR"),

    savedFiltersRefreshedRequest: createAction("CUSTOMER_ANALYTICS/SAVED_FILTERS_REFRESHED/REQUEST"),
    savedFiltersRefreshedSuccess: createAction("CUSTOMER_ANALYTICS/SAVED_FILTERS_REFRESHED/SUCCESS"),
    savedFiltersRefreshedError: createAction("CUSTOMER_ANALYTICS/SAVED_FILTERS_REFRESHED/ERROR"),
};

export default function savedFiltersReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE;
        }

        case actions.savedFiltersRequest.type:
        case actions.savedFiltersRefreshedRequest.type: {
            return {
                ...state,
                loading: true,
                errors: null,
            };
        }

        case actions.savedFiltersSuccess.type: {
            log("saved filters are", action.payload);
            let savedFilters = Array.from(state.savedFilters);
            savedFilters = savedFilters.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                savedFilters: parseReportFiltersServerResponse(savedFilters),
                totalCount: action.payload.count,
            };
        }

        case actions.savedFiltersRefreshedSuccess.type: {
            log("saved filters are", action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                savedFilters: parseReportFiltersServerResponse(action.payload.results),
                totalCount: action.payload.count,
            };
        }

        case actions.savedFiltersError.type:
        case actions.savedFiltersRefreshedError.type: {
            log("error is", action);
            const errorData = getErrorData(action.payload);
            log("got error data", errorData);
            return {
                ...state,
                loading: false,
                errors: errorData,
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => state.savedFilters.loading;
export const getErrors = (state) => state.savedFilters.errors;
export const getSuccess = (state) => state.savedFilters.success;
export const getSavedFilters = (state) => state.savedFilters.savedFilters;
export const getTotal = (state) => state.savedFilters.totalCount;
