import debug from "debug";
import { createAction } from "@reduxjs/toolkit";
import some from "lodash-es/some";

import { getErrorData } from "../../../utils/errorData";

const log = debug("interactionsSearchReducer");

const DEFAULT_STATE = {
    errors: null,
    loadingSavedSearches: false,
    savedSearches: [],
    totalCount: 0,
    success: null,
};

export const actions = {
    clearState: createAction("INTERACTIONS_SEARCH/CLEAR_DATA"),

    requestSavedSearches: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES/REQUEST"),
    requestSavedSearchesSuccess: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES/REQUEST/SUCCESS"),
    requestSavedSearchesError: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES/REQUEST/ERROR"),

    requestDeleteSavedSearch: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES/DELETE/REQUEST"),
    requestDeleteSavedSearchSuccess: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES/DELETE/REQUEST/SUCCESS"),
    requestDeleteSavedSearchError: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES/DELETE/REQUEST/ERROR"),

    requestSavedSearchesRefreshed: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES_REFRESHED/REQUEST"),
    requestSavedSearchesRefreshedSuccess: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES_REFRESHED/REQUEST/SUCCESS"),
    requestSavedSearchesRefreshedError: createAction("INTERACTIONS_SEARCH/SAVED_SEARCHES_REFRESHED/REQUEST/ERROR"),
};

export default function interactionsSearchReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE;
        }

        case actions.requestSavedSearches.type:
        case actions.requestSavedSearchesRefreshed.type: {
            return {
                ...state,
                errors: null,
                loadingSavedSearches: true,
            };
        }

        case actions.requestDeleteSavedSearch.type: {
            return {
                ...state,
                errors: null,
                loadingSavedSearches: true,
                success: null,
            };
        }

        case actions.requestDeleteSavedSearchSuccess.type: {
            return {
                ...state,
                errors: null,
                loadingSavedSearches: false,
                success: true,
            };
        }

        case actions.requestSavedSearchesSuccess.type: {
            log("saved searches are", action.payload);
            let savedSearches = Array.from(state.savedSearches);
            savedSearches = savedSearches.concat(action.payload.results);
            return {
                ...state,
                errors: null,
                loadingSavedSearches: false,
                savedSearches: savedSearches,
                totalCount: action.payload.count,
            };
        }

        case actions.requestSavedSearchesRefreshedSuccess.type: {
            log("saved searches are", action.payload);
            return {
                ...state,
                errors: null,
                loadingSavedSearches: false,
                savedSearches: action.payload.results,
                totalCount: action.payload.count,
            };
        }

        case actions.requestSavedSearchesError.type:
        case actions.requestSavedSearchesRefreshedError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loadingSavedSearches: false,
                errors: getErrorData(action.payload),
            };
        }

        case actions.requestDeleteSavedSearchError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loadingSavedSearches: false,
                errors: getErrorData(action.payload),
                success: false,
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => some([state.interactionsSearch.loadingSavedSearches], (val) => val === true);
export const getErrors = (state) => state.interactionsSearch.errors;
export const getSuccess = (state) => state.interactionsSearch.success;
export const getSavedSearches = (state) => state.interactionsSearch.savedSearches;
export const getTotal = (state) => state.interactionsSearch.totalCount;
