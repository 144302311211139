import React from "react";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import AsyncCreatableSelect from "react-select/async-creatable/dist/react-select.esm";
import {ReactTinyLink} from "react-tiny-link";
import PropTypes from "prop-types";
import debug from "debug";

import ErrorsMessage from "../ErrorMessage/ErrorMessage";
import "./NewsItemForm.css";
import {fetchTags} from "./api";

const log = debug("NewsItemFormCustomers");

const DEFAULT_STATE = {
    title: "",
    description: "",
    link: "",
    tags: [],
    segments: [],
    customers: [],
    contactTypes: [],
    deadline: "",
    alertVisible: false,
    segmentsCustomers: "segments",
};

const accountSegments = (segments) => {
    return segments.map(obj => {
        obj.checked = false;
        return obj;
    });
};


class NewsItemFormCustomers extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;

        this.newsItemSubmit = this.newsItemSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onFormClear = this.onFormClear.bind(this);
        this.handleChangeSegmentsCustomers = this.handleChangeSegmentsCustomers.bind(this);
        this.promiseTagsSearch = this.promiseTagsSearch.bind(this);
    }

    static propTypes = {
        account: PropTypes.string.isRequired,
        clearForm: PropTypes.func.isRequired,
        contactTypeData: PropTypes.array,
        customer: PropTypes.string,
        errors: PropTypes.array,
        fetchContactTypeData: PropTypes.func.isRequired,
        fetchCustomerContent: PropTypes.func.isRequired,
        fetchNewsItems: PropTypes.func.isRequired,
        fetchRefreshNewsItems: PropTypes.func.isRequired,
        fetchSegmentData: PropTypes.func.isRequired,
        fetchTagsData: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        newsItems: PropTypes.array,
        onFormSubmit: PropTypes.func.isRequired,
        pagePath: PropTypes.string.isRequired,
        segmentData: PropTypes.array,
        success: PropTypes.bool,
        tagsData: PropTypes.array,
        user: PropTypes.object,
    };

    static defaultProps = {
        account: "",
        clearForm: () => {},
        contactTypeData: [],
        customer: "",
        errors: null,
        fetchContactTypeData: () => {},
        fetchCustomerContent: () => {},
        fetchNewsItems: () => {},
        fetchRefreshNewsItems: () => {},
        fetchSegmentData: () => {},
        fetchTagsData: () => {},
        newsItems: [],
        onFormSubmit: () => {},
        pagePath: "",
        segmentData: [],
        success: null,
        user: {},
    };

    componentDidMount() {
        log("customer news item form props are", this.props);
        this._isMounted = true;
        if (this.props.segmentData.length === 0)
            this.props.fetchSegmentData(this.props.account);
        if (this.props.contactTypeData.length === 0)
            this.props.fetchContactTypeData(this.props.account);

        this.props.fetchTagsData(this.props.account);

        this.setState({
            ...DEFAULT_STATE,
            segments: accountSegments(this.props.segmentData),
        });
    }

    componentDidUpdate(prevProps) {
        const {success} = this.props;

        if (prevProps.success !== success && success) {
            window.scrollTo(0, 0);

            // this.props.clearForm();
            this.setState({...DEFAULT_STATE});

            const pathName = `/${this.props.pagePath.split("/")[1]}`;
            if (this.props.user && pathName === "/newsitems") {
                this.props.fetchRefreshNewsItems(null, null, 0);
            } else if (this.props.customer && pathName === "/customers") {
                this.props.fetchCustomerContent(this.props.customer, this.props.account);
            }

            if (this.props.user && document.getElementsByClassName("modal-dialog").length !== 0) {
                document.getElementsByClassName("modal-dialog")[0].scrollIntoView();
            }

            this.setState({alertVisible: true}, () => {
                window.setTimeout(() => {
                    if (this._isMounted) {
                        this.setState({alertVisible: false});
                    }
                }, 5000)
            });
        }

        if (prevProps.segmentData.length === 0 && this.props.segmentData.length !== 0) {
            this.setState({
                segments: accountSegments(this.props.segmentData),
            });
        }
    }

    componentWillUnmount() {
        log("component will unmount, clearing news item customers form");
        this.props.clearForm();

        this.setState({
            ...DEFAULT_STATE,
            segments: accountSegments(this.props.segmentData),
        });

        this._isMounted = false;
    }

    newsItemSubmit = (event) => {
        event.preventDefault();

        const selectedSegments = this.state.segments.filter(s => s.checked).map((s, i) => {
            if (s.checked) {
                return s.value;
            }
        });

        const selectedCustomers = this.state.customers.map((c, i) => {
            return c.value;
        });

        const selectedContactTypes = this.state.contactTypes.map((c, i) => {
            return c.value;
        });

        const selectedTags = this.state.tags.map((t, i) => {
            let tag = {name: t.label};
            if (t.value !== t.label) {
                tag.slug = t.value;
            }
            return tag
        });

        let payload = {
            title: this.state.title,
            description: this.state.description,
            link: this.state.link,
            segments: selectedSegments,
            customers: selectedCustomers,
            contactTypes: selectedContactTypes,
            deadline: this.state.deadline,
            account: this.props.account,
            tags: selectedTags,
        };
        log("values are", payload);

        this.props.onFormSubmit(payload);
    };

    onFormClear = () => {
        this.props.clearForm();

        this.setState({
            ...DEFAULT_STATE,
            segments: accountSegments(this.props.segmentData),
        });
    };

    handleInputChange(event) {
        if (event) {
            if (event.target) {
                if (event.target.type === "checkbox") {
                    let updatedSegments;
                    updatedSegments = this.state.segments.map((s, i) => {
                        if (event.target.id === s.value) {
                            s.checked = event.target.checked;
                        }
                        return s;
                    });
                    this.setState({
                        segments: updatedSegments,
                    });
                }
                else {
                    this.setState({
                        [event.target.id]: event.target.value,
                    });
                }
            }
        }
    }

    handleChangeSegmentsCustomers(val) {
        let state = {segmentsCustomers: val,};
        if (val === "segments") {
            state = {
                ...state,
                customers: [],
            }
        }
        else {
            const resetSegments = this.state.segments.map((s, i) => {
                if (s.checked) {
                    s.checked = false;
                }
                return s;
            });
            state = {
                ...state,
                segments: resetSegments,
            }
        }
        this.setState({...state});
    }

    /*:: promiseTagsSearch: () => void */
    promiseTagsSearch(inputValue: string, callback: (selectOptions: Array<Object>) => void) {
        if (this.state.searchTimeout) {
            window.clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            searchTimeout: setTimeout(() => {
                if (inputValue.length < 3) {
                    return;
                }
                fetchTags(this.props.account, inputValue)
                    .then((resp) => {
                        if (resp.response == null) {
                            return callback([]);
                        }
                        resp.response.forEach((tag, _idx) => {
                            tag.value = tag.slug;
                            tag.label = tag.name;
                        });
                        callback(resp.response);
                    }, (err) => {
                        log("error", err);
                        callback([]);
                    })
            }, 500)
        });
    }

    render() {
        const {isSubmitting, errors, success, contactTypeData, user} = this.props;

        const segments = this.state.segments.map((s, index) => {
            return (
                <section className="col-sm-6" key={index}>
                    <input type="checkbox" name="segments" id={s.value}
                           onChange={this.handleInputChange} checked={s.checked}/>
                    <label htmlFor={s.value}>{s.name}</label>
                </section>
            )
        });

        const managedCustomers = user.managed_customers.map((val, idx) => {
            return {
                ...val,
                value: val.slug,
                label: val.name,
            };
        });

        const isValidUrl = (string) => {
            let regexp = /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            return regexp.test(string);
        };

        return (
            <form className="form-component clearfix" onSubmit={this.newsItemSubmit}>
                {isSubmitting ? <div className="form-loading">
                    <i className="fas fa-circle-notch fa-spin"/>
                </div> : null}
                <div className="row">
                    <section className="news-item-form-fields col-md-12">
                        {success ? <Alert variant="success" show={this.state.alertVisible}>
                            Awesome job! News item form saved successfully!
                        </Alert> : null}
                        {errors ? <ErrorsMessage errors={errors}/> : null}
                        <input
                            id="title"
                            name="title"
                            type="text"
                            disabled={isSubmitting}
                            required
                            placeholder="Title"
                            value={this.state.title}
                            maxLength={150}
                            onChange={this.handleInputChange}
                        />
                        <small className="characters-count">{this.state.title.length}/150</small>
                        <textarea
                            id="description"
                            name="description"
                            disabled={isSubmitting}
                            placeholder="Description"
                            value={this.state.description}
                            maxLength={2000}
                            onChange={this.handleInputChange}
                        />
                        <input
                            id="link"
                            name="link"
                            type="url"
                            disabled={isSubmitting}
                            placeholder="Link"
                            value={this.state.link}
                            onChange={this.handleInputChange}
                        />
                        {isValidUrl(this.state.link) && <ReactTinyLink
                            cardSize="small"
                            showGraphic={true}
                            maxLine={2}
                            minLine={1}
                            url={this.state.link}
                        />}
                        {/*<hr/>*/}
                        <div className="toggle-segments-customers">
                            <ToggleButtonGroup type="radio" name="options" value={this.state.segmentsCustomers}
                                               onChange={this.handleChangeSegmentsCustomers}>
                                <ToggleButton value="segments" variant="inverse">Segments</ToggleButton>
                                <ToggleButton value="customers" variant="inverse">Customers</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        {this.state.segmentsCustomers === "segments" && <div className="row checkbox-group">
                            {segments}
                        </div>}
                        {this.state.segmentsCustomers === "customers" && <Select
                            id="customers"
                            name="customers"
                            value={this.state.customers}
                            onChange={(selectedObj) => {
                                this.setState({customers: selectedObj || []})
                            }}
                            options={managedCustomers}
                            placeholder="Choose customer"
                            className='react-select-container'
                            classNamePrefix='react-select'
                            isMulti
                            isDisabled={isSubmitting}
                        />}
                        <Select
                            id="contactTypes"
                            name="contactTypes"
                            value={this.state.contactTypes}
                            onChange={(selectedObj) => {
                                this.setState({contactTypes: selectedObj || []})
                            }}
                            options={contactTypeData}
                            placeholder="Choose contact type"
                            className='react-select-container'
                            classNamePrefix='react-select'
                            isMulti
                            isDisabled={isSubmitting}
                        />
                        <AsyncCreatableSelect
                            id="tags"
                            name="tags"
                            value={this.state.tags}
                            onChange={(selectedObj) => {
                                this.setState({tags: selectedObj || []})
                            }}
                            loadOptions={this.promiseTagsSearch}
                            defaultOptions={this.props.tagsData}
                            placeholder="Add tags"
                            className='react-select-container'
                            classNamePrefix='react-select'
                            isMulti
                            isDisabled={isSubmitting}
                        />
                        <input
                            id="deadline"
                            name="deadline"
                            type="date"
                            disabled={isSubmitting}
                            placeholder="Deadline"
                            value={this.state.deadline}
                            onChange={this.handleInputChange}
                        />
                        <div className="buttons-wrapper">
                            <button
                                type="button"
                                disabled={isSubmitting}
                                className="btn btn-inverse"
                                onClick={this.onFormClear}
                            >
                                Reset
                            </button>
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">Submit</button>
                        </div>
                    </section>
                </div>
            </form>
        );
    }
}

export default NewsItemFormCustomers;
