// @flow

import type {User} from "../types/users";
import {toArray, toSelectOption} from "./utils";

export function parseUserServerResponse(user: Object): User {
    return toSelectOption({
        slug: user.slug,
        name: user.name,
        isCsLead: user.is_cs_lead,
        userTitle: user.user_title,
        email: user.email,
    })
}

export function parseUsersServerResponse(users: Array<Object>): Array<User> {
    return toArray(users, parseUserServerResponse);
}
