import debug from "debug";
import { createAction } from "@reduxjs/toolkit";
import some from "lodash-es/some";
import { parseSentimentsServerResponse } from "../../../parsers/sentiments";

import { getErrorData } from "../../../utils/errorData";
import { parseUsersServerResponse } from "../../../parsers/users";

const log = debug("searchParamsReducer");

const DEFAULT_STATE = {
    csms: [],
    errors: null,
    loadingCsms: false,
    loadingSentiments: false,
    success: null,
    sentiments: [],
};

export const actions = {
    clearState: createAction("SEARCH_PARAMS/CLEAR_DATA"),

    requestCsms: createAction("SEARCH_PARAMS/REQUEST/CSMS"),
    requestCsmsError: createAction("SEARCH_PARAMS/REQUEST/CSMS/ERROR"),
    requestCsmsSuccess: createAction("SEARCH_PARAMS/REQUEST/CSMS/SUCCESS"),

    requestSentiments: createAction("SEARCH_PARAMS/REQUEST/SENTIMENTS"),
    requestSentimentsError: createAction("SEARCH_PARAMS/REQUEST/SENTIMENTS/ERROR"),
    requestSentimentsSuccess: createAction("SEARCH_PARAMS/REQUEST/SENTIMENTS/SUCCESS"),
};

export default function searchParamsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return {
                ...state,
                errors: null,
                loadingCsms: false,
                loadingSentiments: false,
                success: null,
            };
        }

        case actions.requestCsms.type: {
            return {
                ...state,
                errors: null,
                loadingCsms: true,
            };
        }

        case actions.requestSentiments.type: {
            return {
                ...state,
                errors: null,
                loadingSentiments: true,
            };
        }

        case actions.requestSentimentsSuccess.type: {
            return {
                ...state,
                errors: null,
                loadingSentiments: false,
                sentiments: parseSentimentsServerResponse(action.payload),
            };
        }

        case actions.requestCsmsSuccess.type: {
            const csms = parseUsersServerResponse(action.payload);
            return {
                ...state,
                loadingCsms: false,
                errors: null,
                csms: csms,
            };
        }

        case actions.requestSentimentsError.type:
        case actions.requestCsmsError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loadingCsms: false,
                loadingSentiments: false,
                errors: getErrorData(action.payload),
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) =>
    some([state.searchParams.loadingCsms, state.searchParams.loadingSentiments], (val) => val === true);
export const getErrors = (state) => state.searchParams.errors;
export const getSuccess = (state) => state.searchParams.success;
export const getCsms = (state) => state.searchParams.csms;
export const getSentiments = (state) => state.searchParams.sentiments;
