import {takeEvery} from 'redux-saga/effects';
import debug from "debug";
import isEmpty from "lodash-es/isEmpty";

import {get, post} from "../utils/requester";
import {endpoints} from "../constants/api";
import {actions} from "../reducers/campaignExecute";
import {makeRequest} from "./utils";

const log = debug("campaignExecute");


function getCampaignCustomers({campaignSlug}) {
    log('requesting campaign customers');
    return get(endpoints.campaigns.listCustomers.replace(':campaign', campaignSlug));
}

function* getCampaignCustomersData(action) {
    log('got a request to get campaign customers', action);
    yield makeRequest(actions.requestCampaignCustomersSuccess, actions.requestCampaignCustomersError, getCampaignCustomers, action.payload);
}


function executeCampaign({isSendAll, customerSlugs, campaignSlug}) {
    log('requesting to execute campaign for customers', isSendAll, customerSlugs, campaignSlug);
    let payload = {};
    if (isSendAll != null && isSendAll === true) {
        payload.is_send_all = true;
    } else if (customerSlugs != null && !isEmpty(customerSlugs)) {
        payload.customers = customerSlugs;
    }
    if (!isEmpty(payload)) {
        return post(endpoints.campaigns.execute.replace(":campaign", campaignSlug), payload);
    } else {
        log('payload is empty. exiting');
        return new Promise(() => {
            return {success: false, error: "Error executing this request. Please try again later"}
        });
    }
}

function* doExecuteCampaign(action) {
    log('got a request to execute campaign', action);
    yield makeRequest(actions.campaignSendAllSuccess, actions.campaignSendAllError, executeCampaign, action.payload);
}

// WATCHERS
export function* watchRequestGetCampaignCustomers() {
    yield takeEvery(actions.requestCampaignCustomers.type, getCampaignCustomersData);
}

export function* watchRequestExecuteCampaign() {
    yield takeEvery(actions.campaignSendAll.type, doExecuteCampaign);
}
