import {call, put, takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get, patch} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/newsItems";
import {makeRequest} from "./utils";

const log = debug("newsItemsSaga");


function getAccountNewsItems(customer, status, offset, limit, search) {
    log('requesting news items');
    const getParams = {
        customer: customer,
        status: status,
        offset: offset,
        limit: limit,
        search: search,
    };
    return get(endpoints.newsItems.listNewsItems, getParams);
}

function* getNewsItemsData(action) {
    log('got a request to get news items', action);
    yield makeRequest(
        actions.requestNewsItemsSuccess,
        actions.requestNewsItemsError,
        getAccountNewsItems,
        action.customer, action.status, action.offset, action.limit, action.search
    );
}

function getAccountNewsItemsRefresh(customer, status, offset, limit, search) {
    log('requesting news items, refreshed');
    const getParams = {
        customer: customer,
        status: status,
        offset: offset,
        limit: limit,
        search: search,
    };
    return get(endpoints.newsItems.listNewsItems, getParams);
}

function* getNewsItemsDataRefresh(action) {
    log('got a request to get news items, refreshed', action);
    yield makeRequest(
        actions.requestRefreshNewsItemsSuccess,
        actions.requestRefreshNewsItemsError,
        getAccountNewsItemsRefresh,
        action.customer, action.status, action.offset, action.limit, action.search
    );
}

function updateNewsItem(actionPayload, newsItem) {
    log('news item is', newsItem);
    let payload;
    if (actionPayload.status) {
        payload = {
            status: actionPayload.status,
        };
    } else {
        payload = {
            title: actionPayload.title,
            description: actionPayload.description || '',
            link: actionPayload.link || '',
            segments: actionPayload.segments || [],
            customers: actionPayload.customers || [],
            contact_types: actionPayload.contactTypes || [],
            classifications: actionPayload.classifications || [],
            goals: actionPayload.goals || [],
            deadline: actionPayload.deadline || null,
            email: actionPayload.email,
        }
    }
    log ('transplanting the action to server payload', payload);
    return patch(endpoints.newsItems.update.replace(':item', newsItem), payload);
}

function* handleUpdateNewsItem(action) {
    log('got request to update news item', action);
    yield makeRequest(actions.updateNewsItemSuccess, actions.updateNewsItemError, updateNewsItem, action.payload, action.newsItem);
}


function fetchNewsItemsTags(accountSlug) {
    return get(endpoints.newsItems.listNewsItemTag.replace(":account", accountSlug));
}

function* getNewsItemsTags(action) {
    log('got request to get news items tags', action);
    yield makeRequest(actions.requestTagsDataSuccess, actions.requestTagsDataError, fetchNewsItemsTags, action.payload);
}


// WATCHERS
export function* watchRequestForNewsItems() {
    yield takeEvery(types.NEWS_ITEMS_DATA_REQUEST, getNewsItemsData);
}

export function* watchRequestForNewsItemsRefresh() {
    yield takeEvery(types.NEWS_ITEMS_DATA_REFRESH_REQUEST, getNewsItemsDataRefresh);
}

export function* watchRequestForUpdateNewsItem() {
    yield takeEvery(types.UPDATING_NEWS_ITEM, handleUpdateNewsItem);
}

export function* watchRequestForNewsItemsTags() {
    yield takeEvery(actions.requestTagsData.type, getNewsItemsTags);
}
