// @flow
import type { SaveSearchInteractionParams } from "../pages/Interactions/InteractionsTimeline/types";
import type { Sentiment } from "../types/sentiments";
import { toArray, toSelectOption } from "./utils";

export function parseSentimentServerResponse(segment: Object): Sentiment {
    return toSelectOption({
        slug: segment.slug,
        name: segment.name,
        color: segment.color,
        order: segment.order,
    });
}

export function parseSentimentsServerResponse(segments: $ReadOnlyArray<Object>): Array<Sentiment> {
    return toArray(segments, parseSentimentServerResponse);
}

export function parseInteractionSearchServerResponse(searchDetails: $ReadOnly<Object>): SaveSearchInteractionParams {
    return {
        slug: searchDetails.slug,
        title: searchDetails.title,
        period: searchDetails.params.period,
        periodStart: searchDetails.params.period_start,
        periodEnd: searchDetails.params.period_end,
        csms: searchDetails.params.csms,
        contactTypes: searchDetails.params.contact_types,
        contacts: searchDetails.params.contacts,
        customers: searchDetails.params.customers,
        segments: searchDetails.params.segments,
        sentiments: searchDetails.params.sentiments,
        text: searchDetails.params.text,
        onlyDirectParticipants: searchDetails.params.only_direct_participants,
        groupBy: searchDetails.params.group_by,
    };
}
