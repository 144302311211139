import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get, patch} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/campaigns";
import {makeRequest} from "./utils";

const log = debug("campaignsSaga");


function getAccountCampaigns(active, offset, limit, search) {
    log('requesting campaigns');
    let getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    if (active) {
        getParams = {
            ...getParams,
            active: active,
        };
    }
    return get(endpoints.campaigns.listCreateUpdate, getParams);
}

function* getCampaignsData(action) {
    log('got a request to get campaigns', action);
    yield makeRequest(actions.requestCampaignsSuccess, actions.requestCampaignsError, getAccountCampaigns, action.active, action.offset, action.limit, action.search);
}

function getAccountCampaignsRefresh(active, offset, limit, search) {
    log('requesting campaigns, refreshed');
    let getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    if (active) {
        getParams = {
            ...getParams,
            active: active,
        };
    }
    return get(endpoints.campaigns.listCreateUpdate, getParams);
}

function* getCampaignsDataRefresh(action) {
    log('got a request to get campaigns, refreshed', action);
    yield makeRequest(
        actions.requestRefreshCampaignsSuccess,
        actions.requestRefreshCampaignsError,
        getAccountCampaignsRefresh,
        action.active, action.offset, action.limit, action.search
    );
}

function updateCampaignActive(actionPayload) {
    const payload = {
            is_active: actionPayload.active,
            slug: actionPayload.campaign,
        };
    log ('transplanting the action to server payload', payload);
    return patch(endpoints.campaigns.listCreateUpdate, payload);
}

function* handleUpdateCampaignActive(action) {
    log('got request to update campaign active state', action);
    yield makeRequest(actions.updateCampaignActiveSuccess, actions.updateCampaignActiveError, updateCampaignActive, action.payload);
}

function updateCampaignSync(actionPayload) {
    const payload = {
            is_salesforce_sync: actionPayload.sync,
            slug: actionPayload.campaign,
        };
    log ('transplanting the action to server payload', payload);
    return patch(endpoints.campaigns.listCreateUpdate, payload);
}

function* handleUpdateCampaignSync(action) {
    log('got request to update campaign sync state', action);
    yield makeRequest(actions.updateCampaignSyncSuccess, actions.updateCampaignSyncError, updateCampaignSync, action.payload);
}


// WATCHERS
export function* watchRequestForCampaigns() {
    yield takeEvery(types.CAMPAIGNS_DATA_REQUEST, getCampaignsData);
}

export function* watchRequestForCampaignsRefresh() {
    yield takeEvery(types.CAMPAIGNS_DATA_REFRESH_REQUEST, getCampaignsDataRefresh);
}

export function* watchRequestForUpdateCampaignActive() {
    yield takeEvery(types.UPDATING_CAMPAIGN_ACTIVE, handleUpdateCampaignActive);
}

export function* watchRequestForUpdateCampaignSync() {
    yield takeEvery(types.UPDATING_CAMPAIGN_SYNC, handleUpdateCampaignSync);
}
