import type {SelectOption} from "../types/common";

export function toArray(objects: Array<Object>, parserFunc: (obj: Object) => Object) {
    let parsedObjects = [];
    objects.forEach((obj, _idx) => {
        parsedObjects.push(parserFunc(obj));
    });
    return parsedObjects;
}

export function toSelectOption(obj: {slug: string, name: string}): SelectOption {
    return {
        ...obj,
        label: obj.name,
        value: obj.slug,
    };
}
