import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { getCustomerData, updateVerdict } from "./api";
import { actions } from "./reducer";

const log = debug("CustomerDetailsSaga");

function* fetchCustomerDetails(action) {
    log("got request to fetch customer data", action);
    yield makeRequest(
        actions.requestCustomerDataSuccess,
        actions.requestCustomerDataError,
        getCustomerData,
        action.payload
    );
}

function* updateCustomerInsightVerdict(action) {
    log("got request to update insight verdict", action);
    yield makeRequest(
        actions.updateInsightVerdictSuccess,
        actions.updateInsightVerdictError,
        updateVerdict,
        action.payload
    );
}

const sagas = [
    takeLatest(actions.requestCustomerData.type, fetchCustomerDetails),
    takeLatest(actions.updateInsightVerdict.type, updateCustomerInsightVerdict),
];
export default sagas;
