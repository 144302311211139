import debug from "debug";
import {takeEvery} from 'redux-saga/effects';

import {types, actions} from "../reducers/newsItemFormCustomers";
import {endpoints} from "../constants/api";
import {post} from "../utils/requester";
import {makeRequest} from "./utils";

const log = debug("NewsItemFormCustomersSaga");


function saveNewNewsItemCustomers(actionPayload) {
    log('account is', actionPayload.account);
    let payload = {
        account: actionPayload.account,
        classifications: actionPayload.classifications || [],
        contact_types: actionPayload.contactTypes || [],
        customers: actionPayload.customers || [],
        deadline: actionPayload.deadline || null,
        description: actionPayload.description || '',
        goals: actionPayload.goals || [],
        link: actionPayload.link || '',
        segments: actionPayload.segments || [],
        status: actionPayload.status,
        tags: actionPayload.tags || [],
        title: actionPayload.title,
    };
    log ('transplanting the action to server payload', payload);
    return post(endpoints.newsItems.create, payload);
}

function* handleSaveNewNewsItemCustomers(action) {
    log('got request to save new news item for customers', action);
    yield makeRequest(actions.saveNewNewsItemCustomerSuccess, actions.saveNewNewsItemCustomerError, saveNewNewsItemCustomers, action.payload);
}

export function* watchRequestSaveNewNewsItemCustomers() {
    yield takeEvery(types.SUBMITTING_NEWS_ITEM_CUSTOMERS, handleSaveNewNewsItemCustomers);
}
