import { push } from "connected-react-router";
import debug from "debug";
import { put, takeLatest } from "redux-saga/effects";
import { routes } from "../../../constants/routes";
import { makeAuthenticatedCall, makeRequest } from "../../../sagas/utils";
import {
    createInitialContactMessage,
    getContactsCustomer,
    getContactTypes,
    getCustomerInfo,
    getNewsItemsCustomer,
    updateNewsItemContactRelevance,
} from "./api";
import { actions, types } from "./reducer";

const log = debug("customerContactsSaga");

function* getCustomerContentData(action) {
    log("got a request to get customer content", action);

    const {
        success: getCustomerInfoSuccess,
        response: getCustomerInfoResponse,
        error: getCustomerInfoError,
    } = yield makeAuthenticatedCall(getCustomerInfo, action.customer, action.account);
    if (!getCustomerInfoSuccess) {
        log("api bad request", getCustomerInfoError);
        yield put(actions.requestCustomerContentError(getCustomerInfoError));
        return;
    }

    const {
        success: getCustomerNewsItemsSuccess,
        response: getCustomerNewsItemsResponse,
        error: getCustomerNewsItemsError,
    } = yield makeAuthenticatedCall(getNewsItemsCustomer, action.customer);
    if (!getCustomerNewsItemsSuccess) {
        log("api bad request", getCustomerNewsItemsError);
        yield put(actions.requestCustomerContentError(getCustomerNewsItemsError));
        return;
    }

    const {
        success: getCustomerContactsSuccess,
        response: getCustomerContactsResponse,
        error: getCustomerContactsError,
    } = yield makeAuthenticatedCall(getContactsCustomer, action.customer);
    if (!getCustomerContactsSuccess) {
        log("api bad request", getCustomerContactsError);
        yield put(actions.requestCustomerContentError(getCustomerContactsError));
        return;
    }

    const {
        success: getContactTypesSuccess,
        response: getContactTypesResponse,
        error: getContactTypesError,
    } = yield makeAuthenticatedCall(getContactTypes, action.customer, action.account);
    if (!getContactTypesSuccess) {
        log("api bad request", getContactTypesError);
        yield put(actions.requestCustomerContentError(getContactTypesError));
        return;
    }

    yield put(
        actions.requestCustomerContentSuccess({
            customer: getCustomerInfoResponse,
            newsItems: getCustomerNewsItemsResponse,
            contacts: getCustomerContactsResponse,
            contactTypes: getContactTypesResponse,
        })
    );
}

function* getCustomerInfoData(action) {
    log("got a request to get customer info", action);
    yield makeRequest(
        actions.requestCustomerInfoSuccess,
        actions.requestCustomerInfoError,
        getCustomerInfo,
        action.customer,
        action.account
    );
}

function* getCustomerNewsItemsData(action) {
    log("got a request to get customer news items", action);
    yield makeRequest(
        actions.requestCustomerNewsItemsSuccess,
        actions.requestCustomerNewsItemsError,
        getNewsItemsCustomer,
        action.customer
    );
}

function* getCustomerContactData(action) {
    log("got a request to get customer contacts", action);
    yield makeRequest(
        actions.requestCustomerContactsSuccess,
        actions.requestCustomerContactsError,
        getContactsCustomer,
        action.customer
    );
}

function* handleUpdateNewsItemContactRelevance(action) {
    log("got request to update news item contact relevance", action);
    yield makeRequest(
        actions.updateNewsItemContactRelevanceSuccess,
        actions.updateNewsItemContactRelevanceError,
        updateNewsItemContactRelevance,
        action.payload
    );
}

function* handleCreateInitialContactMessage(action) {
    log("got request to create contact message", action);
    const { success, response, error, statusCode } = yield makeAuthenticatedCall(
        createInitialContactMessage,
        action.contact
    );

    if (success) {
        log("save api OK");
        yield put(actions.createContactMessageSuccess(response));
        log("new message", response);
        yield put(
            push(
                routes.messages.edit
                    .replace(":customer", response.contact.customer.slug)
                    .replace(":contact", response.contact.slug)
                    .replace(":message", response.slug)
            )
        );
    } else if (statusCode === 401) {
        log("token expired");
    } else {
        log("api bad request", error);
        yield put(actions.createContactMessageError(error));
    }
}

const sagas = [
    takeLatest(types.CUSTOMER_CONTENT_REQUEST, getCustomerContentData),
    takeLatest(types.CUSTOMER_INFO_REQUEST, getCustomerInfoData),
    takeLatest(types.CUSTOMER_NEWS_ITEMS_REQUEST, getCustomerNewsItemsData),
    takeLatest(types.CUSTOMER_CONTACTS_REQUEST, getCustomerContactData),
    takeLatest(types.UPDATING_NEWS_ITEM_CONTACT_RELEVANCE, handleUpdateNewsItemContactRelevance),
    takeLatest(types.CREATING_CONTACT_MESSAGE, handleCreateInitialContactMessage),
];
export default sagas;
