import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/feedbacks";
import {makeRequest} from "./utils";

const log = debug("feedbacksSaga");


function getAccountFeedbacks(user, offset, limit, search) {
    log('requesting feedbacks');
    let getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    if (user) {
        getParams = {
            ...getParams,
            user: user,
        };
    }
    return get(endpoints.contactMessages.listFeedbacks, getParams);
}

function* getFeedbacksData(action) {
    log('got a request to get feedbacks', action);
    yield makeRequest(
        actions.requestFeedbacksSuccess,
        actions.requestFeedbacksError,
        getAccountFeedbacks,
        action.user, action.offset, action.limit, action.search
    );
}

function getAccountFeedbacksRefresh(user, offset, limit, search) {
    log('requesting feedbacks, refreshed');
    let getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    if (user) {
        getParams = {
            ...getParams,
            user: user,
        };
    }
    return get(endpoints.contactMessages.listFeedbacks, getParams);
}

function* getFeedbacksDataRefresh(action) {
    log('got a request to get feedbacks, refreshed', action);
    yield makeRequest(
        actions.requestRefreshFeedbacksSuccess,
        actions.requestRefreshFeedbacksError,
        getAccountFeedbacksRefresh,
        action.user, action.offset, action.limit, action.search
    );
}


// WATCHERS
export function* watchRequestForFeedbacks() {
    yield takeEvery(types.FEEDBACKS_DATA_REQUEST, getFeedbacksData);
}

export function* watchRequestForFeedbacksRefresh() {
    yield takeEvery(types.FEEDBACKS_DATA_REFRESH_REQUEST, getFeedbacksDataRefresh);
}
