import debug from "debug";
import { createAction } from "@reduxjs/toolkit";

import { getErrorData } from "../../../utils/errorData";
import { parseCustomerInsightsResponse } from "../../../parsers/customersList";
import some from "lodash-es/some";

const log = debug("customerInsightsReducer");

const DEFAULT_STATE = {
    errors: null,
    loading: false,
    customerInsights: [],
};

export const actions = {
    clearState: createAction("CUSTOMER_DETAILS/CLEAR_DATA"),

    requestCustomerInsights: createAction("CUSTOMER_INSIGHTS/REQUEST"),
    requestCustomerInsightsSuccess: createAction("CUSTOMER_INSIGHTS/REQUEST/SUCCESS"),
    requestCustomerInsightsError: createAction("CUSTOMER_INSIGHTS/REQUEST/ERROR"),
};

export default function customerInsightsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE;
        }

        case actions.requestCustomerInsights.type: {
            return {
                ...state,
                errors: null,
                loading: true,
            };
        }

        case actions.requestCustomerInsightsSuccess.type: {
            log("customers insights are", action.payload);
            return {
                ...state,
                errors: null,
                loading: false,
                customerInsights: parseCustomerInsightsResponse(action.payload),
            };
        }

        case actions.requestCustomerInsightsError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => some([state.customerInsights.loading], (val) => val === true);
export const getCustomerInsights = (state) => state.customerInsights.customerInsights;
export const getErrors = (state) => state.customerInsights.errors;
