import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/campaignReport";
import {makeRequest} from "./utils";

const log = debug("campaignReport");


function getCampaignReport(campaign) {
    log('requesting campaign report');
    return get(endpoints.campaigns.report.replace(':campaign', campaign));
}

function* getCampaignReportData(action) {
    log('got a request to get campaign report', action);
    yield makeRequest(actions.requestCampaignReportSuccess, actions.requestCampaignReportError, getCampaignReport, action.campaign);
}


// WATCHERS
export function* watchRequestGetCampaignReport() {
    yield takeEvery(types.CAMPAIGN_REPORT_REQUEST, getCampaignReportData);
}
