import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('feedbacksReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    feedbacks: [],
    totalCount: 0,
};

// ACTION TYPES
export const types = {
    FEEDBACKS_CLEAR_DATA: 'FEEDBACKS/CLEAR/DATA',

    FEEDBACKS_DATA_REQUEST: 'FEEDBACKS/REQUEST',
    FEEDBACKS_DATA_REQUEST_SUCCESS: 'FEEDBACKS/REQUEST/SUCCESS',
    FEEDBACKS_DATA_REQUEST_ERROR: 'FEEDBACKS/REQUEST/ERROR',

    FEEDBACKS_DATA_REFRESH_REQUEST: 'FEEDBACKS_REFRESH/REQUEST',
    FEEDBACKS_DATA_REFRESH_REQUEST_SUCCESS: 'FEEDBACKS_REFRESH/REQUEST/SUCCESS',
    FEEDBACKS_DATA_REFRESH_REQUEST_ERROR: 'FEEDBACKS_REFRESH/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    clearData: () => ({
        type: types.FEEDBACKS_CLEAR_DATA,
    }),

    requestFeedbacks: (user, offset, limit, search) => ({
        type: types.FEEDBACKS_DATA_REQUEST,
        user: user,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestFeedbacksSuccess: (payload) => ({
        type: types.FEEDBACKS_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestFeedbacksError: (errors) => ({
        type: types.FEEDBACKS_DATA_REQUEST_ERROR,
        errors,
    }),

    requestRefreshFeedbacks: (user, offset, limit, search) => ({
        type: types.FEEDBACKS_DATA_REFRESH_REQUEST,
        user: user,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestRefreshFeedbacksSuccess: (payload) => ({
        type: types.FEEDBACKS_DATA_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshFeedbacksError: (errors) => ({
        type: types.FEEDBACKS_DATA_REFRESH_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function feedbacksReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.FEEDBACKS_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.FEEDBACKS_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.FEEDBACKS_DATA_REQUEST_SUCCESS: {
            log('feedbacks are', action.payload);
            let feedbacks = Array.from(state.feedbacks);
            feedbacks = feedbacks.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                feedbacks: feedbacks,
                totalCount: action.payload.count,
            }
        }

        case types.FEEDBACKS_DATA_REQUEST_ERROR:
        case types.FEEDBACKS_DATA_REFRESH_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.FEEDBACKS_DATA_REFRESH_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.FEEDBACKS_DATA_REFRESH_REQUEST_SUCCESS: {
            log('feedbacks are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                feedbacks: action.payload.results,
                totalCount: action.payload.count,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.feedbacks.loading;
export const getFeedbacks = (state) => state.feedbacks.feedbacks;
export const getErrors = (state) => state.feedbacks.errors;
export const getSuccess = (state) => state.feedbacks.success;
export const getTotal = (state) => state.feedbacks.totalCount;
