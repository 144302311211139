import debug from "debug";

import {getErrorData} from "../utils/errorData";
import {createAction} from "@reduxjs/toolkit";

const log = debug('campaignExecuteReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    campaignCustomers: [],
};

// ACTIONS
export const actions = {
    requestCampaignCustomers: createAction('CAMPAIGN_CUSTOMERS/REQUEST'),
    requestCampaignCustomersSuccess: createAction('CAMPAIGN_CUSTOMERS/REQUEST/SUCCESS'),
    requestCampaignCustomersError: createAction('CAMPAIGN_CUSTOMERS/REQUEST/ERROR'),

    campaignSendAll: createAction("CAMPAIGN_EXECUTE/REQUEST"),
    campaignSendAllSuccess: createAction("CAMPAIGN_EXECUTE/REQUEST/SUCCESS"),
    campaignSendAllError: createAction("CAMPAIGN_EXECUTE/REQUEST/ERROR"),
};

// REDUCERS
export default function campaignExecuteReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.requestCampaignCustomers.type: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case actions.requestCampaignCustomersSuccess.type: {
            return {
                ...state,
                loading: false,
                errors: null,
                campaignCustomers: action.payload
            }
        }

        case actions.requestCampaignCustomersError.type: {
            log("error is", action);
            const errorData = getErrorData(action.errors);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case actions.campaignSendAll.type: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case actions.campaignSendAllSuccess.type: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case actions.campaignSendAllError.type: {
            log("error is", action);
            const errorData = getErrorData(action.errors);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.campaignExecute.loading;
export const getErrors = (state) => state.campaignExecute.errors;
export const getSuccess = (state) => state.campaignExecute.success;
export const getCampaignCustomers = (state) => state.campaignExecute.campaignCustomers;
