import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/customers";
import {makeRequest} from "./utils";

const log = debug("customersSaga");


function getAccountCustomers(account, offset, limit) {
    log('requesting customers');
    const getParams = {
        offset: offset,
        limit: limit,
    };
    return get(endpoints.accountData.customers.replace(':account', account), getParams);
}

function* getCustomersData(action) {
    log('got a request to get customers', action);
    yield makeRequest(actions.requestCustomersSuccess, actions.requestCustomersError, getAccountCustomers, action.account, action.offset, action.limit);
}

function getAccountCustomersRefresh(account, offset, limit) {
    log('requesting customers', account);
    const getParams = {
        offset: offset,
        limit: limit,
    };
    return get(endpoints.accountData.customers.replace(':account', account), getParams);
}

function* getCustomersDataRefresh(action) {
    log('got a request to get customers, refreshed', action);
    yield makeRequest(actions.requestRefreshCustomersSuccess, actions.requestRefreshCustomersError, getAccountCustomersRefresh, action.account,  action.offset, action.limit);
}


// WATCHERS
export function* watchRequestForCustomers() {
    yield takeEvery(types.CUSTOMERS_DATA_REQUEST, getCustomersData);
}

export function* watchRequestForCustomersRefresh() {
    yield takeEvery(types.CUSTOMERS_DATA_REFRESH_REQUEST, getCustomersDataRefresh);
}
