import { connect } from "react-redux";
import debug from "debug";

import { actions, getIsLoading, getErrors, getSuccess } from "../reducers/newsItemFormCustomers";
import {
    actions as accountDataActions,
    getIsLoading as accountDataGetIsLoading,
    getErrors as accountDataGetErrors,
    getContactTypes,
    getSegments,
} from "../reducers/accountData";
import {
    actions as newsItemsActions,
    getIsLoading as newsItemsGetIsLoading,
    getErrors as newsItemsGetErrors,
    getNewsItems,
    getSuccess as newsItemsGetSuccess,
    getTags,
} from "../reducers/newsItems";
import {
    actions as customerContactsActions,
    getIsLoading as customerContactsGetIsLoading,
    getErrors as customerContactsGetErrors,
} from "../pages/Customers/CustomerContacts/reducer";
import { getUser } from "../reducers/profile";
import NewsItemFormCustomers from "../components/NewsItemForm/NewsItemFormCustomers";
import { LIMIT } from "../constants/pagination";

const log = debug("NewNewsItemFormCustomersContainer");

const mapStateToProps = (state) => ({
    isSubmitting:
        getIsLoading(state) ||
        accountDataGetIsLoading(state) ||
        newsItemsGetIsLoading(state) ||
        customerContactsGetIsLoading(state),
    errors:
        getErrors(state) ||
        accountDataGetErrors(state) ||
        newsItemsGetErrors(state) ||
        customerContactsGetErrors(state),
    success: getSuccess(state) || newsItemsGetSuccess(state),
    user: getUser(state),
    segmentData: getSegments(state),
    contactTypeData: getContactTypes(state),
    newsItems: getNewsItems(state),
    tagsData: getTags(state),
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        onFormSubmit: (values) => {
            dispatch(actions.saveNewNewsItemCustomer(values));
        },

        clearForm: () => {
            log("clearing form data");
            dispatch(actions.clearForm());
        },

        fetchSegmentData: (account) => {
            log("sending a request to fetch segment data");
            dispatch(accountDataActions.requestSegmentData(account));
        },

        fetchContactTypeData: (account) => {
            log("sending a request to fetch contact type data");
            dispatch(accountDataActions.requestContactTypeData(account));
        },

        fetchTagsData: (accountSlug) => {
            log("sending a request to fetch tags data");
            dispatch(newsItemsActions.requestTagsData(accountSlug));
        },

        fetchNewsItems: (customer, status, offset, limit) => {
            log("sending a request to fetch news items");
            dispatch(newsItemsActions.requestNewsItems(customer, status, offset, limit || LIMIT));
        },

        fetchRefreshNewsItems: (customer, status, offset, limit) => {
            log("sending a request to fetch news items, refreshed");
            dispatch(newsItemsActions.requestRefreshNewsItems(customer, status, offset, limit || LIMIT));
        },

        fetchCustomerContent: (customer, account) => {
            log("sending a request to fetch customer content");
            dispatch(customerContactsActions.requestCustomerContent(customer, account));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsItemFormCustomers);
