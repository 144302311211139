import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('PasswordReset');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    uid: '',
    token: '',
    password: '',
};

// ACTION TYPES
export const types = {
    SUBMIT_NEW_PASSWORD_REQUEST: 'SUBMIT_NEW_PASSWORD/REQUEST',
    SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS: 'SUBMIT_NEW_PASSWORD/REQUEST/SUCCESS',
    SUBMIT_NEW_PASSWORD_REQUEST_ERROR: 'SUBMIT_NEW_PASSWORD/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    setNewPassword: (payload) => ({
        type: types.SUBMIT_NEW_PASSWORD_REQUEST,
        payload: payload,
    }),
    setNewPasswordSuccess: (payload) => ({
        type: types.SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS,
        payload,
    }),
    setNewPasswordError: (errors) => ({
        type: types.SUBMIT_NEW_PASSWORD_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function passwordResetReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.SUBMIT_NEW_PASSWORD_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
                uid: action.payload.uid,
                token: action.payload.token,
                password: action.payload.password,
            }
        }

        case types.SUBMIT_NEW_PASSWORD_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.SUBMIT_NEW_PASSWORD_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.passwordReset.loading;
export const getErrors = (state) => state.passwordReset.errors;
export const getSuccess = (state) => state.passwordReset.success;
