import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('LoginReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    success: false,
    passwordResetSuccess: false,
    response: null,
    errors: null,
};

// ACTION TYPES
export const types = {
    GOOGLE_REQUEST: 'GOOGLE/REQUEST',
    GOOGLE_SUCCESS: 'GOOGLE/SUCCESS',
    GOOGLE_ERROR: 'GOOGLE/ERROR',

    SALESFORCE_REQUEST: 'SALESFORCE/REQUEST',
    SALESFORCE_SUCCESS: 'SALESFORCE/SUCCESS',
    SALESFORCE_ERROR: 'SALESFORCE/ERROR',

    ZENDESK_REQUEST: 'ZENDESK/REQUEST',
    ZENDESK_SUCCESS: 'ZENDESK/SUCCESS',
    ZENDESK_ERROR: 'ZENDESK/ERROR',

    LOGIN_REQUEST: 'LOGIN/REQUEST',
    LOGIN_SUCCESS: 'LOGIN/SUCCESS',
    LOGIN_ERROR: 'LOGIN/ERROR',

    LOGOUT_REQUEST: 'LOGOUT/REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT/SUCCESS',
    LOGOUT_ERROR: 'LOGOUT/ERROR',

    PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST/SUBMIT',
    PASSWORD_RESET_REQUEST_SUCCESS: 'PASSWORD_RESET_REQUEST/SUBMIT/SUCCESS',
    PASSWORD_RESET_REQUEST_ERROR: 'PASSWORD_RESET_REQUEST/SUBMIT/ERROR',
};

// ACTIONS
export const actions = {
    startGoogleRequest: payload => ({
        type: types.GOOGLE_REQUEST,
        payload,
    }),
    googleSuccess: payload => ({
        type: types.GOOGLE_SUCCESS,
        payload,
    }),
    googleError: (errors) => ({
        type: types.GOOGLE_ERROR,
        payload: {errors},
    }),

    startSalesForceRequest: payload => ({
        type: types.SALESFORCE_REQUEST,
        payload,
    }),
    salesForceSuccess: payload => ({
        type: types.SALESFORCE_SUCCESS,
        payload,
    }),
    salesForceError: (errors) => ({
        type: types.SALESFORCE_ERROR,
        payload: {errors},
    }),

    startZendeskRequest: payload => ({
        type: types.ZENDESK_REQUEST,
        payload,
    }),
    zendeskSuccess: payload => ({
        type: types.ZENDESK_SUCCESS,
        payload,
    }),
    zendeskError: (errors) => ({
        type: types.ZENDESK_ERROR,
        payload: {errors},
    }),

    requestLogin: (payload) => ({
        type: types.LOGIN_REQUEST,
        payload,
    }),
    loginSuccess: payload => ({
        type: types.LOGIN_SUCCESS,
        payload,
    }),
    loginError: (errors) => ({
        type: types.LOGIN_ERROR,
        payload: {errors},
    }),

    requestLogout: () => ({
        type: types.LOGOUT_REQUEST,
    }),
    logoutSuccess: payload => ({
        type: types.LOGOUT_SUCCESS,
        payload,
    }),
    logoutError: (errors) => ({
        type: types.LOGOUT_ERROR,
        payload: {errors},
    }),

    submitPasswordReset: (payload) => ({
        type: types.PASSWORD_RESET_REQUEST,
        payload,
    }),
    submitPasswordResetSuccess: payload => ({
        type: types.PASSWORD_RESET_REQUEST_SUCCESS,
        payload,
    }),
    submitPasswordResetError: (errors) => ({
        type: types.PASSWORD_RESET_REQUEST_ERROR,
        payload: {errors},
    }),
};

// REDUCERS
export default function loginReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.GOOGLE_REQUEST:
        case types.SALESFORCE_REQUEST:
        case types.ZENDESK_REQUEST:
        case types.LOGIN_REQUEST: {
            return {
                ...state,
                loading: true,
                success: false,
                response: null,
                errors: null,
            }
        }

        case types.GOOGLE_SUCCESS: {
            log("google request succeeded");
            return {
                ...state,
                loading: false,
                success: true,
                errors: null,
            };
        }

        case types.SALESFORCE_SUCCESS: {
            log("salesforce request succeeded");
            return {
                ...state,
                loading: false,
                success: true,
                errors: null,
            };
        }

        case types.ZENDESK_SUCCESS: {
            log("zendesk request succeeded");
            return {
                ...state,
                loading: false,
                success: true,
                errors: null,
            };
        }

        case types.LOGIN_SUCCESS: {
            log("login succeeded");
            window.localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                loading: false,
                success: true,
                errors: null,
            };
        }

        case types.LOGOUT_REQUEST: {
            return {
                ...state,
                loading: true,
                success: false,
                response: null,
                errors: null,
            }
        }

        case types.LOGOUT_SUCCESS: {
            window.localStorage.clear();
            return DEFAULT_STATE;
        }

        case types.GOOGLE_ERROR:
        case types.SALESFORCE_ERROR:
        case types.ZENDESK_ERROR:
        case types.LOGIN_ERROR:
        case types.LOGOUT_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.payload.errors)[0]);
            log("got error data", errorData);
            return {
                ...state,
                loading: false,
                success: false,
                response: null,
                errors: errorData,
            };
        }

        case types.PASSWORD_RESET_REQUEST: {
            return {
                ...state,
                loading: true,
                passwordResetSuccess: false,
                response: null,
                errors: null,
            }
        }

        case types.PASSWORD_RESET_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                passwordResetSuccess: true,
                errors: null,
            };
        }

        case types.PASSWORD_RESET_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.payload.errors)[0]);
            log("got error data", errorData);
            return {
                ...state,
                loading: false,
                passwordResetSuccess: false,
                response: null,
                errors: errorData,
            };
        }

        default: {
            return DEFAULT_STATE;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.login.loading;
export const getSuccess = (state) => state.login.success;
export const getPasswordResetSuccess = (state) => state.login.passwordResetSuccess;
export const getResponse = (state) => state.login.response;
export const getErrors = (state) => state.login.errors;
export const getAuthenticated = (state) => {
    return window.localStorage.getItem('token') ? true : false;
};
