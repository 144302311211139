import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('campaignFormReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    newsItemErrors: null,
    success: null,
    campaignNewsItems: [],
    totalCountNewsItems: 0,
    campaignContacts: [],
    totalCountContacts: 0,
    campaign: "",
    campaignMessageNewsItems: [],
    campaignDetailsInfo: {},
    campaignNewsItemsInfo: {},
    campaignMessageInfo: {},
    campaignContactsInfo: {},
    campaignContent: {},
};

// ACTION TYPES
export const types = {
    CAMPAIGN_FORM_CLEAR_DATA: 'CAMPAIGN_FORM/CLEAR/DATA',

    CAMPAIGN_NEWS_ITEMS_REQUEST: 'CAMPAIGN_NEWS_ITEMS/REQUEST',
    CAMPAIGN_NEWS_ITEMS_REQUEST_SUCCESS: 'CAMPAIGN_NEWS_ITEMS/REQUEST/SUCCESS',
    CAMPAIGN_NEWS_ITEMS_REQUEST_ERROR: 'CAMPAIGN_NEWS_ITEMS/REQUEST/ERROR',

    CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST: 'CAMPAIGN_NEWS_ITEMS_REFRESH/REQUEST',
    CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST_SUCCESS: 'CAMPAIGN_NEWS_ITEMS_REFRESH/REQUEST/SUCCESS',
    CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST_ERROR: 'CAMPAIGN_NEWS_ITEMS_REFRESH/REQUEST/ERROR',

    CAMPAIGN_CONTACTS_REQUEST: 'CAMPAIGN_CONTACTS/REQUEST',
    CAMPAIGN_CONTACTS_REQUEST_SUCCESS: 'CAMPAIGN_CONTACTS/REQUEST/SUCCESS',
    CAMPAIGN_CONTACTS_REQUEST_ERROR: 'CAMPAIGN_CONTACTS/REQUEST/ERROR',

    CAMPAIGN_CONTACTS_REFRESH_REQUEST: 'CAMPAIGN_CONTACTS_REFRESH/REQUEST',
    CAMPAIGN_CONTACTS_REFRESH_REQUEST_SUCCESS: 'CAMPAIGN_CONTACTS_REFRESH/REQUEST/SUCCESS',
    CAMPAIGN_CONTACTS_REFRESH_REQUEST_ERROR: 'CAMPAIGN_CONTACTS_REFRESH/REQUEST/ERROR',

    SUBMITTING_CAMPAIGN: 'CAMPAIGN/SUBMIT',
    SUBMITTING_CAMPAIGN_SUCCESS: 'CAMPAIGN/SUBMIT/SUCCESS',
    SUBMITTING_CAMPAIGN_ERROR: 'CAMPAIGN/SUBMIT/ERROR',

    SUBMITTING_CAMPAIGN_NEWS_ITEM: 'CAMPAIGN_NEWS_ITEM/SUBMIT',
    SUBMITTING_CAMPAIGN_NEWS_ITEM_SUCCESS: 'CAMPAIGN_NEWS_ITEM/SUBMIT/SUCCESS',
    SUBMITTING_CAMPAIGN_NEWS_ITEM_ERROR: 'CAMPAIGN_NEWS_ITEM/SUBMIT/ERROR',

    CAMPAIGN_CONTENT_REQUEST: 'CAMPAIGN_CONTENT/REQUEST',
    CAMPAIGN_CONTENT_REQUEST_SUCCESS: 'CAMPAIGN_CONTENT/REQUEST/SUCCESS',
    CAMPAIGN_CONTENT_REQUEST_ERROR: 'CAMPAIGN_CONTENT/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    clearData: () => ({
        type: types.CAMPAIGN_FORM_CLEAR_DATA,
    }),

    requestCampaignNewsItems: (show, offset, limit, search) => ({
        type: types.CAMPAIGN_NEWS_ITEMS_REQUEST,
        show: show,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestCampaignNewsItemsSuccess: (payload) => ({
        type: types.CAMPAIGN_NEWS_ITEMS_REQUEST_SUCCESS,
        payload,
    }),
    requestCampaignNewsItemsError: (errors) => ({
        type: types.CAMPAIGN_NEWS_ITEMS_REQUEST_ERROR,
        errors,
    }),

    requestRefreshCampaignNewsItems: (show, offset, limit, search) => ({
        type: types.CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST,
        show: show,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestRefreshCampaignNewsItemsSuccess: (payload) => ({
        type: types.CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshCampaignNewsItemsError: (errors) => ({
        type: types.CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST_ERROR,
        errors,
    }),

    requestCampaignContacts: (contactType, managed, offset, limit, search) => ({
        type: types.CAMPAIGN_CONTACTS_REQUEST,
        contactType: contactType,
        managed: managed,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestCampaignContactsSuccess: (payload) => ({
        type: types.CAMPAIGN_CONTACTS_REQUEST_SUCCESS,
        payload,
    }),
    requestCampaignContactsError: (errors) => ({
        type: types.CAMPAIGN_CONTACTS_REQUEST_ERROR,
        errors,
    }),

    requestRefreshCampaignContacts: (contactType, managed, offset, limit, search) => ({
        type: types.CAMPAIGN_CONTACTS_REFRESH_REQUEST,
        contactType: contactType,
        managed: managed,
        offset: offset,
        limit: limit,
        search: search,
    }),
    requestRefreshCampaignContactsSuccess: (payload) => ({
        type: types.CAMPAIGN_CONTACTS_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshCampaignContactsError: (errors) => ({
        type: types.CAMPAIGN_CONTACTS_REFRESH_REQUEST_ERROR,
        errors,
    }),

    submitCampaign: (payload) => ({
        type: types.SUBMITTING_CAMPAIGN,
        payload,
    }),
    submitCampaignSuccess: (payload) => ({
        type: types.SUBMITTING_CAMPAIGN_SUCCESS,
        payload,
    }),
    submitCampaignError: (errors) => ({
        type: types.SUBMITTING_CAMPAIGN_ERROR,
        errors,
    }),

    submitCampaignNewsItem: (payload) => ({
        type: types.SUBMITTING_CAMPAIGN_NEWS_ITEM,
        payload,
    }),
    submitCampaignNewsItemSuccess: (payload) => ({
        type: types.SUBMITTING_CAMPAIGN_NEWS_ITEM_SUCCESS,
        payload,
    }),
    submitCampaignNewsItemError: (errors) => ({
        type: types.SUBMITTING_CAMPAIGN_NEWS_ITEM_ERROR,
        errors,
    }),
    
    requestCampaignContent: (campaign) => ({
        type: types.CAMPAIGN_CONTENT_REQUEST,
        campaign: campaign,
    }),
    requestCampaignContentSuccess: (payload) => ({
        type: types.CAMPAIGN_CONTENT_REQUEST_SUCCESS,
        payload,
    }),
    requestCampaignContentError: (errors) => ({
        type: types.CAMPAIGN_CONTENT_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function campaignFormReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CAMPAIGN_FORM_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.CAMPAIGN_NEWS_ITEMS_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGN_NEWS_ITEMS_REQUEST_SUCCESS: {
            log('campaign news items are', action.payload);
            let campaignNewsItems = Array.from(state.campaignNewsItems);
            campaignNewsItems = campaignNewsItems.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                campaignNewsItems: campaignNewsItems,
                totalCountNewsItems: action.payload.count,
            }
        }

        case types.CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST_SUCCESS: {
            log('campaign news items are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                campaignNewsItems: action.payload.results,
                totalCountNewsItems: action.payload.count,
            }
        }

        case types.CAMPAIGN_CONTACTS_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGN_CONTACTS_REQUEST_SUCCESS: {
            log('campaign contacts are', action.payload);
            let campaignContacts = Array.from(state.campaignContacts);
            campaignContacts = campaignContacts.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                campaignContacts: campaignContacts,
                totalCountContacts: action.payload.count,
            }
        }

        case types.CAMPAIGN_CONTACTS_REFRESH_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGN_CONTACTS_REFRESH_REQUEST_SUCCESS: {
            log('campaign contacts are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                campaignContacts: action.payload.results,
                totalCountContacts: action.payload.count,
            }
        }

        case types.CAMPAIGN_NEWS_ITEMS_REQUEST_ERROR:
        case types.CAMPAIGN_NEWS_ITEMS_REFRESH_REQUEST_ERROR:
        case types.CAMPAIGN_CONTACTS_REQUEST_ERROR:
        case types.CAMPAIGN_CONTACTS_REFRESH_REQUEST_ERROR:
        case types.CAMPAIGN_CONTENT_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(action.errors);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.SUBMITTING_CAMPAIGN: {
            let submitCampaign = {
                ...state,
                loading: true,
                errors: null,
                success: null,
            };
            if ('campaignDetails' in action.payload) {
                submitCampaign = {
                    ...submitCampaign,
                    campaignDetailsInfo: action.payload.campaignDetails,
                }
            }
            if ('campaignNewsItems' in action.payload) {
                submitCampaign = {
                    ...submitCampaign,
                    campaignNewsItemsInfo: action.payload.campaignNewsItems,
                }
            }
            if ('campaignMessage' in action.payload) {
                submitCampaign = {
                    ...submitCampaign,
                    campaignMessageInfo: action.payload.campaignMessage,
                }
            }
            if ('campaignContacts' in action.payload) {
                submitCampaign = {
                    ...submitCampaign,
                    campaignContactsInfo: action.payload.campaignContacts,
                }
            }
            return submitCampaign;
        }

        case types.SUBMITTING_CAMPAIGN_SUCCESS: {
            let campaignMessageNewsItems = [];
            if (action.payload.campaign_news_items && action.payload.campaign_news_items.length !== 0) {
                campaignMessageNewsItems = action.payload.campaign_news_items;
                campaignMessageNewsItems.map((ni, idx) => {
                    ni.idx = idx;
                });
            }
            log('campaign news items are', campaignMessageNewsItems);
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
                campaign: action.payload.slug,
                campaignDetailsInfo: action.payload,
                campaignMessageNewsItems: campaignMessageNewsItems,
                campaignMessageSubject: action.payload.subject,
            }
        }

        case types.SUBMITTING_CAMPAIGN_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        case types.SUBMITTING_CAMPAIGN_NEWS_ITEM: {
            return {
                ...state,
                loading: true,
                newsItemErrors: null,
                success: null,
                payload: action.payload,
            }
        }

        case types.SUBMITTING_CAMPAIGN_NEWS_ITEM_SUCCESS: {
            return {
                ...state,
                loading: false,
                newsItemErrors: null,
                success: true,
            }
        }

        case types.SUBMITTING_CAMPAIGN_NEWS_ITEM_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                newsItemErrors: errorData,
                success: false,
            }
        }

        case types.CAMPAIGN_CONTENT_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CAMPAIGN_CONTENT_REQUEST_SUCCESS: {
            log('campaign details are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                campaignContent: action.payload,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.campaignForm.loading;
export const getCampaignNewsItems = (state) => state.campaignForm.campaignNewsItems;
export const getCampaignContacts = (state) => state.campaignForm.campaignContacts;
export const getErrors = (state) => state.campaignForm.errors;
export const getNewsItemErrors = (state) => state.campaignForm.newsItemErrors;
export const getSuccess = (state) => state.campaignForm.success;
export const getTotalNewsItems = (state) => state.campaignForm.totalCountNewsItems;
export const getTotalContacts = (state) => state.campaignForm.totalCountContacts;
export const getCampaign = (state) => state.campaignForm.campaign;
export const getCampaignMessageNewsItems = (state) => state.campaignForm.campaignMessageNewsItems;
export const getCampaignMessageSubject = (state) => state.campaignForm.campaignMessageSubject;
export const getCampaignDetailsInfo = (state) => state.campaignForm.campaignDetailsInfo;
export const getCampaignNewsItemsInfo = (state) => state.campaignForm.campaignNewsItemsInfo;
export const getCampaignMessageInfo = (state) => state.campaignForm.campaignMessageInfo;
export const getCampaignContactsInfo = (state) => state.campaignForm.campaignContactsInfo;
export const getCampaignContent = (state) => state.campaignForm.campaignContent;
