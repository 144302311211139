// @flow
import type { Contact, ContactSimple, ContactType } from "../types/contacts";
import { toArray, toSelectOption } from "./utils";

export function parseContactSimpleServerResponse(contact: Object): ContactSimple {
    return toSelectOption({
        slug: contact.slug,
        name: contact.name,
    });
}

export function parseContactsSimpleServerResponse(contacts: $ReadOnlyArray<Object>): Array<ContactSimple> {
    return toArray(contacts, parseContactSimpleServerResponse);
}

export function parseContactTypeServerResponse(data: Object): ContactType {
    return toSelectOption({
        slug: data.slug,
        name: data.name,
    });
}

export function parseContactTypesServerResponse(data: $ReadOnlyArray<Object>): Array<ContactType> {
    return toArray(data, parseContactTypeServerResponse);
}

export function parseContactServerResponse(data: Object): Contact {
    return {
        slug: data.slug,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        account: data.account,
        customer: data.customer,
        contactType: data.contact_type ? parseContactTypeServerResponse(data.contact_type) : null,
    };
}

export function parseContactsServerResponse(data: $ReadOnlyArray<Object>): Array<Contact> {
    return toArray(data, parseContactServerResponse);
}
