import {createAction} from "@reduxjs/toolkit";
import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('ContactMessageEditReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    account: '',
    contact: {},
    customer: '',
    recipientEmail: '',
    subject: '',
    greeting: '',
    newsItems: [],
    feedbackBtnTxt: '',
    footer: '',
    signature: '',
    draft: true,
    contactMessage: {},
    profileData: {},
};

// ACTION TYPES
export const types = {
    SUBMITTING_CONTACT_MESSAGE: 'CONTACT_MESSAGE/EDIT/SUBMIT',
    SUBMITTING_CONTACT_MESSAGE_SUCCESS: 'CONTACT_MESSAGE/EDIT/SUBMIT/SUCCESS',
    SUBMITTING_CONTACT_MESSAGE_ERROR: 'CONTACT_MESSAGE/EDIT/SUBMIT/ERROR',

    INITIAL_CONTENT_REQUEST: 'CONTACT_MESSAGE/EDIT/INITIAL_CONTENT/REQUEST',
    INITIAL_CONTENT_REQUEST_SUCCESS: 'CONTACT_MESSAGE/EDIT/INITIAL_CONTENT/REQUEST/SUCCESS',
    INITIAL_CONTENT_REQUEST_ERROR: 'CONTACT_MESSAGE/EDIT/INITIAL_CONTENT/REQUEST/ERROR',

    MESSAGE_CONTENT_REQUEST: 'MESSAGE_CONTENT/REQUEST',
    MESSAGE_CONTENT_REQUEST_SUCCESS: 'MESSAGE_CONTENT/REQUEST/SUCCESS',
    MESSAGE_CONTENT_REQUEST_ERROR: 'MESSAGE_CONTENT/REQUEST/ERROR',

    CONTACT_MESSAGE_UNDO: 'CONTACT_MESSAGE/UNDO',
    CONTACT_MESSAGE_UNDO_SUCCESS: 'CONTACT_MESSAGE/UNDO/SUCCESS',
    CONTACT_MESSAGE_UNDO_ERROR: 'CONTACT_MESSAGE/UNDO/ERROR',
};

// ACTIONS
export const actions = {
    clearState: createAction("CONTACT_MESSAGE/EDIT/CLEAR_STATE"),
    submitContactMessage: (payload, message_slug, campaign_slug) => ({
        type: types.SUBMITTING_CONTACT_MESSAGE,
        payload,
        message_slug: message_slug,
        campaign_slug: campaign_slug,
    }),
    submitContactMessageSuccess: (payload) => ({
        type: types.SUBMITTING_CONTACT_MESSAGE_SUCCESS,
        payload,
    }),
    submitContactMessageError: (errors) => ({
        type: types.SUBMITTING_CONTACT_MESSAGE_ERROR,
        errors,
    }),

    getInitialContent: (customer_slug, contact_slug) => ({
        type: types.INITIAL_CONTENT_REQUEST,
        customer_slug: customer_slug,
        contact_slug: contact_slug,
    }),
    getInitialContentSuccess: (payload) => ({
        type: types.INITIAL_CONTENT_REQUEST_SUCCESS,
        payload,
    }),
    getInitialContentError: (errors) => ({
        type: types.INITIAL_CONTENT_REQUEST_ERROR,
        errors,
    }),

    getMessageContent: (message_slug) => ({
        type: types.MESSAGE_CONTENT_REQUEST,
        message_slug: message_slug,
    }),
    getMessageContentSuccess: (payload) => ({
        type: types.MESSAGE_CONTENT_REQUEST_SUCCESS,
        payload,
    }),
    getMessageContentError: (errors) => ({
        type: types.MESSAGE_CONTENT_REQUEST_ERROR,
        errors,
    }),

    undoContactMessage: (message_slug, customer_slug) => ({
        type: types.CONTACT_MESSAGE_UNDO,
        message_slug: message_slug,
        customer_slug: customer_slug,
    }),
    undoContactMessageSuccess: (payload) => ({
        type: types.CONTACT_MESSAGE_UNDO_SUCCESS,
        payload,
    }),
    undoContactMessageError: (errors) => ({
        type: types.CONTACT_MESSAGE_UNDO_ERROR,
        errors,
    }),
};

// REDUCERS
export default function contactMessageEditReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE
        }

        case types.SUBMITTING_CONTACT_MESSAGE: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
                recipientEmail: action.payload.recipientEmail,
                subject: action.payload.subject,
                greeting: action.payload.greeting,
                newsItems: action.payload.newsItems,
                feedbackBtnTxt: action.payload.feedbackBtnTxt,
                footer: action.payload.footer,
                signature: action.payload.signature,
                draft: action.payload.draft,
            }
        }

        case types.SUBMITTING_CONTACT_MESSAGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.INITIAL_CONTENT_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.INITIAL_CONTENT_REQUEST_SUCCESS: {
            log('contact and news items are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                contact: action.payload.contact[0],
                newsItems: action.payload.newsItems,
            }
        }

        case types.MESSAGE_CONTENT_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.MESSAGE_CONTENT_REQUEST_SUCCESS: {
            log('contact message is', action.payload.message);
            let msg = {...action.payload.message};
            msg.news_items.map((ni, idx) => {
                ni.idx = idx;
            });
            return {
                ...state,
                loading: false,
                errors: null,
                contactMessage: msg,
                profileData: action.payload.profileData,
            }
        }

        case types.CONTACT_MESSAGE_UNDO: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case types.CONTACT_MESSAGE_UNDO_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.INITIAL_CONTENT_REQUEST_ERROR:
        case types.MESSAGE_CONTENT_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.SUBMITTING_CONTACT_MESSAGE_ERROR:
        case types.CONTACT_MESSAGE_UNDO_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.contactMessageEdit.loading;
export const getErrors = (state) => state.contactMessageEdit.errors;
export const getSuccess = (state) => state.contactMessageEdit.success;
export const getContact = (state) => state.contactMessageEdit.contact;
export const getContactNewsItems = (state) => state.contactMessageEdit.newsItems;
export const getProfileData = (state) => state.contactMessageEdit.profileData;
export const getContactMessage = (state) => state.contactMessageEdit.contactMessage;
