// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ServerResponse } from "../../../types/common";
import { get, post } from "../../../utils/requester";
import type { SaveSearchInteractionParams, ShareSavedSearchPayload } from "./types";

const log = debug("InteractionsTimelineAPI");

const SEARCH_INTERACTIONS_PARAMS = [
    { paramsKey: "period", payloadKey: "period" },
    { paramsKey: "periodStart", payloadKey: "period_start" },
    { paramsKey: "periodEnd", payloadKey: "period_end" },
    { paramsKey: "csms", payloadKey: "csms" },
    { paramsKey: "customers", payloadKey: "customers" },
    { paramsKey: "segments", payloadKey: "segments" },
    { paramsKey: "contactTypes", payloadKey: "contact_types" },
    { paramsKey: "contacts", payloadKey: "contacts" },
    { paramsKey: "sentiments", payloadKey: "sentiments" },
    { paramsKey: "text", payloadKey: "text" },
    { paramsKey: "onlyDirectParticipants", payloadKey: "only_direct_participants" },
    { paramsKey: "groupBy", payloadKey: "group_by" },
    { paramsKey: "title", payloadKey: "title" },
    { paramsKey: "slug", payloadKey: "slug" },
];

export function fetchInteractions(params: SaveSearchInteractionParams): Promise<ServerResponse> {
    log("requesting interactions");
    let getParams = {};
    SEARCH_INTERACTIONS_PARAMS.forEach((p) => {
        const val = params[p.paramsKey];
        if (val != null) {
            getParams[p.payloadKey] = val;
        }
    });
    return get(endpoints.interactions.search, getParams);
}

export function saveInteractionSearch(params: SaveSearchInteractionParams): Promise<ServerResponse> {
    log("requesting to save interactions search", params);
    let postParams = {};
    SEARCH_INTERACTIONS_PARAMS.forEach((p) => {
        const val = params[p.paramsKey];
        if (val != null) {
            postParams[p.payloadKey] = val;
        }
    });
    return post(endpoints.interactions.saveSearch, postParams);
}

export function getSavedSearch(searchSlug: string): Promise<ServerResponse> {
    log("requesting saved search details", searchSlug);
    return get(endpoints.interactions.savedSearch.replace(":searchSlug", searchSlug));
}

export function shareSavedSearch(params: ShareSavedSearchPayload): Promise<ServerResponse> {
    log("requesting to share saved search", params);
    const endpoint = endpoints.interactions.shareSavedSearch.replace(":searchSlug", params.savedSearchSlug);
    return post(endpoint, { users: params.userSlugs });
}
