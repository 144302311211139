import debug from "debug";

import {types as authTypes} from "./auth";
import {getErrorData} from "../utils/errorData";

const log = debug('profileReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
	submitting: false,
	errors: null,
    success: null,
	user: {},
	profileData: {},
	signature: '',
};

export const types = {
	PROFILE_REQUEST: 'PROFILE/REQUEST',
	PROFILE_SUCCESS: 'PROFILE/SUCCESS',
	PROFILE_ERROR: 'PROFILE/ERROR',

	PROFILE_DATA_REQUEST: 'PROFILE_DATA/REQUEST',
	PROFILE_DATA_SUCCESS: 'PROFILE_DATA/SUCCESS',
	PROFILE_DATA_ERROR: 'PROFILE_DATA/ERROR',

    SUBMITTING_RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST/SUBMIT',
    SUBMITTING_RESET_PASSWORD_REQUEST_SUCCESS: 'RESET_PASSWORD_REQUEST/SUBMIT/SUCCESS',
    SUBMITTING_RESET_PASSWORD_REQUEST_ERROR: 'RESET_PASSWORD_REQUEST/SUBMIT/ERROR',

    UPDATE_SIGNATURE_REQUEST: 'UPDATE_SIGNATURE_REQUEST/SUBMIT',
    UPDATE_SIGNATURE_REQUEST_SUCCESS: 'UPDATE_SIGNATURE_REQUEST/SUBMIT/SUCCESS',
    UPDATE_SIGNATURE_REQUEST_ERROR: 'UPDATE_SIGNATURE_REQUEST/SUBMIT/ERROR',
};

export const actions = {
	profileRequest: () => ({
		type: types.PROFILE_REQUEST,
	}),
	profileSuccess: (payload) => ({
		type: types.PROFILE_SUCCESS,
		payload,
	}),
	profileError: (errors) => ({
		type: types.PROFILE_ERROR,
		errors,
	}),

	profileDataRequest: () => ({
		type: types.PROFILE_DATA_REQUEST,
	}),
	profileDataSuccess: (payload) => ({
		type: types.PROFILE_DATA_SUCCESS,
		payload,
	}),
	profileDataError: (errors) => ({
		type: types.PROFILE_DATA_ERROR,
		errors,
	}),

    submitResetPassword: (payload) => ({
        type: types.SUBMITTING_RESET_PASSWORD_REQUEST,
        payload,
    }),
    submitResetPasswordSuccess: (payload) => ({
        type: types.SUBMITTING_RESET_PASSWORD_REQUEST_SUCCESS,
        payload,
    }),
    submitResetPasswordError: (errors) => ({
        type: types.SUBMITTING_RESET_PASSWORD_REQUEST_ERROR,
        errors,
    }),

    submitUpdateSignature: (payload) => ({
        type: types.UPDATE_SIGNATURE_REQUEST,
        payload,
    }),
    submitUpdateSignatureSuccess: (payload) => ({
        type: types.UPDATE_SIGNATURE_REQUEST_SUCCESS,
        payload,
    }),
    submitUpdateSignatureError: (errors) => ({
        type: types.UPDATE_SIGNATURE_REQUEST_ERROR,
        errors,
    }),
};

// REDUCER
export default function profileReducer(state = DEFAULT_STATE, action) {
	switch (action.type) {
		case types.PROFILE_REQUEST:
		case types.PROFILE_DATA_REQUEST: {
			return {
				...state,
				submitting: true,
				errors: null,
			};
		}

		case types.PROFILE_SUCCESS: {
			return {
				...state,
				submitting: false,
				errors: null,
				user: action.payload,
			};
		}

		case types.PROFILE_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
			return {
				...state,
				submitting: false,
				errors: errorData,
			};
		}

		case types.PROFILE_DATA_SUCCESS: {
			return {
				...state,
				submitting: false,
				errors: null,
				profileData: action.payload,
			};
		}

		case types.PROFILE_DATA_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
			return {
				...state,
				submitting: false,
				errors: errorData,
			};
		}

		case types.SUBMITTING_RESET_PASSWORD_REQUEST: {
            return {
                ...state,
                submitting: true,
                errors: null,
                success: null,
                email: action.payload.email,
            }
        }

        case types.SUBMITTING_RESET_PASSWORD_REQUEST_SUCCESS: {
            return {
                ...state,
                submitting: false,
                errors: null,
                success: true,
            }
        }

		case types.UPDATE_SIGNATURE_REQUEST: {
            return {
                ...state,
                submitting: true,
                errors: null,
                success: null,
                signature: action.payload.signature,
            }
        }

        case types.UPDATE_SIGNATURE_REQUEST_SUCCESS: {
            return {
                ...state,
                submitting: false,
                errors: null,
                success: true,
            }
        }

        case types.SUBMITTING_RESET_PASSWORD_REQUEST_ERROR:
        case types.UPDATE_SIGNATURE_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                submitting: false,
                errors: errorData,
                success: false,
            }
        }

		case authTypes.LOGOUT_SUCCESS: {
			return DEFAULT_STATE;
		}

		default: {
			return state;
		}
	}
}


// SELECTORS
export const getIsLoading = (state) => state.profile.submitting;
export const getUser = (state) => state.profile.user;
export const getProfileData = (state) => state.profile.profileData;
export const getErrors = (state) => state.profile.errors;
export const getSuccess = (state) => state.profile.success;
export const getAccountSlug = (state) => state.profile.user.account.slug;
