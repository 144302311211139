import debug from "debug";
import { createAction } from "@reduxjs/toolkit";
import { parseInteractionsServerResponse } from "../../../parsers/interactions";
import { parseInteractionSearchServerResponse } from "../../../parsers/sentiments";

import { getErrorData } from "../../../utils/errorData";

const log = debug("interactionsTimelineReducer");

const INTERACTIONS_TTL_MS = 30 * 60 * 1000; // 30 minutes

const DEFAULT_STATE = {
    errors: null,
    shareErrors: null,
    loading: false,
    success: null,
    shareSuccess: null,
    interactions: [] || {},
    interactionsExpireAt: null, // timestamp for then interactions are considered expired
    searchDetails: null, // saved search details
};

export const actions = {
    clearState: createAction("INTERACTIONS_TIMELINE/CLEAR_STATE"),

    requestInteractionsResults: createAction("INTERACTIONS_TIMELINE/RESULTS/REQUEST"),
    requestInteractionsResultsSuccess: createAction("INTERACTIONS_TIMELINE/RESULTS/REQUEST/SUCCESS"),
    requestInteractionsResultsError: createAction("INTERACTIONS_TIMELINE/RESULTS/REQUEST/ERROR"),

    saveInteractionSearch: createAction("INTERACTIONS_TIMELINE/SAVE_SEARCH/REQUEST"),
    saveInteractionSearchSuccess: createAction("INTERACTIONS_TIMELINE/SAVE_SEARCH/REQUEST/SUCCESS"),
    saveInteractionSearchError: createAction("INTERACTIONS_TIMELINE/SAVE_SEARCH/REQUEST/ERROR"),

    shareSavedInteractionSearch: createAction("INTERACTIONS_TIMELINE/SHARE_SAVED_SEARCH/REQUEST"),
    shareSavedInteractionSearchSuccess: createAction("INTERACTIONS_TIMELINE/SHARE_SAVED_SEARCH/REQUEST/SUCCESS"),
    shareSavedInteractionSearchError: createAction("INTERACTIONS_TIMELINE/SHARE_SAVED_SEARCH/REQUEST/ERROR"),

    requestSavedInteractionSearch: createAction("INTERACTIONS_TIMELINE/SAVE_SEARCH/DETAILS/REQUEST"),
    requestSavedInteractionSearchSuccess: createAction("INTERACTIONS_TIMELINE/SAVE_SEARCH/DETAILS/REQUEST/SUCCESS"),
    requestSavedInteractionSearchError: createAction("INTERACTIONS_TIMELINE/SAVE_SEARCH/DETAILS/REQUEST/ERROR"),
};

export default function interactionsTimelineReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return {
                ...DEFAULT_STATE,
            };
        }

        case actions.saveInteractionSearch.type: {
            return {
                ...state,
                errors: null,
                loading: true,
                success: null,
            };
        }

        case actions.shareSavedInteractionSearch.type: {
            return {
                ...state,
                shareErrors: null,
                loading: true,
                shareSuccess: null,
            };
        }

        case actions.requestSavedInteractionSearch.type:
        case actions.requestInteractionsResults.type: {
            return {
                ...state,
                errors: null,
                loading: true,
            };
        }

        case actions.requestSavedInteractionSearchSuccess.type: {
            return {
                ...state,
                errors: null,
                loading: false,
                searchDetails: parseInteractionSearchServerResponse(action.payload),
            };
        }

        case actions.saveInteractionSearchSuccess.type: {
            return {
                ...state,
                errors: null,
                loading: false,
                searchDetails: parseInteractionSearchServerResponse(action.payload),
                success: true,
            };
        }

        case actions.requestInteractionsResultsSuccess.type: {
            let interactionsResp;
            if (Array.isArray(action.payload)) {
                interactionsResp = parseInteractionsServerResponse(action.payload);
            } else {
                interactionsResp = {};
                Object.entries(action.payload).forEach(([key, interactions]) => {
                    interactionsResp[key] = parseInteractionsServerResponse(interactions);
                });
            }

            return {
                ...state,
                errors: null,
                loading: false,
                interactions: interactionsResp,
                interactionsExpireAt: Date.now() + INTERACTIONS_TTL_MS,
            };
        }

        case actions.shareSavedInteractionSearchSuccess.type: {
            return {
                ...state,
                shareErrors: null,
                loading: false,
                shareSuccess: true,
            };
        }

        case actions.saveInteractionSearchError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
                success: false,
            };
        }

        case actions.shareSavedInteractionSearchError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                shareErrors: getErrorData(action.payload),
                shareSuccess: false,
            };
        }

        case actions.requestSavedInteractionSearchError.type:
        case actions.requestInteractionsResultsError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => state.interactionsTimeline.loading;
export const getErrors = (state) => state.interactionsTimeline.errors;
export const getShareErrors = (state) => state.interactionsTimeline.shareErrors;
export const getSuccess = (state) => state.interactionsTimeline.success;
export const getShareSuccess = (state) => state.interactionsTimeline.shareSuccess;
export const getInteractions = (state) => {
    const now = Date.now();
    if (state.interactionsTimeline.interactionsExpireAt < now) {
        // consider interactions expired
        log("interactions expired", now, state.interactionsTimeline.interactionsExpireAt);
        return [];
    } else {
        return state.interactionsTimeline.interactions;
    }
};
export const getSearchDetails = (state) => state.interactionsTimeline.searchDetails;
// this component store has been persisted explicitly
export const getInteractionsTimeline = (state, key) => {
    const now = Date.now();
    if (state.interactionsTimeline.interactionsExpireAt < now) {
        // consider interactions expired
        log("interactions expired", now, state.interactionsTimeline.interactionsExpireAt);
        return [];
    }
    if (Array.isArray(state.interactionsTimeline.interactions)) {
        return state.interactionsTimeline.interactions;
    } else {
        return state.interactionsTimeline.interactions[key];
    }
};
