import debug from "debug";
import {actions} from "../reducers/auth";
import {call, put} from 'redux-saga/effects';

const log = debug("utils");

export function* makeAuthenticatedCall(fn, ...args) {
    const response = yield call(fn, ...args);
    switch (response.statusCode) {
        case 401: {
            yield put(actions.logoutSuccess());
            return response;
        }

        default: {
            return response;
        }
    }
}

export function* makeRequest(successActionFn, errorActionFn, requesterFn, ...requestArgs) {
    const {success, response, error, statusCode} = yield makeAuthenticatedCall(requesterFn, ...requestArgs);
    log('got response', success, response, error, statusCode);
    if (success) {
        log('api call ok', response);
        yield put(successActionFn(response));
    } else if (statusCode ===  401) {
        log("token expired. exiting", response);
    } else {
        log('api call bad', error);
        yield put(errorActionFn(error));
    }
}
