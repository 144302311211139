import {connect} from 'react-redux';

import PrivateRoute from "../components/Authentication/PrivateRoute";
import {getAuthenticated} from "../reducers/auth";
import debug from "debug";

const log = debug("PrivateRoute");

const mapStateToProps = (state) => ({
    authenticated: getAuthenticated(state),
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...props,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
