export const routes = {
    root: "/",
    newsitems: {
        new: "/:account/newsitems/new",
        main: "/newsitems",
    },
    dashboard: {
        main: "/dashboard",
    },
    accounts: {
        main: "/accounts",
    },
    customers: {
        main: "/customers",
        customerDetails: "/customers/:customer",
        contacts: "/customers/:customer/contacts",
    },
    campaigns: {
        main: "/campaigns",
        new: "/campaigns/new",
        edit: "/campaigns/:campaign/edit",
        execute: "/campaigns/:campaign/execute",
        customerContacts: "/campaigns/:campaign/:customer/contacts",
        view: "/campaigns/:campaign/view",
        report: "/campaigns/:campaign/report",
    },
    messages: {
        main: "/messages",
        edit: "/messages/:customer/:contact/:message/edit",
        view: "/messages/:customer/:contact/:message/view",
    },
    emailfeedback: {
        main: "/emailfeedback",
    },
    feedback: {
        main: "/feedback",
    },
    interactions: {
        view: "/interactions/:interaction/view",
        main: "/interactions",
        timeline: "/interactions/timeline",
    },
    reports: {
        main: "/reports",
        csminteractions: "/reports/csminteractions",
        csmcustomers: "/reports/csmcustomers",
        vpcscustomers: "/reports/vpcscustomers",
        ceoreport: "/reports/ceoreport",
        webhose: {
            csminteractions_01: "/reports/webhose/csminteractions_01",
            csminteractions_02: "/reports/webhose/csminteractions_02",
            csmcustomers: "/reports/webhose/csmcustomers",
            vpcscustomers: "/reports/webhose/vpcscustomers",
        },
        blameless: {
            csminteractions_01: "/reports/blameless/csminteractions_01",
            csminteractions_02: "/reports/blameless/csminteractions_02",
            csminteractions_03: "/reports/blameless/csminteractions_03",
            csminteractions_04: "/reports/blameless/csminteractions_04",
            csmcustomers: "/reports/blameless/csmcustomers",
            vpcscustomers: "/reports/blameless/vpcscustomers",
        },
    },
    profile: {
        main: "/profile",
    },
    salesforce: {
        main: "/salesforcelanding",
    },
    zendesk: {
        main: "/zendesklanding",
    },
    password: {
        reset: "/password/reset/:uid/:token",
    },
};
