import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('EmailFeedback');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    feedbackData: {},
    items: [],
    overall_note: '',
};

// ACTION TYPES
export const types = {
    FEEDBACK_DATA_REQUEST: 'FEEDBACK_DATA/REQUEST',
    FEEDBACK_DATA_REQUEST_SUCCESS: 'FEEDBACK_DATA/REQUEST/SUCCESS',
    FEEDBACK_DATA_REQUEST_ERROR: 'FEEDBACK_DATA/REQUEST/ERROR',

    SUBMIT_FEEDBACK_REQUEST: 'SUBMIT_FEEDBACK/REQUEST',
    SUBMIT_FEEDBACK_REQUEST_SUCCESS: 'SUBMIT_FEEDBACK/REQUEST/SUCCESS',
    SUBMIT_FEEDBACK_REQUEST_ERROR: 'SUBMIT_FEEDBACK/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    getFeedbackData: (message) => ({
        type: types.FEEDBACK_DATA_REQUEST,
        message: message,
    }),
    getFeedbackDataSuccess: (payload) => ({
        type: types.FEEDBACK_DATA_REQUEST_SUCCESS,
        payload,
    }),
    getFeedbackDataError: (errors) => ({
        type: types.FEEDBACK_DATA_REQUEST_ERROR,
        errors,
    }),

    submitFeedback: (payload, message) => ({
        type: types.SUBMIT_FEEDBACK_REQUEST,
        payload: payload,
        message: message,
    }),
    submitFeedbackSuccess: (payload) => ({
        type: types.SUBMIT_FEEDBACK_REQUEST_SUCCESS,
        payload,
    }),
    submitFeedbackError: (errors) => ({
        type: types.SUBMIT_FEEDBACK_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function emailFeedbackReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.FEEDBACK_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.FEEDBACK_DATA_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                feedbackData: action.payload,
            }
        }

        case types.FEEDBACK_DATA_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.SUBMIT_FEEDBACK_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
                payload: action.payload,
            }
        }

        case types.SUBMIT_FEEDBACK_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.SUBMIT_FEEDBACK_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.emailFeedback.loading;
export const getErrors = (state) => state.emailFeedback.errors;
export const getSuccess = (state) => state.emailFeedback.success;
export const getFeedbackData = (state) => state.emailFeedback.feedbackData;
