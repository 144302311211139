import React from "react";
import { ConnectedRouter } from "connected-react-router";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import debug from "debug";

import { getHistory } from "../utils/history";
import { routes } from "../constants/routes";
import RouteLoader from "../components/RouteLoader/RouteLoader";
import PrivateRoute from "../containers/PrivateRoute";
import AuthLayout from "../components/Layout/AuthLayout";
import DefaultLayout from "../components/Layout/DefaultLayout";
import { actions as authActions, getAuthenticated } from "../reducers/auth";
import { getUser } from "../reducers/profile";
import Login from "../pages/Login/Login";

const log = debug("Router");

const mapStateToProps = (state) => ({
    authenticated: getAuthenticated(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...props,
        logout: () => {
            log("logging user out");
            dispatch(authActions.requestLogout());
        },
        loginFromToken: (token) => {
            log("logging in user automatically");
            dispatch(authActions.loginSuccess({ token }));
        },
    };
};

function getAsyncRoute(asyncImportFunc) {
    return Loadable({
        loader: () => asyncImportFunc(),
        loading: RouteLoader,
        timeout: 30000,
    });
}

const AsyncNewNewsItem = getAsyncRoute(() => import("../pages/NewNewsItem/NewNewsItem"));
const AsyncPasswordReset = getAsyncRoute(() => import("../pages/PasswordReset/PasswordResetPage"));
const AsyncNewsItems = getAsyncRoute(() => import("../pages/NewsItems/NewsItemsPage"));
const AsyncCustomers = getAsyncRoute(() => import("../pages/Customers/CustomersList/page"));
const AsyncCustomerDetails = getAsyncRoute(() => import("../pages/Customers/CustomerDetails/page"));
const AsyncCustomerContacts = getAsyncRoute(() => import("../pages/Customers/CustomerContacts/page"));
const AsyncCampaigns = getAsyncRoute(() => import("../pages/Campaigns/CampaignsPage"));
const AsyncNewCampaign = getAsyncRoute(() => import("../pages/Campaigns/NewCampaignPage"));
const AsyncEditCampaign = getAsyncRoute(() => import("../pages/Campaigns/EditCampaignPage"));
const AsyncCampaignExecute = getAsyncRoute(() => import("../pages/Campaigns/CampaignExecutePage"));
const AsyncCampaignExecuteContacts = getAsyncRoute(() => import("../pages/Campaigns/CampaignExecuteContactsPage"));
const AsyncCampaignView = getAsyncRoute(() => import("../pages/Campaigns/CampaignViewPage"));
const AsyncCampaignReport = getAsyncRoute(() => import("../pages/Campaigns/CampaignReportPage"));
const AsyncMessages = getAsyncRoute(() => import("../pages/Messages/ContactMessagesPage"));
const AsyncContactMessageEdit = getAsyncRoute(() => import("../pages/Messages/ContactMessageEditPage"));
const AsyncContactMessageView = getAsyncRoute(() => import("../pages/Messages/ContactMessageViewPage"));
const AsyncEmailFeedback = getAsyncRoute(() => import("../pages/EmailFeedback/EmailFeedbackPage"));
const AsyncInteractionDetails = getAsyncRoute(() => import("../pages/Interactions/InteractionDetails/page"));
const AsyncInteractionsSearch = getAsyncRoute(() => import("../pages/Interactions/InteractionsSearch/page"));
const AsyncInteractionsTimeline = getAsyncRoute(() => import("../pages/Interactions/InteractionsTimeline/page"));
const AsyncFeedback = getAsyncRoute(() => import("../pages/Feedback/FeedbackPage"));
const AsyncReports = getAsyncRoute(() => import("../pages/Reports/CustomerAnalytics/page"));
const AsyncProfile = getAsyncRoute(() => import("../pages/Profile/ProfilePage"));
const AsyncSalesForceLanding = getAsyncRoute(() => import("../pages/SalesForce/SalesForceLandingPage"));
const AsyncZendeskLanding = getAsyncRoute(() => import("../pages/Zendesk/ZendeskLandingPage"));
const AsyncCSMInteractions = getAsyncRoute(() => import("../pages/Reports/StaticMockups/CSMInteractionsPage"));
const AsyncCSMCustomers = getAsyncRoute(() => import("../pages/Reports/StaticMockups/CSMCustomersPage"));
const AsyncVPCSCustomers = getAsyncRoute(() => import("../pages/Reports/StaticMockups/VPCSCustomersPage"));
const AsyncCEOReport = getAsyncRoute(() => import("../pages/Reports/StaticMockups/CEOReportPage"));
const AsyncWebhoseCSMInteractions01 = getAsyncRoute(() =>
    import("../pages/Reports/StaticMockups/Webhose/WebhoseCSMInteractions01Page")
);
const AsyncWebhoseCSMInteractions02 = getAsyncRoute(() =>
    import("../pages/Reports/StaticMockups/Webhose/WebhoseCSMInteractions02Page")
);
const AsyncWebhoseCSMCustomers = getAsyncRoute(() => import("../pages/Reports/StaticMockups/Webhose/WebhoseCSMCustomersPage"));
const AsyncWebhoseVPCSCustomers = getAsyncRoute(() => import("../pages/Reports/StaticMockups/Webhose/WebhoseVPCSCustomersPage"));
const AsyncBlamelessCSMInteractions01 = getAsyncRoute(() =>
    import("../pages/Reports/StaticMockups/Blameless/BlamelessCSMInteractions01Page")
);
const AsyncBlamelessCSMInteractions02 = getAsyncRoute(() =>
    import("../pages/Reports/StaticMockups/Blameless/BlamelessCSMInteractions02Page")
);
const AsyncBlamelessCSMInteractions03 = getAsyncRoute(() =>
    import("../pages/Reports/StaticMockups/Blameless/BlamelessCSMInteractions03Page")
);
const AsyncBlamelessCSMInteractions04 = getAsyncRoute(() =>
    import("../pages/Reports/StaticMockups/Blameless/BlamelessCSMInteractions04Page")
);
const AsyncBlamelessCSMCustomers = getAsyncRoute(() => import("../pages/Reports/StaticMockups/Blameless/BlamelessCSMCustomersPage"));
const AsyncBlamelessVPCSCustomers = getAsyncRoute(() =>
    import("../pages/Reports/StaticMockups/Blameless/BlamelessVPCSCustomersPage")
);
const AsyncError = getAsyncRoute(() => import("../pages/Error/FourOFour"));

class Router extends React.Component {
    render() {
        log("router props are", this.props);
        const { authenticated, user } = this.props;
        const history = getHistory();
        const location = history.location;

        const defaultLayoutOptions = {
            authenticated: authenticated,
            user: user,
            location: location,
            logout: this.props.logout,
            from: location.pathname,
        };

        const authLayoutOptions = {
            location: location,
            from: location.pathname,
        };

        return (
            <ConnectedRouter history={history}>
                <Switch>
                    <Route
                        exact
                        path={routes.newsitems.new}
                        component={AuthLayout(AsyncNewNewsItem, authLayoutOptions)}
                    />
                    <Route exact path={routes.root} render={() => <Login {...authLayoutOptions} />} />
                    <Route
                        exact
                        path={routes.password.reset}
                        component={AuthLayout(AsyncPasswordReset, authLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.newsitems.main}
                        component={DefaultLayout(AsyncNewsItems, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.customers.main}
                        component={DefaultLayout(AsyncCustomers, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.customers.customerDetails}
                        component={DefaultLayout(AsyncCustomerDetails, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.customers.contacts}
                        component={DefaultLayout(AsyncCustomerContacts, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.campaigns.main}
                        component={DefaultLayout(AsyncCampaigns, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.campaigns.new}
                        component={DefaultLayout(AsyncNewCampaign, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.campaigns.edit}
                        component={DefaultLayout(AsyncEditCampaign, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.campaigns.execute}
                        component={DefaultLayout(AsyncCampaignExecute, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.campaigns.customerContacts}
                        component={DefaultLayout(AsyncCampaignExecuteContacts, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.campaigns.view}
                        component={DefaultLayout(AsyncCampaignView, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.campaigns.report}
                        component={DefaultLayout(AsyncCampaignReport, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.messages.main}
                        component={DefaultLayout(AsyncMessages, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.messages.edit}
                        component={DefaultLayout(AsyncContactMessageEdit, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.messages.view}
                        component={DefaultLayout(AsyncContactMessageView, defaultLayoutOptions)}
                    />
                    <Route
                        exact
                        path={routes.emailfeedback.main}
                        component={AuthLayout(AsyncEmailFeedback, authLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.feedback.main}
                        component={DefaultLayout(AsyncFeedback, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.interactions.main}
                        component={DefaultLayout(AsyncInteractionsSearch, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.interactions.timeline}
                        component={DefaultLayout(AsyncInteractionsTimeline, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.interactions.view}
                        component={DefaultLayout(AsyncInteractionDetails, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.main}
                        component={DefaultLayout(AsyncReports, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.profile.main}
                        component={DefaultLayout(AsyncProfile, defaultLayoutOptions)}
                    />
                    <PrivateRoute exact path={routes.salesforce.main} component={AuthLayout(AsyncSalesForceLanding)} />
                    <PrivateRoute exact path={routes.zendesk.main} component={AuthLayout(AsyncZendeskLanding)} />
                    <PrivateRoute
                        exact
                        path={routes.reports.csminteractions}
                        component={DefaultLayout(AsyncCSMInteractions, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.csmcustomers}
                        component={DefaultLayout(AsyncCSMCustomers, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.vpcscustomers}
                        component={DefaultLayout(AsyncVPCSCustomers, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.ceoreport}
                        component={DefaultLayout(AsyncCEOReport, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.webhose.csminteractions_01}
                        component={DefaultLayout(AsyncWebhoseCSMInteractions01, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.webhose.csminteractions_02}
                        component={DefaultLayout(AsyncWebhoseCSMInteractions02, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.webhose.csmcustomers}
                        component={DefaultLayout(AsyncWebhoseCSMCustomers, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.webhose.vpcscustomers}
                        component={DefaultLayout(AsyncWebhoseVPCSCustomers, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.blameless.csminteractions_01}
                        component={DefaultLayout(AsyncBlamelessCSMInteractions01, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.blameless.csminteractions_02}
                        component={DefaultLayout(AsyncBlamelessCSMInteractions02, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.blameless.csminteractions_03}
                        component={DefaultLayout(AsyncBlamelessCSMInteractions03, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.blameless.csminteractions_04}
                        component={DefaultLayout(AsyncBlamelessCSMInteractions04, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.blameless.csmcustomers}
                        component={DefaultLayout(AsyncBlamelessCSMCustomers, defaultLayoutOptions)}
                    />
                    <PrivateRoute
                        exact
                        path={routes.reports.blameless.vpcscustomers}
                        component={DefaultLayout(AsyncBlamelessVPCSCustomers, defaultLayoutOptions)}
                    />
                    <Route component={AuthLayout(AsyncError)} />
                </Switch>
            </ConnectedRouter>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
