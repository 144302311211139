import React from "react";
import debug from "debug";

import "./AuthLayout.css";
import {getHistory} from "../../utils/history";


const log = debug("AuthLayout");

const AuthLayout = (WrappedComponent) => (props) => {
	let wrapperClassNames = ['container-fluid', 'content-wrapper', 'no-submenu', 'auth-layout'];
	log("props are", props);
	return [
		<div key="content-wrapper" className={wrapperClassNames.join(' ')}>
			<WrappedComponent key="content" {...props} location={getHistory().location}/>
		</div>,
	];
};

export default AuthLayout;
