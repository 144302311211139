import debug from "debug";
import {getErrorData} from "../utils/errorData";

const log = debug('customerContactFormReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    customerData: [],
};

// ACTION TYPES
export const types = {
    CUSTOMER_CONTACT_CLEAR_DATA: 'CUSTOMER_CONTACT/CLEAR/DATA',

    CUSTOMER_CONTACT_CONTENT_REQUEST: 'CUSTOMER_CONTACT_CONTENT/REQUEST',
    CUSTOMER_CONTACT_CONTENT_REQUEST_SUCCESS: 'CUSTOMER_CONTACT_CONTENT/REQUEST/SUCCESS',
    CUSTOMER_CONTACT_CONTENT_REQUEST_ERROR: 'CUSTOMER_CONTACT_CONTENT/REQUEST/ERROR',

    SAVING_CUSTOMER_CONTACT: 'CUSTOMER_CONTACT/SAVE',
    SAVING_CUSTOMER_CONTACT_SUCCESS: 'CUSTOMER_CONTACT/SAVE/SUCCESS',
    SAVING_CUSTOMER_CONTACT_ERROR: 'CUSTOMER_CONTACT/SAVE/ERROR',
};

// ACTIONS
export const actions = {
    clearForm: () => ({
        type: types.CUSTOMER_CONTACT_CLEAR_DATA,
    }),

    requestCustomerContactContent: (account) => ({
        type: types.CUSTOMER_CONTACT_CONTENT_REQUEST,
        account: account,
    }),
    requestCustomerContactContentSuccess: (payload) => ({
        type: types.CUSTOMER_CONTACT_CONTENT_REQUEST_SUCCESS,
        payload,
    }),
    requestCustomerContactContentError: (errors) => ({
        type: types.CUSTOMER_CONTACT_CONTENT_REQUEST_ERROR,
        errors,
    }),

    saveCustomerContact: (payload, contact) => ({
        type: types.SAVING_CUSTOMER_CONTACT,
        payload,
        contact,
    }),
    saveCustomerContactSuccess: (payload) => ({
        type: types.SAVING_CUSTOMER_CONTACT_SUCCESS,
        payload,
    }),
    saveCustomerContactError: (errors) => ({
        type: types.SAVING_CUSTOMER_CONTACT_ERROR,
        errors,
    }),
};

// REDUCERS
export default function customerContactFormReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CUSTOMER_CONTACT_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.CUSTOMER_CONTACT_CONTENT_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CUSTOMER_CONTACT_CONTENT_REQUEST_SUCCESS: {
            log('customers are', action.payload.customers);

            action.payload.customers.results.forEach((val, idx) => {
                val.value = val.slug;
                val.label = val.name;
            });

            return {
                ...state,
                loading: false,
                errors: null,
                customerData: action.payload.customers.results,
            }
        }

        case types.CUSTOMER_CONTACT_CONTENT_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.SAVING_CUSTOMER_CONTACT: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case types.SAVING_CUSTOMER_CONTACT_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.SAVING_CUSTOMER_CONTACT_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.customerContactForm.loading;
export const getCustomers = (state) => state.customerContactForm.customerData;
export const getErrors = (state) => state.customerContactForm.errors;
export const getSuccess = (state) => state.customerContactForm.success;
