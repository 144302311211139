import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/accountData";
import {makeRequest} from "./utils";

const log = debug("accountDataSaga");


function getAccountSegmentData(account) {
    log('requesting segments data', account);
    return get(endpoints.accountData.segments.replace(':account', account));
}

function* getSegmentData(action) {
    log('got a request to get segment data', action);
    yield makeRequest(actions.requestSegmentDataSuccess, actions.requestSegmentDataError, getAccountSegmentData, action.account);
}

function getAccountCustomerData({accountSlug, limit}) {
    log('requesting customer data', accountSlug, limit);
    const endpoint = endpoints.accountData.customers.replace(':account', accountSlug);
    if (limit != null)
        return get(endpoint, {limit: limit});
    return get(endpoint)
}

function* getCustomerData(action) {
    log('got a request to get customer data', action);
    yield makeRequest(actions.requestCustomerDataSuccess, actions.requestCustomerDataError, getAccountCustomerData, action.payload)
}

function getAccountContactTypeData(account) {
    log('requesting contact type data', account);
    return get(endpoints.accountData.contactTypes.replace(':account', account));
}

function* getContactTypeData(action) {
    log('got a request to get contact type data', action);
    yield makeRequest(actions.requestContactTypeDataSuccess, actions.requestContactTypeDataError, getAccountContactTypeData, action.account);
}

function getAccountClassificationData() {
    log('requesting classification data');
    return get(endpoints.accountData.classifications);
}

function* getClassificationData(action) {
    log('got a request to get classification data', action);
    yield makeRequest(actions.requestClassificationDataSuccess, actions.requestClassificationDataError, getAccountClassificationData);
}

function getAccountGoalData() {
    log('requesting goal data');
    return get(endpoints.accountData.goals);
}

function* getGoalData(action) {
    log('got a request to get goal data', action);
    yield makeRequest(actions.requestGoalDataSuccess, actions.requestGoalDataError, getAccountGoalData)
}

function getAccountManagerData() {
    log('requesting manager data');
    return get(endpoints.accountData.managers);
}

function* getMangerData(action) {
    log('got a request to get manager data', action);
    yield makeRequest(actions.requestManagerDataSuccess, actions.requestManagerDataError, getAccountManagerData)
}


// WATCHERS
export function* watchRequestForSegmentData() {
    yield takeEvery(types.SEGMENT_DATA_REQUEST, getSegmentData);
}

export function* watchRequestForCustomerData() {
    yield takeEvery(types.CUSTOMER_DATA_REQUEST, getCustomerData);
}

export function* watchRequestForContactTypeData() {
    yield takeEvery(types.CONTACT_TYPE_DATA_REQUEST, getContactTypeData);
}

export function* watchRequestForClassificationData() {
    yield takeEvery(types.CLASSIFICATION_DATA_REQUEST, getClassificationData);
}

export function* watchRequestForGoalData() {
    yield takeEvery(types.GOAL_DATA_REQUEST, getGoalData);
}

export function* watchRequestForManagerData() {
    yield takeEvery(types.MANAGER_DATA_REQUEST, getMangerData);
}
