import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { fetchInteractions, getSavedSearch, saveInteractionSearch, shareSavedSearch } from "./api";
import { actions } from "./reducer";

const log = debug("InteractionsTimelineSaga");

function* fetchInteractionsData(action) {
    log("got request to fetch interactions", action);
    yield makeRequest(
        actions.requestInteractionsResultsSuccess,
        actions.requestInteractionsResultsError,
        fetchInteractions,
        action.payload
    );
}

function* requestToSaveInteractionsSearch(action) {
    log("got request to save interactions search", action);
    yield makeRequest(
        actions.saveInteractionSearchSuccess,
        actions.saveInteractionSearchError,
        saveInteractionSearch,
        action.payload
    );
}

function* fetchSavedSearch(action) {
    log("got request to fetch saved search", action);
    yield makeRequest(
        actions.requestSavedInteractionSearchSuccess,
        actions.requestSavedInteractionSearchError,
        getSavedSearch,
        action.payload
    );
}

function* requestToShareSavedSearch(action) {
    log("got request to share saved search", action);
    yield makeRequest(
        actions.shareSavedInteractionSearchSuccess,
        actions.shareSavedInteractionSearchError,
        shareSavedSearch,
        action.payload
    );
}

const sagas = [
    takeLatest(actions.requestInteractionsResults.type, fetchInteractionsData),
    takeLatest(actions.saveInteractionSearch.type, requestToSaveInteractionsSearch),
    takeLatest(actions.requestSavedInteractionSearch.type, fetchSavedSearch),
    takeLatest(actions.shareSavedInteractionSearch.type, requestToShareSavedSearch),
];
export default sagas;
