import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import debug from "debug";

import './Header.css';
import NewsItemForm from "../../../containers/NewsItemForm";
import NewsItemFormCustomers from "../../../containers/NewsItemFormCustomers";

const log = debug("Header");


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewsItemModal: false,
            showNewsItemUserModal: false,
        };

        this.handleShowNewsItemModal = this.handleShowNewsItemModal.bind(this);
        this.handleCloseNewsItemModal = this.handleCloseNewsItemModal.bind(this);

        this.handleShowNewsItemUserModal = this.handleShowNewsItemUserModal.bind(this);
        this.handleCloseNewsItemUserModal = this.handleCloseNewsItemUserModal.bind(this);
    }

    handleShowNewsItemModal() {
        this.setState({
            showNewsItemModal: true,
        });
    };

    handleCloseNewsItemModal() {
        this.setState({
            showNewsItemModal: false,
        });
    };

    handleShowNewsItemUserModal() {
        this.setState({
            showNewsItemUserModal: true,
        });
    };

    handleCloseNewsItemUserModal() {
        this.setState({
            showNewsItemUserModal: false,
        });
    };

    render() {
        log('location is', this.props.pagePath);
        log('props are', this.props);

        return (
            <header>
                <Navbar fixed="top" variant="light" bg="light" expand="md" className="p-0 shadow">
                    <div className="col-md-2 mr-0">
                        <Navbar.Brand>
                            <Link to='/newsitems'>
                                <img
                                    alt="questomer"
                                    className="logo-main"
                                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                                />
                                <span>Questomer</span>
                            </Link>
                        </Navbar.Brand>
                    </div>
                    <Nav activeKey={window.location.pathname} className="ml-md-auto px-3">
                        <button type="button" className="btn btn-secondary news-item-btn" onClick={this.handleShowNewsItemUserModal}>+ My own news item</button>
                        <button type="button" className="btn btn-primary news-item-btn" onClick={this.handleShowNewsItemModal}>+ Team news item</button>
                    </Nav>
                </Navbar>
                <Modal
                    size="lg"
                    aria-labelledby="news-item-modal"
                    centered
                    show={this.state.showNewsItemModal}
                    onHide={this.handleCloseNewsItemModal} >
                    <Modal.Header closeButton>
                        <Modal.Title id="news-item-modal">
                            What's new?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <NewsItemForm pagePath={this.props.pagePath} account={this.props.user.account.slug}/>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="lg"
                    aria-labelledby="news-item-user-modal"
                    centered
                    show={this.state.showNewsItemUserModal}
                    onHide={this.handleCloseNewsItemUserModal} >
                    <Modal.Header closeButton>
                        <Modal.Title id="news-item-modal">
                            What's new for your customers?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <NewsItemFormCustomers pagePath={this.props.pagePath} account={this.props.user.account.slug} customer={this.props.computedMatch.params.customer}/>
                    </Modal.Body>
                </Modal>
            </header>
        );
    }
}


export default Header;
