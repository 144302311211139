import { connect } from "react-redux";
import debug from "debug";

import {
    actions,
    getAuthenticated,
    getErrors,
    getResponse,
    getIsLoading,
    getSuccess,
    getPasswordResetSuccess,
} from "../reducers/auth";
import {
    actions as profileActions,
    getIsLoading as getProfileLoading,
    getErrors as getProfileErrors,
    getUser,
} from "../reducers/profile";
import LoginForm from "../components/Authentication/LoginForm/LoginForm";

const log = debug("LoginForm");

const mapStateToProps = (state) => ({
    authenticated: getAuthenticated(state),
    isLoading: getIsLoading(state) || getProfileLoading(state),
    success: getSuccess(state),
    passwordResetSuccess: getPasswordResetSuccess(state),
    response: getResponse(state),
    errors: getErrors(state) || getProfileErrors(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch, props) => {
	log("login form props", props);
	return {
		onFormSubmit: (values) => dispatch(actions.requestLogin(values)),

        onResetPasswordSubmit: (values) => dispatch(actions.submitPasswordReset(values)),

        getProfileData: () => dispatch(profileActions.profileRequest()),

        loginFromToken: (token) => dispatch(actions.loginSuccess({ token })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
