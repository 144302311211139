import { all } from "redux-saga/effects";
import {
    watchStartGoogleRequest,
    watchStartSalesForceRequest,
    watchStartZendeskRequest,
    watchRequestLoginRequest,
    watchRequestLogoutRequest,
    watchRequestResetPasswordRequest,
} from "./auth";
import { watchRequestAddContacts, watchRequestContactsData } from "./campaignAddRecipients";
import { watchRequestSubmitSetNewPassword } from "./passwordReset";
import { watchRequestSaveNewNewsItem } from "./newsItemForm";
import { watchRequestSaveNewNewsItemCustomers } from "./newsItemFormCustomers";
import {
    watchRequestForSegmentData,
    watchRequestForCustomerData,
    watchRequestForContactTypeData,
    watchRequestForClassificationData,
    watchRequestForGoalData,
    watchRequestForManagerData,
} from "./accountData";
import {
    watchRequestForNewsItems,
    watchRequestForNewsItemsRefresh,
    watchRequestForNewsItemsTags,
    watchRequestForUpdateNewsItem,
} from "./newsItems";
import { watchRequestForCustomers, watchRequestForCustomersRefresh } from "./customers";
import { watchRequestForCustomerContactContent, watchRequestForCreateCustomerContact } from "./customerContactForm";
import {
    watchRequestForContactNewsItems,
    watchRequestForContactNewsItemsRefresh,
    watchRequestForAddSelectedNewsItems,
} from "./contactAddNewsItems";
import {
    watchRequestForProfile,
    watchRequestForProfileData,
    watchRequestForResetPassword,
    watchRequestForUpdateSignature,
} from "./profile";
import {
    watchRequestSaveContactMessage,
    watchRequestGetInitialContent,
    watchRequestGetContactMessageContent,
    watchRequestContactMessageUndo,
} from "./contactMessageEdit";
import { watchRequestGetSentContactMessageContent } from "./contactMessageView";
import { watchRequestForContactMessages, watchRequestForContactMessagesRefresh } from "./contactMessages";
import { watchRequestGetEmailFeedbackData, watchRequestSubmitEmailFeedback } from "./emailFeedback";
import { watchRequestForFeedbacks, watchRequestForFeedbacksRefresh } from "./feedbacks";
import {
    watchRequestForCampaigns,
    watchRequestForCampaignsRefresh,
    watchRequestForUpdateCampaignActive,
    watchRequestForUpdateCampaignSync,
} from "./campaigns";
import {
    watchRequestForCampaignNewsItems,
    watchRequestForCampaignNewsItemsRefresh,
    watchRequestForCampaignContacts,
    watchRequestForCampaignContactsRefresh,
    watchRequestSaveCampaign,
    watchRequestSaveCampaignNewsItem,
    watchRequestGetCampaignContent,
} from "./campaignForm";
import { watchRequestExecuteCampaign, watchRequestGetCampaignCustomers } from "./campaignExecute";
import {
    watchRequestGetCampaignCustomerContacts,
    watchRequestGetCampaignCustomerContactsRefresh,
    watchRequestRemoveContacts,
    watchRequestSendCampaignMessage,
} from "./campaignExecuteContacts";
import { watchRequestGetCampaignReport } from "./campaignReport";
import {
    watchRequestCampaignTemplates,
    watchRequestValidateReport,
    watchRequestSubmitMassCampaign,
} from "./massCampaign";
import interactionDetailsSagas from "../pages/Interactions/InteractionDetails/sagas";
import interactionsSearchSagas from "../pages/Interactions/InteractionsSearch/sagas";
import searchParamsSagas from "../pages/Interactions/SearchParams/sagas";
import interactionsTimelineSagas from "../pages/Interactions/InteractionsTimeline/sagas";
import customerContactsSagas from "../pages/Customers/CustomerContacts/sagas";
import customerAnalyticsSagas from "../pages/Reports/CustomerAnalytics/sagas";
import savedFiltersSagas from "../pages/Reports/SavedFilters/sagas";
import customersListSagas from "../pages/Customers/CustomersList/sagas";
import customerDetailsSagas from "../pages/Customers/CustomerDetails/sagas";
import customerInsightSagas from "../pages/Customers/CustomerInsights/sagas";

export default function* rootSaga() {
    yield all([
        watchRequestAddContacts(),
        watchRequestCampaignTemplates(),
        watchRequestContactMessageUndo(),
        watchRequestContactsData(),
        watchRequestExecuteCampaign(),
        watchRequestForAddSelectedNewsItems(),
        watchRequestForCampaignContacts(),
        watchRequestForCampaignContactsRefresh(),
        watchRequestForCampaignNewsItems(),
        watchRequestForCampaignNewsItemsRefresh(),
        watchRequestForCampaigns(),
        watchRequestForCampaignsRefresh(),
        watchRequestForClassificationData(),
        watchRequestForContactMessages(),
        watchRequestForContactMessagesRefresh(),
        watchRequestForContactNewsItems(),
        watchRequestForContactNewsItemsRefresh(),
        watchRequestForContactTypeData(),
        watchRequestForCreateCustomerContact(),
        watchRequestForCustomerContactContent(),
        watchRequestForCustomerData(),
        watchRequestForCustomers(),
        watchRequestForCustomersRefresh(),
        watchRequestForFeedbacks(),
        watchRequestForFeedbacksRefresh(),
        watchRequestForGoalData(),
        watchRequestForManagerData(),
        watchRequestForNewsItems(),
        watchRequestForNewsItemsRefresh(),
        watchRequestForNewsItemsTags(),
        watchRequestForProfile(),
        watchRequestForProfileData(),
        watchRequestForResetPassword(),
        watchRequestForSegmentData(),
        watchRequestForUpdateCampaignActive(),
        watchRequestForUpdateCampaignSync(),
        watchRequestForUpdateNewsItem(),
        watchRequestForUpdateSignature(),
        watchRequestGetCampaignContent(),
        watchRequestGetCampaignCustomerContacts(),
        watchRequestGetCampaignCustomerContactsRefresh(),
        watchRequestGetCampaignCustomers(),
        watchRequestGetCampaignReport(),
        watchRequestGetContactMessageContent(),
        watchRequestGetEmailFeedbackData(),
        watchRequestGetInitialContent(),
        watchRequestGetSentContactMessageContent(),
        watchRequestLoginRequest(),
        watchRequestLogoutRequest(),
        watchRequestRemoveContacts(),
        watchRequestResetPasswordRequest(),
        watchRequestSaveCampaign(),
        watchRequestSaveCampaignNewsItem(),
        watchRequestSaveContactMessage(),
        watchRequestSaveNewNewsItem(),
        watchRequestSaveNewNewsItemCustomers(),
        watchRequestSendCampaignMessage(),
        watchRequestSubmitEmailFeedback(),
        watchRequestSubmitMassCampaign(),
        watchRequestSubmitSetNewPassword(),
        watchRequestValidateReport(),
        watchStartGoogleRequest(),
        watchStartSalesForceRequest(),
        watchStartZendeskRequest(),
        ...interactionsSearchSagas,
        ...searchParamsSagas,
        ...interactionsTimelineSagas,
        ...interactionDetailsSagas,
        ...customerContactsSagas,
        ...customerAnalyticsSagas,
        ...savedFiltersSagas,
        ...customersListSagas,
        ...customerDetailsSagas,
        ...customerInsightSagas,
    ]);
}
