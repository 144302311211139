import React from "react";
import { Link } from "react-router-dom";
import debug from "debug";

import './Footer.css';

const log = debug("Footer");


class Footer extends React.Component {
    render() {
        log("user", this.props.user);
        const { user } = this.props;

        return (
            <footer className={`row-block ${user.impostor_info ? "impostor-info" : null}`}>
                <div className="container">
                    <div className="row">
                        <section className="col-md-12">
                            {/*<p className="copyright">© Copyright <Link to="/">questomer.com</Link> | All Rights Reserved </p>*/}
                            {user.impostor_info ? <small>Your user <strong>{user.impostor_info.email}</strong> is logged in as <strong>{user.email}</strong></small> : null}
                        </section>
                    </div>
                </div>
            </footer>

        );
    }
}

export default Footer;
