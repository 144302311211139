import React from "react";
import debug from "debug";

import "./DefaultLayout.css";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./Footer/Footer";

const log = debug("DefaultLayout");

const defaultOptions = {
    authenticated: false,
};

const DefaultLayout = (WrappedComponent, options = {}) => (props) => {
    const mergedOptions = Object.assign({}, defaultOptions, options);
    let wrapperClassNames = ["container-fluid", "content-wrapper"];
    if (!mergedOptions.authenticated) {
        wrapperClassNames.push("no-submenu");
    }
    log("props are", props);
    return [
        <Header
            key="header"
            authenticated={mergedOptions.authenticated}
            user={mergedOptions.user}
            pagePath={props.path}
            logout={mergedOptions.logout}
            {...props}
        />,
        <div className={wrapperClassNames.join(" ")} key="content">
            <div className="row">
                <Sidebar key="sidebar" logout={mergedOptions.logout} pagePath={props.path} user={mergedOptions.user} />
                <div className="col-md-9 col-lg-10 ml-sm-auto px-4">
                    <WrappedComponent key="content" user={mergedOptions.user} {...props} />
                </div>
            </div>
        </div>,
        <React.Fragment key="footer">
            {mergedOptions.user && mergedOptions.user.impostor_info ? <Footer user={mergedOptions.user} /> : null}
        </React.Fragment>
    ];
};

export default DefaultLayout;
