import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import PropTypes from "prop-types";
import isEmpty from "lodash-es/isEmpty";
import debug from "debug";

import './Sidebar.css';
import { AVAILABLE_FEATURES } from "../../../constants/accountFeatures";

const log = debug("Sidebar");

function sidebarFeatures(link, key, icon, label) {
    return <Nav.Link href={link} key={key}><i className={icon}/>{label}</Nav.Link>;
}


class Sidebar extends React.Component {
    static propTypes = {
        authenticated: PropTypes.bool,
        user: PropTypes.object,
        pagePath: PropTypes.string,
        logout: PropTypes.func,
    };

    static defaultProps = {
        authenticated: false,
        user: null,
        pagePath: "",
        logout: () => {},
    };

    render() {
        log('location is', this.props.pagePath);
        log('pathname is', window.location.pathname);
        const pathName = `/${this.props.pagePath.split('/')[1]}`;
        log('root path is', pathName);
        log('user is', this.props.user);
        const enabledFeatures = this.props.user && this.props.user.account ? this.props.user.account.enabled_features : {};
        log('enabled features', enabledFeatures);

        let navItems = [];
        if (!isEmpty(enabledFeatures)) {
            AVAILABLE_FEATURES.map((f, _i) => {
                if (enabledFeatures[f.key]) {
                    navItems.push(sidebarFeatures(f.link, f.key, f.icon, f.label));
                }
            });
        }
        log('nav items', navItems);

        return (
            <div className="sidebar-container">
                <Navbar variant="light" bg="light" expand="md" className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
                    <div className="sidebar-sticky">
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse>
                            <Nav activeKey={pathName} className="flex-column">
                                {/*<Nav.Link href="/dashboard"><i className="fas fa-home"/>Dashboard</Nav.Link>
                                <Nav.Link href="/accounts"><i className="fas fa-user-friends"/>Accounts</Nav.Link>*/}
                                {navItems}
                                <Nav.Link href="/profile"><i className="fas fa-user"/>Profile</Nav.Link>
                                <button type="button" className="btn btn-logout nav-link" onClick={this.props.logout} title="Logout"><i className="fas fa-sign-out-alt" />Logout</button>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </div>
        );
    }
}


export default Sidebar;
