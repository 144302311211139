import debug from "debug";
import { createAction } from "@reduxjs/toolkit";

import { getErrorData } from "../../../utils/errorData";
import { parseCustomerDetailsResponse } from "../../../parsers/customersList";
import some from "lodash-es/some";

const log = debug("customerDetailsReducer");

const DEFAULT_STATE = {
    errors: null,
    loading: false,
    customerData: {},
    success: null,
};

export const actions = {
    clearState: createAction("CUSTOMER_DETAILS/CLEAR_DATA"),

    requestCustomerData: createAction("CUSTOMER_DETAILS/REQUEST"),
    requestCustomerDataSuccess: createAction("CUSTOMER_DETAILS/REQUEST/SUCCESS"),
    requestCustomerDataError: createAction("CUSTOMER_DETAILS/REQUEST/ERROR"),

    updateInsightVerdict: createAction("CUSTOMER_INSIGHTS/VERDICT/UPDATE"),
    updateInsightVerdictSuccess: createAction("CUSTOMER_INSIGHTS/VERDICT/UPDATE/SUCCESS"),
    updateInsightVerdictError: createAction("CUSTOMER_INSIGHTS/VERDICT/UPDATE/ERROR"),
};

export default function customerDetailsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.clearState.type: {
            return DEFAULT_STATE;
        }

        case actions.requestCustomerData.type: {
            return {
                ...state,
                errors: null,
                loading: true,
            };
        }

        case actions.updateInsightVerdict.type: {
            return {
                ...state,
                errors: null,
                loading: true,
                success: null,
            };
        }

        case actions.requestCustomerDataSuccess.type: {
            log("customers details are", action.payload);
            return {
                ...state,
                errors: null,
                loading: false,
                customerData: parseCustomerDetailsResponse(action.payload),
            };
        }

        case actions.updateInsightVerdictSuccess.type: {
            return {
                ...state,
                errors: null,
                loading: false,
                success: true,
            };
        }

        case actions.requestCustomerDataError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
            };
        }

        case actions.updateInsightVerdictError.type: {
            log("error is", action.payload);
            return {
                ...state,
                loading: false,
                errors: getErrorData(action.payload),
                success: false,
            };
        }

        default: {
            return state;
        }
    }
}

export const getIsLoading = (state) => some([state.customerDetails.loading], (val) => val === true);
export const getCustomerData = (state) => state.customerDetails.customerData;
export const getErrors = (state) => state.customerDetails.errors;
export const getSuccess = (state) => state.customerDetails.success;
