// @flow

import type {Segment} from "../types/segments";
import {toArray, toSelectOption} from "./utils";

export function parseSegmentServerResponse(segment: Object): Segment {
    return toSelectOption({
        slug: segment.slug,
        name: segment.name,
    });
}

export function parseSegmentsServerResponse(segments: Array<Object>): Array<Segment> {
    return toArray(segments, parseSegmentServerResponse);
}
