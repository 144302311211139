import debug from "debug";
import {takeEvery} from 'redux-saga/effects';

import {types, actions} from "../reducers/contactMessageView";
import {endpoints} from "../constants/api";
import {get} from "../utils/requester";
import {makeRequest} from "./utils";

const log = debug("ContactMessageViewSaga");


function getSentContactMessageContent(message_slug) {
    log('requesting sent contact message', message_slug);
    return get(endpoints.contactMessages.updateView.replace(':cm', message_slug));
}

function* handleGetSentContactMessageContent(action) {
    log('got a request to get sent contact message content', action);
    yield makeRequest(actions.getContactMessageSuccess, actions.getContactMessageError, getSentContactMessageContent, action.message_slug);
}

// WATCHERS
export function* watchRequestGetSentContactMessageContent() {
    yield takeEvery(types.CONTACT_MESSAGE_REQUEST, handleGetSentContactMessageContent);
}
