import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('customersReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    customers: [],
    totalCount: 0,
};

// ACTION TYPES
export const types = {
    CUSTOMERS_DATA_REQUEST: 'CUSTOMERS/REQUEST',
    CUSTOMERS_DATA_REQUEST_SUCCESS: 'CUSTOMERS/REQUEST/SUCCESS',
    CUSTOMERS_DATA_REQUEST_ERROR: 'CUSTOMERS/REQUEST/ERROR',

    CUSTOMERS_DATA_REFRESH_REQUEST: 'CUSTOMERS_REFRESH/REQUEST',
    CUSTOMERS_DATA_REFRESH_REQUEST_SUCCESS: 'CUSTOMERS_REFRESH/REQUEST/SUCCESS',
    CUSTOMERS_DATA_REFRESH_REQUEST_ERROR: 'CUSTOMERS_REFRESH/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    requestCustomers: (account) => ({
        type: types.CUSTOMERS_DATA_REQUEST,
        account: account,
    }),
    requestCustomersSuccess: (payload) => ({
        type: types.CUSTOMERS_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestCustomersError: (errors) => ({
        type: types.CUSTOMERS_DATA_REQUEST_ERROR,
        errors,
    }),

    requestRefreshCustomers: (account, offset, limit) => ({
        type: types.CUSTOMERS_DATA_REFRESH_REQUEST,
        account: account,
        offset: offset,
        limit: limit,
    }),
    requestRefreshCustomersSuccess: (payload) => ({
        type: types.CUSTOMERS_DATA_REFRESH_REQUEST_SUCCESS,
        payload,
    }),
    requestRefreshCustomersError: (errors) => ({
        type: types.CUSTOMERS_DATA_REFRESH_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function customersReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.CUSTOMERS_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CUSTOMERS_DATA_REQUEST_SUCCESS: {
            log('customers are', action.payload);
            let customers = Array.from(state.customers);
            customers = customers.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                customers: customers,
                totalCount: action.payload.count,
            }
        }

        case types.CUSTOMERS_DATA_REQUEST_ERROR:
        case types.CUSTOMERS_DATA_REFRESH_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(action.errors);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case types.CUSTOMERS_DATA_REFRESH_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case types.CUSTOMERS_DATA_REFRESH_REQUEST_SUCCESS: {
            log('customers are', action.payload);
            return {
                ...state,
                loading: false,
                errors: null,
                customers: action.payload.results,
                totalCount: action.payload.count,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.customers.loading;
export const getCustomers = (state) => state.customers.customers;
export const getErrors = (state) => state.customers.errors;
export const getTotal = (state) => state.customers.totalCount;
