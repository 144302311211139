import debug from "debug";

import {getErrorData} from "../utils/errorData";

const log = debug('NewsItemFormReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    account: '',
    status: {},
    title: '',
    description: '',
    link: '',
    segments: [],
    customers: [],
    contactTypes: [],
    deadline: null,
    email: '',
};

// ACTION TYPES
export const types = {
    NEWS_ITEM_CLEAR_DATA: 'NEW_NEWS_ITEM/CLEAR/DATA',

    SUBMITTING_NEWS_ITEM: 'NEW_NEWS_ITEM/SUBMIT',
    SUBMITTING_NEWS_ITEM_SUCCESS: 'NEW_NEWS_ITEM/SUBMIT/SUCCESS',
    SUBMITTING_NEWS_ITEM_ERROR: 'NEW_NEWS_ITEM/SUBMIT/ERROR',
};

// ACTIONS
export const actions = {
    clearForm: () => ({
        type: types.NEWS_ITEM_CLEAR_DATA,
    }),

    saveNewNewsItem: (payload) => ({
        type: types.SUBMITTING_NEWS_ITEM,
        payload,
    }),
    saveNewNewsItemSuccess: (payload) => ({
        type: types.SUBMITTING_NEWS_ITEM_SUCCESS,
        payload,
    }),
    saveNewNewsItemError: (errors) => ({
        type: types.SUBMITTING_NEWS_ITEM_ERROR,
        errors,
    }),
};

// REDUCERS
export default function newNewsItemReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.NEWS_ITEM_CLEAR_DATA: {
            return DEFAULT_STATE;
        }

        case types.SUBMITTING_NEWS_ITEM: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case types.SUBMITTING_NEWS_ITEM_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case types.SUBMITTING_NEWS_ITEM_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loading: false,
                errors: errorData,
                success: false,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.newsItemForm.loading;
export const getErrors = (state) => state.newsItemForm.errors;
export const getSuccess = (state) => state.newsItemForm.success;
