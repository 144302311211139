import debug from "debug";
import some from "lodash-es/some";
import {parseCustomersSimpleServerResponse} from "../parsers/customers";
import {parseSegmentsServerResponse} from "../parsers/segments";

import {getErrorData} from "../utils/errorData";

const log = debug('accountDataReducer');

// INITIAL REDUX STORAGE STATE
export const DEFAULT_STATE = {
    loading: false,
    loadingSegmentsData: false,
    loadingContactTypes: false,
    loadingCustomerData: false,
    loadingClassificationData: false,
    loadingGoalData: false,
    loadingManagersData: false,
    errors: null,
    segmentData: [],
    customerData: [],
    contactTypeData: [],
    classifications: [],
    goals: [],
    managers: [],
};

// ACTION TYPES
export const types = {
    SEGMENT_DATA_REQUEST: 'SEGMENT_DATA/REQUEST',
    SEGMENT_DATA_REQUEST_SUCCESS: 'SEGMENT/REQUEST/SUCCESS',
    SEGMENT_DATA_REQUEST_ERROR: 'SEGMENT/REQUEST/ERROR',

    CUSTOMER_DATA_REQUEST: 'CUSTOMER_DATA/REQUEST',
    CUSTOMER_DATA_REQUEST_SUCCESS: 'CUSTOMER/REQUEST/SUCCESS',
    CUSTOMER_DATA_REQUEST_ERROR: 'CUSTOMER/REQUEST/ERROR',

    CONTACT_TYPE_DATA_REQUEST: 'CONTACT_TYPE_DATA/REQUEST',
    CONTACT_TYPE_DATA_REQUEST_SUCCESS: 'CONTACT_TYPE/REQUEST/SUCCESS',
    CONTACT_TYPE_DATA_REQUEST_ERROR: 'CONTACT_TYPE/REQUEST/ERROR',

    CLASSIFICATION_DATA_REQUEST: 'CLASSIFICATION_DATA/REQUEST',
    CLASSIFICATION_DATA_REQUEST_SUCCESS: 'CLASSIFICATION_DATA/REQUEST/SUCCESS',
    CLASSIFICATION_DATA_REQUEST_ERROR: 'CLASSIFICATION_DATA/REQUEST/ERROR',

    GOAL_DATA_REQUEST: 'GOAL_DATA/REQUEST',
    GOAL_DATA_REQUEST_SUCCESS: 'GOAL_DATA/REQUEST/SUCCESS',
    GOAL_DATA_REQUEST_ERROR: 'GOAL_DATA/REQUEST/ERROR',

    MANAGER_DATA_REQUEST: 'MANAGER_DATA/REQUEST',
    MANAGER_DATA_REQUEST_SUCCESS: 'MANAGER_DATA/REQUEST/SUCCESS',
    MANAGER_DATA_REQUEST_ERROR: 'MANAGER_DATA/REQUEST/ERROR',
};

// ACTIONS
export const actions = {
    requestSegmentData: (account) => ({
        type: types.SEGMENT_DATA_REQUEST,
        account: account,
    }),
    requestSegmentDataSuccess: (payload) => ({
        type: types.SEGMENT_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestSegmentDataError: (errors) => ({
        type: types.SEGMENT_DATA_REQUEST_ERROR,
        errors,
    }),

    requestCustomerData: (payload) => ({
        type: types.CUSTOMER_DATA_REQUEST,
        payload,
    }),
    requestCustomerDataSuccess: (payload) => ({
        type: types.CUSTOMER_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestCustomerDataError: (errors) => ({
        type: types.CUSTOMER_DATA_REQUEST_ERROR,
        errors,
    }),

    requestContactTypeData: (account) => ({
        type: types.CONTACT_TYPE_DATA_REQUEST,
        account: account,
    }),
    requestContactTypeDataSuccess: (payload) => ({
        type: types.CONTACT_TYPE_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestContactTypeDataError: (errors) => ({
        type: types.CONTACT_TYPE_DATA_REQUEST_ERROR,
        errors,
    }),

    requestClassificationData: () => ({
        type: types.CLASSIFICATION_DATA_REQUEST,
    }),
    requestClassificationDataSuccess: (payload) => ({
        type: types.CLASSIFICATION_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestClassificationDataError: (errors) => ({
        type: types.CLASSIFICATION_DATA_REQUEST_ERROR,
        errors,
    }),

    requestGoalData: () => ({
        type: types.GOAL_DATA_REQUEST,
    }),
    requestGoalDataSuccess: (payload) => ({
        type: types.GOAL_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestGoalDataError: (errors) => ({
        type: types.GOAL_DATA_REQUEST_ERROR,
        errors,
    }),

    requestManagerData: () => ({
        type: types.MANAGER_DATA_REQUEST,
    }),
    requestManagerDataSuccess: (payload) => ({
        type: types.MANAGER_DATA_REQUEST_SUCCESS,
        payload,
    }),
    requestManagerDataError: (errors) => ({
        type: types.MANAGER_DATA_REQUEST_ERROR,
        errors,
    }),
};

// REDUCERS
export default function accountDataReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case types.SEGMENT_DATA_REQUEST: {
            return {
                ...state,
                // loading: true,
                loadingSegmentsData: true,
                errors: null,
            }
        }

        case types.SEGMENT_DATA_REQUEST_SUCCESS: {
            return {
                ...state,
                // loading: state.loading || state.loadingContactTypes,
                loadingSegmentsData: false,
                errors: null,
                segments: parseSegmentsServerResponse(action.payload),
            }
        }

        case types.CUSTOMER_DATA_REQUEST: {
            return {
                ...state,
                loadingCustomerData: true,
                errors: null,
            }
        }

        case types.CUSTOMER_DATA_REQUEST_SUCCESS: {
            return {
                ...state,
                loadingCustomerData: false,
                errors: null,
                customers: parseCustomersSimpleServerResponse(action.payload.results),
            }
        }

        case types.CONTACT_TYPE_DATA_REQUEST: {
            return {
                ...state,
                loadingContactTypes: true,
                errors: null,
            }
        }

        case types.CONTACT_TYPE_DATA_REQUEST_SUCCESS: {
            action.payload.forEach((val, idx) => {
                val.value = val.slug;
                val.label = val.name;
            });

            log('contact type data is', action.payload);
            return {
                ...state,
                loadingContactTypes: false,
                errors: null,
                contactTypes: action.payload,
            }
        }

        case types.CLASSIFICATION_DATA_REQUEST: {
            return {
                ...state,
                loadingClassificationData: true,
                errors: null,
            }
        }

        case types.CLASSIFICATION_DATA_REQUEST_SUCCESS: {
            action.payload.forEach((val, idx) => {
                val.value = val.slug;
                val.label = val.name;
            });

            log('classification data is', action.payload);
            return {
                ...state,
                loadingClassificationData: false,
                errors: null,
                classifications: action.payload,
            }
        }

        case types.GOAL_DATA_REQUEST: {
            return {
                ...state,
                loadingGoalData: true,
                errors: null,
            }
        }

        case types.GOAL_DATA_REQUEST_SUCCESS: {
            action.payload.forEach((val, idx) => {
                val.value = val.slug;
                val.label = val.name;
            });

            log('goal data is', action.payload);
            return {
                ...state,
                loadingGoalData: false,
                errors: null,
                goals: action.payload,
            }
        }

        case types.MANAGER_DATA_REQUEST: {
            return {
                ...state,
                loadingManagersData: true,
                errors: null,
            }
        }

        case types.MANAGER_DATA_REQUEST_SUCCESS: {
            action.payload.forEach((val, idx) => {
                val.value = val.slug;
                val.label = val.name;
            });

            log('manager data is', action.payload);
            return {
                ...state,
                loadingManagersData: false,
                errors: null,
                managers: action.payload,
            }
        }

        case types.SEGMENT_DATA_REQUEST_ERROR:
        case types.CUSTOMER_DATA_REQUEST_ERROR:
        case types.CONTACT_TYPE_DATA_REQUEST_ERROR:
        case types.CLASSIFICATION_DATA_REQUEST_ERROR:
        case types.GOAL_DATA_REQUEST_ERROR:
        case types.MANAGER_DATA_REQUEST_ERROR: {
            log("error is", action);
            const errorData = getErrorData(Object.values(action.errors)[0]);
            return {
                ...state,
                loadingClassificationData: false,
                loadingContactTypes: false,
                loadingCustomerData: false,
                loadingGoalData: false,
                loadingManagersData: false,
                loadingSegmentsData: false,
                errors: errorData,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => some([
    state.accountData.loadingClassificationData,
    state.accountData.loadingContactTypes,
    state.accountData.loadingCustomerData,
    state.accountData.loadingGoalData,
    state.accountData.loadingManagersData,
    state.accountData.loadingSegmentsData
], (flag) => flag === true);
export const getSegments = (state) => state.accountData.segments;
export const getCustomers = (state) => state.accountData.customers;
export const getContactTypes = (state) => state.accountData.contactTypes;
export const getClassifications = (state) => state.accountData.classifications;
export const getGoals = (state) => state.accountData.goals;
export const getManagers = (state) => state.accountData.managers;
export const getErrors = (state) => state.accountData.errors;
