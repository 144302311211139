import isEmpty from "lodash-es/isEmpty";
import React from "react";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import Alert from "react-bootstrap/Alert";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { ReactTinyLink } from "react-tiny-link";
import PropTypes from "prop-types";
import debug from "debug";
import { searchCustomers } from "../Customers/CustomersAsyncSelect/api";

import ErrorsMessage from "../ErrorMessage/ErrorMessage";
import "./NewsItemForm.css";
import { fetchTags } from "./api";

const log = debug("NewsItemForm");

const DEFAULT_STATE = {
    title: "",
    description: "",
    link: "",
    segments: [],
    customers: [],
    contactTypes: [],
    tags: [],
    classifications: [],
    goals: [],
    deadline: "",
    email: "",
    alertVisible: false,
    segmentsCustomers: "segments",
    searchTimeout: null,
    search: "",
};

const accountSegments = (segments) => {
    return segments.map((obj) => {
        obj.checked = false;
        return obj;
    });
};

const getDefaultState = (segments) => {
    return {
        ...DEFAULT_STATE,
        segments: segments,
    };
};

class NewsItemForm extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;

        this.newsItemSubmit = this.newsItemSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onFormClear = this.onFormClear.bind(this);
        this.setDefaultState = this.setDefaultState.bind(this);
        this.handleChangeSegmentsCustomers = this.handleChangeSegmentsCustomers.bind(this);
        this.promiseTagsSearch = this.promiseTagsSearch.bind(this);
        this.promiseCustomerSearch = this.promiseCustomerSearch.bind(this);
    }

    static propTypes = {
        account: PropTypes.string.isRequired,
        classificationData: PropTypes.array,
        clearForm: PropTypes.func.isRequired,
        contactTypeData: PropTypes.array,
        customerData: PropTypes.array,
        errors: PropTypes.array,
        fetchClassificationData: PropTypes.func.isRequired,
        fetchContactTypeData: PropTypes.func.isRequired,
        fetchCustomerData: PropTypes.func.isRequired,
        fetchGoalData: PropTypes.func.isRequired,
        fetchNewsItems: PropTypes.func.isRequired,
        fetchRefreshNewsItems: PropTypes.func.isRequired,
        fetchSegmentData: PropTypes.func.isRequired,
        fetchTagsData: PropTypes.func.isRequired,
        goalData: PropTypes.array,
        isSubmitting: PropTypes.bool.isRequired,
        newsItem: PropTypes.object,
        newsItems: PropTypes.array,
        onFormSubmit: PropTypes.func.isRequired,
        onUpdateSubmit: PropTypes.func.isRequired,
        pagePath: PropTypes.string.isRequired,
        segmentData: PropTypes.array,
        success: PropTypes.bool,
        tagsData: PropTypes.array,
        user: PropTypes.object,
    };

    static defaultProps = {
        pagePath: "",
        account: "",
        onFormSubmit: () => {},
        errors: null,
        success: null,
        clearForm: () => {},
        fetchSegmentData: () => {},
        fetchCustomerData: () => {},
        fetchContactTypeData: () => {},
        fetchClassificationData: () => {},
        fetchGoalData: () => {},
        fetchTagsData: () => {},
        segmentData: [],
        customerData: [],
        contactTypeData: [],
        classificationData: [],
        goalData: [],
        fetchNewsItems: () => {},
        fetchRefreshNewsItems: () => {},
        newsItems: [],
        user: {},
        newsItem: {},
        onUpdateSubmit: () => {},
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.fetchSegmentData(this.props.account);
        this.props.fetchCustomerData(this.props.account);
        this.props.fetchContactTypeData(this.props.account);
        this.props.fetchTagsData(this.props.account);

        if (this.props.newsItem && Object.entries(this.props.newsItem).length !== 0) {
            this.props.fetchClassificationData();
            this.props.fetchGoalData();
        }

        this.setDefaultState(this.props.segmentData, this.props.user);
    }

    componentDidUpdate(prevProps) {
        const { success, newsItem } = this.props;

        if (prevProps.success !== success && success) {
            window.scrollTo(0, 0);

            if (!newsItem || isEmpty(newsItem)) {
                // this.props.clearForm();
                this.setDefaultState(this.props.segmentData, this.props.user);
            }

            if (this.props.user && document.getElementsByClassName("modal-dialog").length !== 0) {
                document.getElementsByClassName("modal-dialog")[0].scrollIntoView();
            }

            this.setState({ alertVisible: true }, () => {
                window.setTimeout(() => {
                    if (this._isMounted) {
                        this.setState({ alertVisible: false });
                    }
                }, 5000);
            });

            if (this.props.user && this.props.pagePath === "/newsitems" && this.props.user.is_cs_lead) {
                this.props.fetchRefreshNewsItems(null, this.props.user.is_cs_lead ? "FOR_REVIEW" : null, 0);
            }
        }

        if (prevProps.segmentData.length === 0 && this.props.segmentData.length !== 0) {
            this.setDefaultState(this.props.segmentData, this.props.user);
        }
    }

    componentWillUnmount() {
        log("component will unmount, clearing news item form");
        this.props.clearForm();
        this._isMounted = false;
    }

    setDefaultState = (segmentData, user) => {
        const { newsItem } = this.props;
        let state = getDefaultState(accountSegments(segmentData));
        if (user) {
            state.email = user.email;
        }

        if (newsItem && !isEmpty(newsItem)) {
            let newsItemSegmentsCustomers = "segments";
            let newsItemSegments;
            let newsItemCustomers = [];
            let newsItemContactTypes = [];
            let newsItemTags = [];
            let newsItemClassifications = [];
            let newsItemGoals = [];

            if (newsItem.segments.length !== 0) {
                newsItemSegments = segmentData.map((s, i) => {
                    if (newsItem.segments.length !== 0) {
                        newsItem.segments.map((ni, j) => {
                            if (s.slug === ni.slug) {
                                s.checked = true;
                            }
                        });
                    } else {
                        s.checked = false;
                    }
                    return s;
                });
            } else if (newsItem.customers.length !== 0) {
                newsItemCustomers = newsItem.customers.map((c, i) => {
                    c.label = c.name;
                    c.value = c.slug;
                    return c;
                });
                newsItemSegments = accountSegments(segmentData);
                newsItemSegmentsCustomers = "customers";
            } else {
                newsItemSegments = accountSegments(segmentData);
            }

            if (newsItem.contact_types.length !== 0) {
                newsItemContactTypes = newsItem.contact_types.map((c, i) => {
                    c.label = c.name;
                    c.value = c.slug;
                    return c;
                });
            }

            if (newsItem.classifications.length !== 0) {
                newsItemClassifications = newsItem.classifications.map((c, i) => {
                    c.label = c.name;
                    c.value = c.slug;
                    return c;
                });
            }

            if (newsItem.goals.length !== 0) {
                newsItemGoals = newsItem.goals.map((c, i) => {
                    c.label = c.name;
                    c.value = c.slug;
                    return c;
                });
            }

            if (!isEmpty(newsItem.tags)) {
                newsItemTags = newsItem.tags.map((tag) => {
                    tag.label = tag.name;
                    tag.value = tag.slug;
                    return tag;
                });
            }

            state = {
                title: newsItem.title || "",
                description: newsItem.description || "",
                link: newsItem.link || "",
                segments: newsItemSegments,
                customers: newsItemCustomers,
                contactTypes: newsItemContactTypes,
                tags: newsItemTags,
                classifications: newsItemClassifications,
                goals: newsItemGoals,
                deadline: newsItem.deadline || "",
                email: newsItem.email || user.email,
                alertVisible: false,
                segmentsCustomers: newsItemSegmentsCustomers,
            };
        }

        this.setState(state);
    };

    newsItemSubmit = (event) => {
        event.preventDefault();

        const selectedSegments = this.state.segments
            .filter((s) => s.checked)
            .map((s, i) => {
                if (s.checked) {
                    return s.value;
                }
            });

        const selectedCustomers = this.state.customers.map((c, i) => {
            return c.value;
        });

        const selectedContactTypes = this.state.contactTypes.map((c, i) => {
            return c.value;
        });

        const selectedTags = this.state.tags.map((t, i) => {
            let tag = { name: t.label };
            if (t.value !== t.label) {
                tag.slug = t.value;
            }
            return tag;
        });

        const selectedClassifications = this.state.classifications.map((c, i) => {
            return c.value;
        });

        const selectedGoals = this.state.goals.map((g, i) => {
            return g.value;
        });

        let payload = {
            title: this.state.title,
            description: this.state.description,
            link: this.state.link,
            segments: selectedSegments,
            customers: selectedCustomers,
            contactTypes: selectedContactTypes,
            tags: selectedTags,
            classifications: selectedClassifications,
            goals: selectedGoals,
            deadline: this.state.deadline,
            email: this.state.email,
            account: this.props.account,
        };
        log("values are", payload);

        if (this.props.newsItem) {
            if (Object.entries(this.props.newsItem).length !== 0) {
                this.props.onUpdateSubmit(payload, this.props.newsItem.slug);
            } else {
                this.props.onFormSubmit(payload);
            }
        }
    };

    onFormClear = () => {
        this.props.clearForm();

        this.setDefaultState(this.props.segmentData, this.props.user);
    };

    handleInputChange(event) {
        if (event) {
            if (event.target) {
                if (event.target.type === "checkbox") {
                    let updatedSegments;
                    updatedSegments = this.state.segments.map((s, i) => {
                        if (event.target.id === s.value) {
                            s.checked = event.target.checked;
                        }
                        return s;
                    });
                    this.setState({
                        segments: updatedSegments,
                    });
                } else {
                    this.setState({
                        [event.target.id]: event.target.value,
                    });
                }
            }
        }
    }

    handleChangeSegmentsCustomers(val) {
        let state = { segmentsCustomers: val };
        if (val === "segments") {
            state = {
                ...state,
                customers: [],
            };
        } else {
            const resetSegments = this.state.segments.map((s, i) => {
                if (s.checked) {
                    s.checked = false;
                }
                return s;
            });
            state = {
                ...state,
                segments: resetSegments,
            };
        }
        this.setState({ ...state });
    }

    /*:: promiseTagsSearch: () => void */
    promiseTagsSearch(inputValue: string, callback: (selectOptions: Array<Object>) => void) {
        if (this.state.searchTimeout) window.clearTimeout(this.state.searchTimeout);
        this.setState({
            searchTimeout: setTimeout(() => {
                if (inputValue.length < 3) return;
                fetchTags(this.props.account, inputValue).then(
                    (resp) => {
                        if (resp.response == null) {
                            return callback([]);
                        }
                        resp.response.forEach((tag, _idx) => {
                            tag.value = tag.slug;
                            tag.label = tag.name;
                        });
                        callback(resp.response);
                    },
                    (err) => {
                        log("error", err);
                        callback([]);
                    }
                );
            }, 500),
        });
    }

    promiseCustomerSearch(inputValue: string, callback: (selectOptions: Array<Object>) => void) {
        if (this.state.searchTimeout) window.clearTimeout(this.state.searchTimeout);
        this.setState({
            searchTimeout: setTimeout(() => {
                searchCustomers(inputValue, callback);
            }, 500),
        });
    }

    render() {
        const {
            isSubmitting,
            errors,
            success,
            customerData,
            contactTypeData,
            classificationData,
            goalData,
            newsItem,
        } = this.props;

        const segments = this.state.segments.map((s, index) => {
            return (
                <section className="col-sm-6" key={index}>
                    <input
                        type="checkbox"
                        name="segments"
                        id={s.value}
                        onChange={this.handleInputChange}
                        checked={s.checked}
                    />
                    <label htmlFor={s.value}>{s.name}</label>
                </section>
            );
        });

        let classificationSelect = null;
        if (newsItem) {
            if (Object.entries(newsItem).length !== 0) {
                classificationSelect = (
                    <Select
                        id="classifications"
                        name="classifications"
                        value={this.state.classifications}
                        onChange={(selectedObj) => {
                            this.setState({ classifications: selectedObj || [] });
                        }}
                        options={classificationData}
                        placeholder="Choose classification"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isMulti
                        isDisabled={isSubmitting}
                    />
                );
            }
        }

        let goalSelect = null;
        if (newsItem && Object.entries(newsItem).length !== 0) {
            goalSelect = (
                <Select
                    id="goals"
                    name="goals"
                    value={this.state.goals}
                    onChange={(selectedObj) => {
                        this.setState({ goals: selectedObj || [] });
                    }}
                    options={goalData}
                    placeholder="Choose goal"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isMulti
                    isDisabled={isSubmitting}
                />
            );
        }

        const isValidUrl = (string) => {
            let regexp = /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            return regexp.test(string);
        };

        return (
            <form className="form-component clearfix" onSubmit={this.newsItemSubmit}>
                {isSubmitting ? (
                    <div className="form-loading">
                        <i className="fas fa-circle-notch fa-spin" />
                    </div>
                ) : null}
                {errors ? <ErrorsMessage errors={errors} /> : null}
                <div className="row">
                    <section className="news-item-form-fields col-md-12">
                        {success ? (
                            <Alert variant="success" show={this.state.alertVisible}>
                                Awesome job! News item form submitted successfully!
                            </Alert>
                        ) : null}
                        <input
                            id="title"
                            name="title"
                            type="text"
                            disabled={isSubmitting}
                            required
                            placeholder="Title"
                            value={this.state.title}
                            maxLength={150}
                            onChange={this.handleInputChange}
                        />
                        <small className="characters-count">{this.state.title.length}/150</small>
                        <textarea
                            id="description"
                            name="description"
                            disabled={isSubmitting}
                            placeholder="Description"
                            value={this.state.description}
                            maxLength={2000}
                            onChange={this.handleInputChange}
                        />
                        <input
                            id="link"
                            name="link"
                            type="url"
                            disabled={isSubmitting}
                            placeholder="Link"
                            value={this.state.link}
                            onChange={this.handleInputChange}
                        />
                        {isValidUrl(this.state.link) ? (
                            <ReactTinyLink
                                cardSize="small"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url={this.state.link}
                            />
                        ) : null}
                        <div className="toggle-segments-customers">
                            <ToggleButtonGroup
                                type="radio"
                                name="options"
                                value={this.state.segmentsCustomers}
                                onChange={this.handleChangeSegmentsCustomers}
                            >
                                <ToggleButton value="segments" variant="secondary-inverse">
                                    Segments
                                </ToggleButton>
                                <ToggleButton value="customers" variant="secondary-inverse">
                                    Customers
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        {this.state.segmentsCustomers === "segments" ? (
                            <div className="row checkbox-group">{segments}</div>
                        ) : null}
                        {this.state.segmentsCustomers === "customers" && (
                            <AsyncSelect
                                id="customers"
                                name="customers"
                                value={this.state.customers}
                                onChange={(selectedObj) => {
                                    this.setState({ customers: selectedObj || [] });
                                }}
                                loadOptions={this.promiseCustomerSearch}
                                defaultOptions={customerData}
                                placeholder="Choose customer"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                isMulti
                                isDisabled={isSubmitting}
                            />
                        )}
                        <Select
                            id="contactTypes"
                            name="contactTypes"
                            value={this.state.contactTypes}
                            onChange={(selectedObj) => {
                                this.setState({ contactTypes: selectedObj || [] });
                            }}
                            options={contactTypeData}
                            placeholder="Choose contact type"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti
                            isDisabled={isSubmitting}
                        />
                        <AsyncCreatableSelect
                            id="tags"
                            name="tags"
                            value={this.state.tags}
                            onChange={(selectedObj) => {
                                this.setState({ tags: selectedObj || [] });
                            }}
                            loadOptions={this.promiseTagsSearch}
                            defaultOptions={this.props.tagsData}
                            placeholder="Add tags"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti
                            isDisabled={isSubmitting}
                        />
                        {classificationSelect}
                        {goalSelect}
                        <input
                            id="deadline"
                            name="deadline"
                            type="date"
                            disabled={isSubmitting}
                            placeholder="Deadline"
                            value={this.state.deadline}
                            onChange={this.handleInputChange}
                        />
                        <input
                            id="email"
                            name="email"
                            type="email"
                            disabled={isSubmitting}
                            placeholder="Your email address"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                        <div className="buttons-wrapper">
                            <button
                                type="button"
                                disabled={isSubmitting}
                                className="btn btn-inverse"
                                onClick={this.onFormClear}
                            >
                                Reset
                            </button>
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </section>
                </div>
            </form>
        );
    }
}

export default NewsItemForm;
