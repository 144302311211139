import {put, takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get, patch, post} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/customerContactForm";
import {makeAuthenticatedCall, makeRequest} from "./utils";

const log = debug("customerContactFormSaga");


function getCustomers(account) {
    log('requesting customers', account);
    return get(endpoints.accountData.customers.replace(':account', account));
}

function* getCustomerContactContentData(action) {
    log('got a request to get customer contact content', action);

    const {success: getCustomersSuccess, response: getCustomersResponse, error: getCustomersError} = yield makeAuthenticatedCall(getCustomers, action.account);
    if (!getCustomersSuccess) {
        log('api bad request', getCustomersError);
        yield put(actions.requestCustomerContactContentError(getCustomersResponse));
        return;
    }

    yield put(actions.requestCustomerContactContentSuccess({customers: getCustomersResponse}));
}

function saveCustomerContact(actionPayload, contact) {
    log('payload is', actionPayload);
    let payload = {
        first_name: actionPayload.firstName,
        last_name: actionPayload.lastName,
        email: actionPayload.email,
        customer: actionPayload.customer,
        contact_type: actionPayload.contactType || null,
    };
    log ('transplanting the payload to server');
    if (contact) {
        log('contact is', contact);
        return patch(endpoints.contacts.updateContact.replace(':contact', contact), payload);
    } else {
        return post(endpoints.contacts.create, payload);
    }
}

function* handleSaveCustomerContact(action) {
    log('got request to create customer contact', action);
    yield makeRequest(actions.saveCustomerContactSuccess, actions.saveCustomerContactError, saveCustomerContact, action.payload, action.contact);
}


// WATCHERS
export function* watchRequestForCustomerContactContent() {
    yield takeEvery(types.CUSTOMER_CONTACT_CONTENT_REQUEST, getCustomerContactContentData);
}

export function* watchRequestForCreateCustomerContact() {
    yield takeEvery(types.SAVING_CUSTOMER_CONTACT, handleSaveCustomerContact);
}
