import debug from "debug";

import {getErrorData} from "../utils/errorData";
import {createAction} from "@reduxjs/toolkit";

const log = debug('campaignExecuteContactsReducer');

// INITIAL REDUX STORAGE STATE
const DEFAULT_STATE = {
    loading: false,
    errors: null,
    success: null,
    campaignContacts: [],
    totalCount: 0,
};

// ACTIONS
export const actions = {
    requestCampaignContacts: createAction('CAMPAIGN_CUSTOMER_CONTACTS/REQUEST'),
    requestCampaignContactsSuccess: createAction('CAMPAIGN_CUSTOMER_CONTACTS/REQUEST/SUCCESS'),
    requestCampaignContactsError: createAction('CAMPAIGN_CUSTOMER_CONTACTS/REQUEST/ERROR'),

    requestRefreshCampaignContacts: createAction('CAMPAIGN_CUSTOMER_CONTACTS_REFRESH/REQUEST'),
    requestRefreshCampaignContactsSuccess: createAction('CAMPAIGN_CUSTOMER_CONTACTS_REFRESH/REQUEST/SUCCESS'),
    requestRefreshCampaignContactsError: createAction('CAMPAIGN_CUSTOMER_CONTACTS_REFRESH/REQUEST/ERROR'),

    requestRemoveContacts: createAction('CAMPAIGN_CUSTOMER_CONTACTS/REMOVE/REQUEST'),
    requestRemoveContactsSuccess: createAction('CAMPAIGN_CUSTOMER_CONTACTS/REMOVE/REQUEST/SUCCESS'),
    requestRemoveContactsError: createAction('CAMPAIGN_CUSTOMER_CONTACTS/REMOVE/REQUEST/ERROR'),

    requestSendCampaignMessage: createAction('CAMPAIGN_MESSAGE/SEND/REQUEST'),
    requestSendCampaignMessageSuccess: createAction('CAMPAIGN_MESSAGE/SEND/REQUEST/SUCCESS'),
    requestSendCampaignMessageError: createAction('CAMPAIGN_MESSAGE/SEND/REQUEST/ERROR'),
};

// REDUCERS
export default function campaignExecuteContactsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.requestCampaignContacts.type:
        case actions.requestRefreshCampaignContacts.type: {
            return {
                ...state,
                loading: true,
                errors: null,
            }
        }

        case actions.requestRemoveContacts.type:
        case actions.requestSendCampaignMessage.type: {
            return {
                ...state,
                loading: true,
                errors: null,
                success: null,
            }
        }

        case actions.requestCampaignContactsSuccess.type: {
            log('campaign customer contacts are', action.payload);
            let campaignContacts = Array.from(state.campaignContacts);
            campaignContacts = campaignContacts.concat(action.payload.results);
            return {
                ...state,
                loading: false,
                errors: null,
                campaignContacts: campaignContacts,
                totalCount: action.payload.count,
            }
        }

        case actions.requestRefreshCampaignContactsSuccess.type: {
            return {
                ...state,
                loading: false,
                errors: null,
                campaignContacts: action.payload.results,
                totalCount: action.payload.count,
            }
        }

        case actions.requestRemoveContactsSuccess.type:
        case actions.requestSendCampaignMessageSuccess.type: {
            return {
                ...state,
                loading: false,
                errors: null,
                success: true,
            }
        }

        case actions.requestCampaignContactsError.type:
        case actions.requestRefreshCampaignContactsError.type: {
            log("error is", action);
            const errorData = getErrorData(action.errors);
            return {
                ...state,
                loading: false,
                errors: errorData,
            }
        }

        case actions.requestRemoveContactsError.type:
        case actions.requestSendCampaignMessageError.type: {
            log("error is", action);
            const errorData = getErrorData(action.errors);
            return {
                ...state,
                loading: false,
                success: false,
                errors: errorData,
            }
        }

        default: {
            return state;
        }
    }
};

// SELECTORS
export const getIsLoading = (state) => state.campaignExecuteContacts.loading;
export const getErrors = (state) => state.campaignExecuteContacts.errors;
export const getSuccess = (state) => state.campaignExecuteContacts.success;
export const getCampaignContacts = (state) => state.campaignExecuteContacts.campaignContacts;
export const getTotal = (state) => state.campaignExecuteContacts.totalCount;
