import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { deleteSavedSearch, getSavedSearches, getSavedSearchesRefreshed } from "./api";
import { actions } from "./reducer";

const log = debug("InteractionsSearchSaga");

function* fetchSavedSearches(action) {
    log("got request to fetch saved searches", action);
    yield makeRequest(
        actions.requestSavedSearchesSuccess,
        actions.requestSavedSearchesError,
        getSavedSearches,
        action.payload
    );
}

function* fetchSavedSearchesRefreshed(action) {
    log("got request to fetch saved searches, refreshed", action);
    yield makeRequest(
        actions.requestSavedSearchesRefreshedSuccess,
        actions.requestSavedSearchesRefreshedError,
        getSavedSearchesRefreshed,
        action.payload
    );
}

function* requestToDeleteSavedSearch(action) {
    log("got request to delete saved search", action);
    yield makeRequest(
        actions.requestDeleteSavedSearchSuccess,
        actions.requestDeleteSavedSearchError,
        deleteSavedSearch,
        action.payload
    );
}

const sagas = [
    takeLatest(actions.requestSavedSearches.type, fetchSavedSearches),
    takeLatest(actions.requestSavedSearchesRefreshed.type, fetchSavedSearchesRefreshed),
    takeLatest(actions.requestDeleteSavedSearch, requestToDeleteSavedSearch),
];
export default sagas;
