import debug from "debug";
import {takeEvery} from 'redux-saga/effects';

import {types, actions} from "../reducers/emailFeedback";
import {endpoints} from "../constants/api";
import {get, post} from "../utils/requester";
import {makeRequest} from "./utils";

const log = debug("EmailFeedbackSaga");


function getEmailFeedbackData(message) {
    log('getting email feedback data', message);
    return get(endpoints.contactMessages.feedback.replace(':cm', message));
}

function* handleGetEmailFeedbackData(action) {
    log('got a request to get email feedback data', action);
    yield makeRequest(actions.getFeedbackDataSuccess, actions.getFeedbackDataError, getEmailFeedbackData, action.message);
}

function submitEmailFeedback(actionPayload, message) {
    log('submitting email feedback', actionPayload);
    let payload = {
        items: actionPayload.newsItems,
        overall_note: actionPayload.overallNote,
        call_to_actions: actionPayload.callToActions,
    };
    return post(endpoints.contactMessages.feedback.replace(':cm', message), payload);
}

function* handleSubmitEmailFeedback(action) {
    log('got a request to submit email feedback', action);
    yield makeRequest(
        actions.submitFeedbackSuccess,
        actions.submitFeedbackError,
        submitEmailFeedback,
        action.payload, action.message
    );
}

// WATCHERS
export function* watchRequestGetEmailFeedbackData() {
    yield takeEvery(types.FEEDBACK_DATA_REQUEST, handleGetEmailFeedbackData);
}

export function* watchRequestSubmitEmailFeedback() {
    yield takeEvery(types.SUBMIT_FEEDBACK_REQUEST, handleSubmitEmailFeedback);
}
