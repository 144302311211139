import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get, post} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/contactAddNewsItems";
import {makeRequest} from "./utils";

const log = debug("contactAddNewsItemsSaga");


function getContactNewsItems(contact, offset, limit, search) {
    log('requesting contact news items');
    const getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    return get(endpoints.contacts.availableNewsItems.replace(':contact', contact), getParams);
}

function* getContactNewsItemsData(action) {
    log('got a request to get contact news items', action);
    yield makeRequest(
        actions.requestContactNewsItemsSuccess,
        actions.requestContactNewsItemsError,
        getContactNewsItems,
        action.contact, action.offset, action.limit, action.search
    );
}

function getContactNewsItemsRefresh(contact, offset, limit, search) {
    log('requesting contact news items, refreshed');
    const getParams = {
        offset: offset,
        limit: limit,
        search: search,
    };
    return get(endpoints.contacts.availableNewsItems.replace(':contact', contact), getParams);
}

function* getContactNewsItemsDataRefresh(action) {
    log('got a request to get contact news items, refreshed', action);
    yield makeRequest(
        actions.requestRefreshContactNewsItemsSuccess,
        actions.requestRefreshContactNewsItemsError,
        getContactNewsItemsRefresh,
        action.contact, action.offset, action.limit, action.search
    );
}

function addSelectedNewsItems(actionPayload, contact) {
    log('selected news items are', actionPayload);
    const payload = {
        news_items: actionPayload,
    };
    log ('transplanting the action to server payload', payload);
    return post(endpoints.contacts.updateContactNewsItems.replace(':contact', contact), payload);
}

function* handleAddSelectedNewsItems(action) {
    log('got request to add selected news item', action);
    yield makeRequest(actions.addNewsItemsSuccess, actions.addNewsItemsError, addSelectedNewsItems, action.payload, action.contact);
}


// WATCHERS
export function* watchRequestForContactNewsItems() {
    yield takeEvery(types.CONTACT_NEWS_ITEMS_DATA_REQUEST, getContactNewsItemsData);
}

export function* watchRequestForContactNewsItemsRefresh() {
    yield takeEvery(types.CONTACT_NEWS_ITEMS_DATA_REFRESH_REQUEST, getContactNewsItemsDataRefresh);
}

export function* watchRequestForAddSelectedNewsItems() {
    yield takeEvery(types.ADDING_NEWS_ITEMS, handleAddSelectedNewsItems);
}
