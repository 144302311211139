import debug from "debug";
import {put, takeEvery} from 'redux-saga/effects';

import {types, actions} from "../reducers/newsItemForm";
import {endpoints} from "../constants/api";
import {post} from "../utils/requester";
import {makeRequest} from "./utils";

const log = debug("NewsItemFormSaga");


function saveNewNewsItem(actionPayload) {
    log('account is', actionPayload.account);
    let payload = {
        account: actionPayload.account,
        classifications: actionPayload.classifications || [],
        contact_types: actionPayload.contactTypes || [],
        customers: actionPayload.customers || [],
        deadline: actionPayload.deadline || null,
        description: actionPayload.description || '',
        email: actionPayload.email || '',
        goals: actionPayload.goals || [],
        link: actionPayload.link || '',
        segments: actionPayload.segments || [],
        status: actionPayload.status,
        tags: actionPayload.tags || [],
        title: actionPayload.title,
    };
    log ('transplanting the action to server payload', payload);
    return post(endpoints.newsItems.publicCreate, payload);
}

function* handleSaveNewNewsItem(action) {
    log('got request to save new news item', action);
    yield makeRequest(actions.saveNewNewsItemSuccess, actions.saveNewNewsItemError, saveNewNewsItem, action.payload);
}

export function* watchRequestSaveNewNewsItem() {
    yield takeEvery(types.SUBMITTING_NEWS_ITEM, handleSaveNewNewsItem);
}
