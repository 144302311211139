import debug from "debug";
import { push } from 'connected-react-router';
import {put, takeEvery} from 'redux-saga/effects';

import {types, actions} from "../reducers/contactMessageEdit";
import {endpoints} from "../constants/api";
import {get, post, patch, deleteMethod} from "../utils/requester";
import {makeAuthenticatedCall} from "./utils";
import {routes} from "../constants/routes";

const log = debug("ContactMessageEditSaga");


function saveContactMessage(actionPayload, message_slug) {
    log('message is', message_slug);
    let payload = {
        recipient_email: actionPayload.recipientEmail,
        subject: actionPayload.subject || '',
        body_greeting: actionPayload.greeting || '',
        news_items: actionPayload.newsItems || [],
        feedback_button_text: actionPayload.feedbackBtnTxt || '',
        body_footer: actionPayload.footer || '',
        body_sender_signature: actionPayload.signature || '',
        is_draft: actionPayload.draft,
    };
    log ('transplanting the action to server payload', payload);
    return patch(endpoints.contactMessages.updateView.replace(':cm', message_slug), payload);
}

function* handleSaveContactMessage(action) {
    log('got request to save contact message', action);
    const {success, response, error, statusCode} = yield makeAuthenticatedCall(saveContactMessage, action.payload, action.message_slug);
    log('api response', error, statusCode);

    if (success) {
        log('save api OK');
        yield put(actions.submitContactMessageSuccess(response));
        if (!action.payload.draft) {
            yield makeAuthenticatedCall(sendContactMessage, action.payload, action.message_slug, action.campaign_slug);
            if (action.campaign_slug !== "") {
                yield put(push(
                    {
                            pathname: routes.messages.view.replace(':customer', response.contact.customer.slug).replace(':contact', response.contact.slug).replace(':message', response.slug),
                            state: {
                                messageCampaign: action.campaign_slug,
                            }
                        }
                ));
            } else {
                yield put(push(routes.messages.view.replace(':customer', response.contact.customer.slug).replace(':contact', response.contact.slug).replace(':message', response.slug)));
            }
        }
    } else if (statusCode === 401) {
        log('token expired');
    } else {
        log('api bad request', error);
        yield put(actions.submitContactMessageError(error));
    }
}

function sendContactMessage(actionPayload, message_slug) {
    log ('transplanting the action to server payload', actionPayload);
    log ('contact message saved, now ready to send!', message_slug);
    return post(endpoints.contactMessages.send.replace(':cm', message_slug), actionPayload);
}

function getContact(customer_slug, contact_slug) {
    log('requesting customer contacts', customer_slug);
    let getParams = {};
    if (contact_slug) {
        log('contact provided', contact_slug);
        getParams = {
            contact: contact_slug,
        };
    }
    return get(endpoints.contacts.customer.replace(':customer', customer_slug), getParams);
}

function getContactNewsItems(contact_slug) {
    log('requesting contact news items', contact_slug);
    return get(endpoints.newsItems.listNewsItemsContact.replace(':contact', contact_slug));
}

function* handleGetInitialContent(action) {
    log('got a request to get initial message content', action);

    const {
              success: getContactSuccess,
              response: getContactResponse,
              error: getContactError,
              statusCode: getContactStatusCode
          } = yield makeAuthenticatedCall(getContact, action.customer_slug, action.contact_slug);

    if (getContactStatusCode === 401) {
        log("token expired");
        return;
    }

    if (!getContactSuccess) {
        log('api bad request', getContactError);
        yield put(actions.getInitialContentError(getContactResponse));
        return;
    }

    const {
              success: getContactNewsItemsSuccess,
              response: getContactNewsItemsResponse,
              error: getContactNewsItemsError,
              statusCode: getContactNewsItemsStatusCode,
          } = yield makeAuthenticatedCall(getContactNewsItems, action.contact_slug);

    if (getContactNewsItemsStatusCode === 401) {
        log("token expired");
        return;
    }

    if (!getContactNewsItemsSuccess) {
        log('api bad request', getContactNewsItemsError);
        yield put(actions.getInitialContentError(getContactNewsItemsResponse));
        return;
    }

    yield put(actions.getInitialContentSuccess({contact: getContactResponse, newsItems: getContactNewsItemsResponse}));
}

function getContactMessageContent(message_slug) {
    log('requesting contact message', message_slug);
    return get(endpoints.contactMessages.updateView.replace(':cm', message_slug));
}

function fetchProfile() {
    log('requesting user profile');
    return get(endpoints.auth.profile);
}

function* handleGetContactMessageContent(action) {
    log('got a request to get contact message content', action);

    const {success: getContactMessageSuccess, response: getContactMessageResponse, error: getContactMessageError, statusCode: getContactMessageStatusCode} = yield makeAuthenticatedCall(getContactMessageContent, action.message_slug);

    if (getContactMessageStatusCode === 401) {
        log('token expired');
        return;
    }

    if (!getContactMessageSuccess) {
        log('api bad request', getContactMessageError);
        yield put(actions.getMessageContentError(getContactMessageResponse));
        return;
    }

    const {success: getProfileInfoSuccess, response: getProfileInfoResponse, error: getProfileInfoError, statusCode: getProfileInfoStatusCode} = yield makeAuthenticatedCall(fetchProfile);
    if (getProfileInfoStatusCode === 401) {
        log('token expired');
        return;
    }
    
    if (!getProfileInfoSuccess) {
        log('api bad request', getProfileInfoError);
        yield put(actions.getMessageContentError(getProfileInfoResponse));
        return;
    }

    yield put(actions.getMessageContentSuccess({message: getContactMessageResponse, profileData: getProfileInfoResponse}));
}

function contactMessageUndo(message_slug) {
    log ('requesting contact message undo');
    return deleteMethod(endpoints.contactMessages.updateView.replace(':cm', message_slug));
}

function* handleContactMessageUndo(action) {
    log('got request to undo contact message', action);
    const {success, response, error, statusCode} = yield makeAuthenticatedCall(contactMessageUndo, action.message_slug);

    if (success) {
        log('save api OK');
        yield put(actions.undoContactMessageSuccess(response));
        yield put(push(routes.customers.contacts.replace(':customer', action.customer_slug)));
    } else if (statusCode === 401) {
        log("token expired");
    } else {
        log('api bad request', error);
        yield put(actions.undoContactMessageError(error));
    }
}

// WATCHERS
export function* watchRequestSaveContactMessage() {
    yield takeEvery(types.SUBMITTING_CONTACT_MESSAGE, handleSaveContactMessage);
}

export function* watchRequestGetInitialContent() {
    yield takeEvery(types.INITIAL_CONTENT_REQUEST, handleGetInitialContent);
}

export function* watchRequestGetContactMessageContent() {
    yield takeEvery(types.MESSAGE_CONTENT_REQUEST, handleGetContactMessageContent);
}

export function* watchRequestContactMessageUndo() {
    yield takeEvery(types.CONTACT_MESSAGE_UNDO, handleContactMessageUndo);
}
