// @flow
import isEmpty from "lodash-es/isEmpty";

import type { Customer } from "../pages/Customers/CustomersList/types";
import type { Insight } from "../pages/Customers/CustomerInsights/types";
import type { CustomerDetails } from "../pages/Customers/CustomerDetails/types";
import { toArray } from "./utils";

export function parseCustomerServerResponse(customer: $ReadOnly<Object>): Customer {
    let csm;
    if (!isEmpty(customer.csms) && !isEmpty(customer.csms[0])) {
        const firstCSM = customer.csms[0];
        csm = {
            slug: firstCSM.slug,
            name: firstCSM.name,
            isCsLead: firstCSM.is_cs_lead,
            userTitle: firstCSM.user_title,
            email: firstCSM.email,
        };
    }

    let financialKpi;
    if (!isEmpty(customer.financial_kpi)) {
        financialKpi = {
            slug: customer.financial_kpi.slug,
            name: customer.financial_kpi.name,
        }
    }

    let tier;
    if (!isEmpty(customer.tier)) {
        tier = {
            slug: customer.tier.slug,
            name: customer.tier.name,
        }
    }

    let stage;
    if (!isEmpty(customer.stage)) {
        stage = {
            slug: customer.stage.slug,
            name: customer.stage.name,
        }
    }

    return {
        slug: customer.slug,
        customerName: customer.name,
        csm: csm,
        value: customer.value_amount,
        currency: customer.value_currency,
        financialKpi: financialKpi,
        tier: tier,
        stage: stage,
        renewalDate: customer.renewal_date,
        insightCount: customer.insight_count,
    };
}

export function parseCustomersListServerResponse(customers: Array<Object>): Array<Customer> {
    return toArray(customers, parseCustomerServerResponse);
}

export function parseCustomerDetailsResponse(customer: $ReadOnly<Object>): CustomerDetails {
    let csm;
    if (!isEmpty(customer.csms) && !isEmpty(customer.csms[0])) {
        const firstCSM = customer.csms[0];
        csm = {
            slug: firstCSM.slug,
            name: firstCSM.name,
            isCsLead: firstCSM.is_cs_lead,
            userTitle: firstCSM.user_title,
            email: firstCSM.email,
        };
    }

    let segments = [];
    if (!isEmpty(customer.segments)) {
        segments = customer.segments.map((segment, i) => {
            return {
                slug: segment.slug,
                name: segment.name,
                groupName: segment.group_name,
            }
        });
    }

    let financialKpis = [];
    if (!isEmpty(customer.financial_kpis)) {
        financialKpis = customer.financial_kpis.map((kpi, i) => {
            return {
                slug: kpi.slug,
                name: kpi.name,
                value: kpi.value,
                currency: kpi.currency,
            }
        });
    }

    let tier;
    if (!isEmpty(customer.tier)) {
        tier = {
            slug: customer.tier.slug,
            name: customer.tier.name,
        }
    }

    let stage;
    if (!isEmpty(customer.stage)) {
        stage = {
            slug: customer.stage.slug,
            name: customer.stage.name,
        }
    }

    let responsiveness;
    if (!isEmpty(customer.responsiveness)) {
        responsiveness = {
            hours: customer.responsiveness.hours,
            interactionCount: customer.responsiveness.interaction_count,
        };
    }

    let interactionPoints = [];
    if (!isEmpty(customer.interaction_points)) {
        interactionPoints = customer.interaction_points.reverse().map((point, i) => {
            return {
                date: point.date,
                totalInteractions: point.num_total_interactions,
            }
        });
    }

    return {
        slug: customer.slug,
        customerName: customer.name,
        csm: csm,
        segments: segments,
        financialKpis: financialKpis,
        tier: tier,
        stage: stage,
        renewalDate: customer.renewal_date,
        insightCount: customer.insight_count,
        lastTouchPoint: customer.last_touch_point,
        lastTouchPointDM: customer.last_touch_point_dm,
        responsiveness: responsiveness,
        interactionPoints: interactionPoints,
    };
}

export function parseCustomerInsightResponse(insight: $ReadOnly<Object>): Insight {
    return {
        slug: insight.slug,
        insightKind: insight.insight_type_kind,
        description: insight.message,
        duration: insight.duration,
        verdict: insight.verdict,
    };
}

export function parseCustomerInsightsResponse(insights: Array<Object>): Array<Insight> {
    return toArray(insights, parseCustomerInsightResponse);
}
