// @flow
import * as React from 'react';
import isEmpty from "lodash-es/isEmpty";
import debug from "debug";

import "./ErrorMessage.css";
import Message from '../Message/Message';


const log = debug('ErrorMessage');

type Props = {
    errors: Array<Object> | Object,
    displayKeys: boolean,
}


type State = {}


function toMessage(err: Object | string, componentKey: number = 1, displayKeys: boolean = false): React.Element<typeof Message> {
    let msg;
    if (typeof err === "string") {
        msg = <p>{err}</p>;
    } else if (typeof err === "object") {
        msg = Object.keys(err).map(key => (
            <p key={key}>{displayKeys ? <b>{key}:</b> : null}{err[key]}</p>
        ));
    } else {
        msg = <p className="error">An error occurred. Please try again later.</p>;
    }

    return <Message type="error" key={componentKey}>{msg}</Message>;
}

class ErrorsMessage extends React.PureComponent<Props, State> {
    static defaultProps: Props = {
        errors: [],
        displayKeys: false
    }

    render(): React.Node {
        const {errors, displayKeys} = this.props;
        let content;

        if (Array.isArray(errors)) {
            content = errors.map((err, idx) => {
                if (!isEmpty(err)) {
                    log('rendering error', err);
                    return toMessage(err, idx, displayKeys);
                }
            })
        } else {
            content = toMessage(errors);
        }
        log('content of error is', content);
        return content;
    }
}

export default ErrorsMessage;
