import {connect} from "react-redux";
import debug from "debug";

import {actions, getIsLoading, getErrors, getSuccess} from "../reducers/newsItemForm";
import {
	actions as accountDataActions,
	getIsLoading as accountDataGetIsLoading,
	getErrors as accountDataGetErrors,
	getSegments,
	getCustomers,
	getContactTypes,
	getClassifications,
	getGoals
} from "../reducers/accountData";
import {
	actions as newsItemsActions,
	getIsLoading as newsItemsGetIsLoading,
	getErrors as newsItemsGetErrors,
	getNewsItems,
	getSuccess as newsItemsGetSuccess,
	getTags
} from "../reducers/newsItems";
import {getUser} from "../reducers/profile";
import NewsItemForm from "../components/NewsItemForm/NewsItemForm";
import {LIMIT} from "../constants/pagination";

const log = debug("NewNewsItemForm");

const mapStateToProps = state => ({
	isSubmitting: getIsLoading(state) || accountDataGetIsLoading(state) || newsItemsGetIsLoading(state),
	errors: getErrors(state) || accountDataGetErrors(state) || newsItemsGetErrors(state),
	success: getSuccess(state) || newsItemsGetSuccess(state),
	user: getUser(state),
	segmentData: getSegments(state),
	customerData: getCustomers(state),
	contactTypeData: getContactTypes(state),
	classificationData: getClassifications(state),
	goalData: getGoals(state),
	tagsData: getTags(state),
	newsItems: getNewsItems(state),
});

const mapDispatchToProps = (dispatch, props) => {
	return {
		onFormSubmit: (values) => {
			log('sending request to save news item');
			dispatch(actions.saveNewNewsItem(values));
		},

		clearForm: () => {
			log('clearing form data');
			dispatch(actions.clearForm());
		},

		fetchSegmentData: (account) => {
            log('sending a request to fetch segment data');
            dispatch(accountDataActions.requestSegmentData(account));
        },

		fetchCustomerData: (account) => {
            log('sending a request to fetch customer data');
            dispatch(accountDataActions.requestCustomerData({accountSlug: account, limit: 10}));
        },

		fetchContactTypeData: (account) => {
            log('sending a request to fetch contact type data');
            dispatch(accountDataActions.requestContactTypeData(account));
        },

		fetchClassificationData: () => {
            log('sending a request to fetch classification data');
            dispatch(accountDataActions.requestClassificationData());
        },

		fetchGoalData: () => {
            log('sending a request to fetch goal data');
            dispatch(accountDataActions.requestGoalData());
        },

		fetchTagsData: (accountSlug) => {
            log('sending a request to fetch tags data');
            dispatch(newsItemsActions.requestTagsData(accountSlug));
        },

		fetchNewsItems: (customer, status, offset, limit) => {
            log('sending a request to fetch news items');
            dispatch(newsItemsActions.requestNewsItems(customer, status, offset, limit || LIMIT));
        },

		fetchRefreshNewsItems: (customer, status, offset, limit) => {
            log('sending a request to fetch news items, refreshed');
            dispatch(newsItemsActions.requestRefreshNewsItems(customer, status, offset, limit || LIMIT));
        },

		onUpdateSubmit: (values, newsItem) => {
            log('sending a request to edit news item');
            dispatch(newsItemsActions.updateNewsItem(values, newsItem));
        },
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsItemForm);
