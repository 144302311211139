// @flow
import isEmpty from "lodash-es/isEmpty";

import type {
    FinancialKpi,
    AnalyticsData,
    NonEngagement,
    Insight,
    SavedFilterParams,
} from "../pages/Reports/CustomerAnalytics/types";
import { toArray, toSelectOption } from "./utils";

export function parseNonEngagementServerResponse(tiers: Array<Object>): Array<NonEngagement> {
    return tiers.map((tier, i) => {
        return {
            tier: tier.tier,
            value: tier.value,
            total: tier.total,
            order: tier.order,
            nonEngaged: !isEmpty(tier.non_engaged) ? tier.non_engaged.join(",") : null,
            engaged: !isEmpty(tier.engaged) ? tier.engaged.join(",") : null,
        };
    });
}

export function parseInsightsServerResponse(insights: Array<Object>): Array<Insight> {
    return insights.map((insight, i) => {
        let customer;
        if (!isEmpty(insight.customer)) {
            customer = {
                name: insight.customer.name,
                slug: insight.customer.slug,
            };
        }

        let financialKpi;
        if (!isEmpty(insight.financial_kpi)) {
            financialKpi = {
                slug: insight.financial_kpi.slug,
                name: insight.financial_kpi.name,
            };
        }

        return {
            customer: customer,
            insightKind: insight.insight_type_kind,
            description: insight.message,
            financialKpi: financialKpi,
            value: insight.value,
            currency: insight.value_currency,
            duration: insight.duration,
            slug: insight.slug,
        };
    });
}

export function parseCustomerAnalyticsServerResponse(analyticsData: Object): AnalyticsData {
    let financialKpiAtRisk = [];
    if (!isEmpty(analyticsData.financial_kpi_at_risk)) {
        financialKpiAtRisk = analyticsData.financial_kpi_at_risk.map((risk, i) => {
            return {
                date: risk.date,
                value: risk.value,
                currency: risk.currency,
                customersCount: risk.customers_count,
            };
        });
    }

    let effortInvested = [];
    if (!isEmpty(analyticsData.effort_invested)) {
        effortInvested = analyticsData.effort_invested.map((effort, i) => {
            return {
                name: effort.customer.name,
                customerSlug: effort.customer.slug,
                parent: effort.tier,
                value: effort.value,
            };
        });
    }

    let decisionMakerNonEngagement = [];
    if (!isEmpty(analyticsData.decision_maker_non_engagement)) {
        decisionMakerNonEngagement = parseNonEngagementServerResponse(analyticsData.decision_maker_non_engagement);
    }

    let customerNonEngagement = [];
    if (!isEmpty(analyticsData.customers_non_engagement)) {
        customerNonEngagement = parseNonEngagementServerResponse(analyticsData.customers_non_engagement);
    }


    let topInsights = {
        byFinancialKpi: [],
        byDuration: [],
    };
    
    if (!isEmpty(analyticsData.top_insights)) {
        if (!isEmpty(analyticsData.top_insights.by_financial_kpi)) {
            topInsights.byFinancialKpi = parseInsightsServerResponse(analyticsData.top_insights.by_financial_kpi);
        }

        if (!isEmpty(analyticsData.top_insights.by_duration)) {
            topInsights.byDuration = parseInsightsServerResponse(analyticsData.top_insights.by_duration);
        }
    }

    let financialKpi;
    if (!isEmpty(analyticsData.financial_kpi)) {
        financialKpi = {
            slug: analyticsData.financial_kpi.slug,
            name: analyticsData.financial_kpi.name,
        }
    }

    return {
        financialKpiAtRisk: financialKpiAtRisk,
        effortInvested: effortInvested,
        customerNonEngagement: customerNonEngagement,
        decisionMakerNonEngagement: decisionMakerNonEngagement,
        topInsights: topInsights,
        financialKpi: financialKpi,
    }
}

export function parseKpiServerResponse(kpi: Object): FinancialKpi {
    return toSelectOption({
        slug: kpi.slug,
        name: kpi.name,
    });
}

export function parseKpisServerResponse(kpis: Array<Object>): Array<FinancialKpi> {
    return toArray(kpis, parseKpiServerResponse);
}

export function parseReportFilterServerResponse(filterDetails: $ReadOnly<Object>): SavedFilterParams {
    return {
        csms: filterDetails.params.csms,
        financialKpi: filterDetails.params.financial_kpi,
        tier: filterDetails.params.tier,
        stage: filterDetails.params.stage,
        renewalTiming: filterDetails.params.renewal_timing,
        period: filterDetails.params.period_weeks,
        slug: filterDetails.slug,
        title: filterDetails.title,
    };
}

export function parseReportFiltersServerResponse(savedFilters: Array<Object>): Array<SavedFilterParams> {
    return toArray(savedFilters, parseReportFilterServerResponse);
}
