import {takeEvery} from 'redux-saga/effects';
import debug from "debug";

import {get, post} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/massCampaign";
import {makeRequest} from "./utils";

const log = debug("massCampaign");


function getCampaignTemplates(account) {
    log('requesting campaign templates');
    return get(endpoints.campaigns.templates.replace(":account", account));
}

function* getCampaignTemplatesData(action) {
    log('got a request to get campaign templates', action);
    yield makeRequest(actions.requestCampaignTemplatesSuccess, actions.requestCampaignTemplatesError, getCampaignTemplates, action.account);
}

function validateReport(actionPayload) {
    let payload = {
        report_id: actionPayload.reportID,
    };
    log ('transplanting the action to server payload', payload);
    return get(endpoints.campaigns.validateReport, payload);
}

function* handleValidateReport(action) {
    log('got request to validate report', action);
    yield makeRequest(
        actions.validateReportSuccess,
        actions.validateReportError,
        validateReport,
        action.payload
    );
}

function submitMassCampaign(actionPayload) {
    let payload = {
        name: actionPayload.campaignName,
        contacts: actionPayload.contacts,
    };
    if (actionPayload.replyTo != null) {
        payload.reply_to = actionPayload.replyTo;
    }
    if (actionPayload.metadata != null) {
        payload.metadata = actionPayload.metadata;
    }
    log ('transplanting the action to server payload', payload);
    return post(endpoints.campaigns.massCreate.replace(':template', actionPayload.campaignTemplate), payload);
}

function* handleSubmitMassCampaign(action) {
    log('got request to submit mass campaign', action);
    yield makeRequest(actions.submitMassCampaignSuccess, actions.submitMassCampaignError, submitMassCampaign, action.payload);
}

// WATCHERS
export function* watchRequestCampaignTemplates() {
    yield takeEvery(types.CAMPAIGN_TEMPLATES_REQUEST, getCampaignTemplatesData);
}

export function* watchRequestValidateReport() {
    yield takeEvery(types.VALIDATING_REPORT, handleValidateReport);
}

export function* watchRequestSubmitMassCampaign() {
    yield takeEvery(types.SUBMITTING_MASS_CAMPAIGN, handleSubmitMassCampaign);
}
