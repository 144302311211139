import debug from "debug";
import { takeLatest } from "redux-saga/effects";
import { makeRequest } from "../../../sagas/utils";

import { getCustomersList } from "./api";
import { actions } from "./reducer";

const log = debug("CustomersListSaga");

function* fetchCustomersList(action) {
    log("got request to fetch customers list", action);
    yield makeRequest(
        actions.requestCustomersListSuccess,
        actions.requestCustomersListError,
        getCustomersList,
        action.payload
    );
}

function* fetchCustomersListRefreshed(action) {
    log("got request to fetch customers list, refreshed", action);
    yield makeRequest(
        actions.requestCustomersListRefreshedSuccess,
        actions.requestCustomersListRefreshedError,
        getCustomersList,
        action.payload
    );
}

const sagas = [
    takeLatest(actions.requestCustomersList.type, fetchCustomersList),
    takeLatest(actions.requestCustomersListRefreshed.type, fetchCustomersListRefreshed),
];
export default sagas;
