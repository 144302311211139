// @flow
import debug from "debug";
import { endpoints } from "../../../constants/api";
import type { ServerResponse } from "../../../types/common";
import { get } from "../../../utils/requester";

const log = debug("CustomerInsightsAPI");

export function getCustomerInsights(customer: string): Promise<ServerResponse> {
    log("requesting customer insights", customer);
    return get(endpoints.customers.insights.replace(':customer', customer));
}
