import debug from "debug";
import {takeEvery} from "redux-saga/effects";

import {get, post, patch} from "../utils/requester";
import {endpoints} from "../constants/api";
import {types, actions} from "../reducers/profile";
import {makeRequest} from "./utils";

const log = debug("profileSaga");


function fetchProfile() {
    log('requesting user profile');
    return get(endpoints.auth.profile);
}

export function* profileFetchRequest(action) {
    log('got a request to fetch user profile', action);
    yield makeRequest(actions.profileSuccess, actions.profileError, fetchProfile);
}

function fetchProfileData() {
    log('requesting user profile data');
    return get(endpoints.auth.profile);
}

export function* profileDataFetchRequest(action) {
    log('got a request to fetch user profile data', action);
    yield makeRequest(actions.profileDataSuccess, actions.profileDataError, fetchProfileData);
}

function sendResetPasswordRequest(actionPayload) {
    log('new password is', actionPayload.password);
    let payload = {
        new_password: actionPayload.password,
        current_password: actionPayload.currentPassword,
    };
    log ('transplanting the action to server payload', payload);
    return post(endpoints.auth.setPassword, payload);
}

export function* handleSendResetPasswordRequest(action) {
    log('got a request to set new password', action);
    yield makeRequest(actions.submitResetPasswordSuccess, actions.submitResetPasswordError, sendResetPasswordRequest, action.payload);
}

function sendUpdateSignatureRequest(actionPayload) {
    log('profile signature', actionPayload.signature);
    let payload = {
        signature: actionPayload.signature,
    };
    log ('transplanting the action to server payload', payload);
    return patch(endpoints.auth.profile, payload);
}

export function* handleSendUpdateSignatureRequest(action) {
    log('got a request to update profile signature', action.payload);
    yield makeRequest(actions.submitUpdateSignatureSuccess, actions.submitUpdateSignatureError, sendUpdateSignatureRequest, action.payload);
}

// WATCHERS
export function* watchRequestForProfile() {
    yield takeEvery(types.PROFILE_REQUEST, profileFetchRequest);
}

export function* watchRequestForProfileData() {
    yield takeEvery(types.PROFILE_DATA_REQUEST, profileDataFetchRequest);
}

export function* watchRequestForResetPassword() {
    yield takeEvery(types.SUBMITTING_RESET_PASSWORD_REQUEST, handleSendResetPasswordRequest);
}

export function* watchRequestForUpdateSignature() {
    yield takeEvery(types.UPDATE_SIGNATURE_REQUEST, handleSendUpdateSignatureRequest);
}
